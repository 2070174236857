import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { PhotoCamera, Clear } from '@material-ui/icons';

import { IconButton } from '@material-ui/core';

import {
  DEFAULT_AVATAR,
  DEFAULT_TOURNAMENT,
  CLEAR_ICON
} from '../../helpers/constants';

import { useStyles } from './StylesUploadImage';

const UploadImage = (props) => {
  const {
    onChange,
    onClearImg,
    img,
    className,
    id,
    isPoster,
    disabled,
    header
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.wholeSize, !disabled && classes.hover)}>
        <input
          accept="image/jpeg, image/jpg, image/png"
          className={classes.input}
          {...{ id }}
          type="file"
          {...{ onChange }}
          disabled={disabled}
        />
        <label htmlFor={id}>
          <img
            src={img ? img : !isPoster ? DEFAULT_AVATAR : DEFAULT_TOURNAMENT}
            alt={t('photo')}
            className={clsx(
              !header ? classes.cover : classes.coverHeader,
              !disabled && classes.image,
              classes.fullSize
            )}
          />
          {!disabled && (
            <span
              className={clsx(
                img && classes.uploadedImgExist,
                classes.imgShadow
              )}>
              <PhotoCamera className={classes.camera} />
              <span className={classes.text}>{t('photo')}</span>
            </span>
          )}
        </label>
      </div>

      {/* Clear button */}
      {!disabled && img && onClearImg && (
        <IconButton
          id={CLEAR_ICON}
          className={clsx(classes.clearButton)}
          onClick={(evt) => {
            onClearImg(evt, {
              targetId: CLEAR_ICON
            });
          }}>
          <Clear />
        </IconButton>
      )}
    </div>
  );
};

export default UploadImage;
