import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Tooltip } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as ArrowIc } from '../../assets/img/arrow_right_white_bg&grey_border.svg';
import { ReactComponent as PublishedIcon } from '../../assets/img/published.svg';
import { ReactComponent as NoCountryIcon } from '../../assets/img/no_country.svg';
import { ReactComponent as CoachesIcon } from '../../assets/img/coaches.svg';
import { ReactComponent as TimerIcon } from '../../assets/img/timer.svg';
import { ReactComponent as BronzeFightIcon } from '../../assets/img/bronze_fight.svg';
import { ReactComponent as NoParticipantNumIcon } from '../../assets/img/no_participant_num.svg';
import { ReactComponent as CloseFlagIcon } from '../../assets/img/close_flag.svg';

import CategoriesIcon from '@material-ui/icons/CardMembership';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { DDMMYYYY, finishedTournament } from 'helpers/selectors';
import { DELETE_ICON } from 'helpers/constants';

import Table from 'components/Table/CardsList';

import { useStyles, useStylesRecords } from './TableListStyles';

const EDIT_TOURNAMENT_ICON = 'EDIT_TOURNAMENT_ICON';

const TableList = (props) => {
  const {
    role,
    page,
    rowsPerPage,
    selectedRow,
    tournamentData,
    filteredTournaments,
    showModal,
    rowClickedHandler
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const recordDataParams = (item, idx) => {
    const updateDate = item?.update_date?.split(' ');

    return {
      selected: +selectedRow === +item.id,
      main: {
        style: {
          wrapper: classes.marginTop1,
          cardMainPart: classes.cardValuePart
        },
        rowOrder: { value: (page - 1) * rowsPerPage + idx + 1 },
        name: item?.tournament_name,
        hasPhoto: true,
        photo: item?.poster
      },
      info: [
        {
          value: item?.id,
          name: t('code'),
          width: '3em'
        },
        ...(role === 'administrator'
          ? [{ value: 'organizer', name: t('organizer'), width: '5.5em' }]
          : []),
        {
          value:
            +item?.type === 1
              ? t('regionalEvent')
              : +item?.type === 2
              ? t('internationalEvent')
              : t('trainingSchool'),
          name: t('eventType'),
          width: '8em'
        },
        { value: item?.participants_count, name: t('participantsNum') },
        { value: DDMMYYYY(item?.start_date), name: t('date') },

        ...(role === 'administrator'
          ? [
              {
                value: DDMMYYYY(item?.registration_date),
                name: `Reg. ${t('date')}`
              }
            ]
          : []),
        ...(role === 'master'
          ? [{ value: item?.start_time, name: t('time') }]
          : []),
        {
          value:
            finishedTournament(item) || !!+tournamentData?.finished
              ? t('eventClosed')
              : !+item?.registration_active
              ? t('registrationClosed')
              : t('registrationOpened'),
          name: t('eventStatus')
        },
        { value: item?.iso3, name: t('country') },
        { value: item?.region, name: t('region') },
        { value: item?.city, name: t('city') },
        ...(role === 'administrator'
          ? [{ value: item?.published, name: <PublishedIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.finished, name: <CloseFlagIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.bronze_fight_enabled, name: <BronzeFightIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [
              {
                value: item?.no_participant_number,
                name: <NoParticipantNumIcon />
              }
            ]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.no_country, name: <NoCountryIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.active, name: <TimerIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [
              {
                value:
                  updateDate &&
                  `${DDMMYYYY(updateDate[0])} ${updateDate[1]
                    ?.split(':')
                    ?.slice(0, 2)
                    .join(':')}`,
                name: <AutorenewIcon />
              }
            ]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.categories_count, name: <CategoriesIcon /> }]
          : []),
        ...(role === 'administrator'
          ? [{ value: item?.coaches_count, name: <CoachesIcon /> }]
          : []),
        ...(role === 'master'
          ? [{ value: item?.address, name: t('address') }]
          : [])
      ]
    };
  };

  const classes2 = useStylesRecords(recordDataParams({})?.info);

  return (
    <Table
      {...{ recordDataParams }}
      data={filteredTournaments.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )}
      // onClick={(evt, item) => this.rowClickedHandler(evt, item)}
      // shouldAllowEditing={() => true}
      specificDetails={{
        className: { gridRecordsValue: classes2.gridRecordsValue }
      }}
      actionIcons={(item, _, className) => (
        <>
          {!(
            role === 'master' &&
            (item.finished === '1' || !+item.is_master)
          ) && (
            <>
              <Tooltip title={t('updateRecord', { name: t('event') })}>
                <EditIcon
                  id={EDIT_TOURNAMENT_ICON}
                  className={clsx(className)}
                  onClick={(evt) => showModal(evt, item)}
                />
              </Tooltip>
              <Tooltip title={t('deleteRecord', { name: t('event') })}>
                <DeleteIcon
                  id={DELETE_ICON}
                  className={clsx(className, classes.marginLeft1)}
                  onClick={(evt) => showModal(evt, item)}
                />
              </Tooltip>
            </>
          )}
          <Tooltip title={t('event')}>
            <ArrowIc
              className={clsx(
                className,
                !(
                  role === 'master' &&
                  (item.finished === '1' || !+item.is_master)
                ) && classes.marginLeft1
              )}
              onClick={(evt) => rowClickedHandler(evt, item)}
            />
          </Tooltip>
        </>
      )}
    />
  );
};

export default TableList;
