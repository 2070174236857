import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { getParticipantDetails } from 'helpers/constants';
import { isDesktopView } from '../../../../helpers/selectors';

import styles from './styles.module.css';

import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

const RowComponent = ({
  data,
  grid,
  category,
  skirmish,
  skirmishIdx,
  gridCheckboxes,
  viewportWidth,
  tournamentData,
  onSwapParticipants
}) => {
  const { t } = useTranslation();
  const { participants } = skirmish;
  const showInDesktopView = isDesktopView(viewportWidth);

  if (!participants[0].id) return <></>;

  return (
    <div className={styles.rowWrapper}>
      {/* Order */}
      <div className={styles.order}>{skirmishIdx + 1}</div>

      {/* Participant */}
      {participants?.map((participant, participantIdx) => {
        const seenIds = new Set(); // To track already encountered IDs

        const findParticipants = data.participants?.filter((it) => {
          if (participant?.ids?.includes(it.id) && !seenIds.has(it.id)) {
            seenIds.add(it.id); // Add ID to the set to mark it as seen
            return true; // Keep participant
          }
          return false; // Skip duplicates participant
        });
        const isSwapActive =
          data.participant2Swap &&
          data.participant2Swap.categoryId === grid.id &&
          data.participant2Swap.block === skirmish.position.block &&
          data.participant2Swap.round === skirmish.position.round &&
          data.participant2Swap.skirmish === skirmish.position.skirmish &&
          data.participant2Swap.position === participantIdx + 1;

        const notesResults = category?.kataRoundNames?.map(
          (label, labelIdx) => {
            const notes = participant?.results?.[labelIdx] || {};

            return {
              ...notes,
              label: label[0] ?? 'Unknown'
            };
          }
        );

        return (
          <Fragment key={participantIdx}>
            <div
              className={`${styles.wrapperParticipantsAbout} ${
                participant.isStartPosition ? styles.active : ''
              } ${isSwapActive ? styles.swap : ''}`}
              style={{
                ...(showInDesktopView && findParticipants?.[0]?.number
                  ? { gridTemplateColumns: '3em 10px 1fr' }
                  : {})
              }}
              onClick={
                participant.isStartPosition
                  ? () =>
                      onSwapParticipants({
                        block: skirmish.position.block,
                        round: skirmish.position.round,
                        skirmish: skirmish.position.skirmish,
                        position: participantIdx + 1,
                        categoryId: grid.id
                      })
                  : undefined
              }>
              {findParticipants?.map((part, partIdx) => {
                const participantDetails = getParticipantDetails(t, part);

                return (
                  <Fragment key={partIdx}>
                    {showInDesktopView && part.number && (
                      <div className={styles.participantNumber}>
                        {part.number}
                      </div>
                    )}

                    {/* Small gap */}
                    {showInDesktopView && part.number && (
                      <div className={styles.gap}></div>
                    )}

                    {/* Name and City */}
                    <div className={styles.participantAbout}>
                      <Tooltip
                        key={partIdx}
                        title={
                          <span style={{ display: 'grid' }}>
                            {participantDetails.map(
                              (partDetail, partDetailsIdx) => (
                                <span key={partDetailsIdx}>
                                  {partDetail.label}: {partDetail.val}
                                </span>
                              )
                            )}
                          </span>
                        }
                        arrow>
                        <div className={styles.name}>
                          {!+tournamentData?.no_country && part.countryISO2 && (
                            <img
                              className={styles.flag}
                              src={`/images/flags/${part.countryISO2?.toLowerCase()}.svg`}
                              alt={`${part.countryISO2} flag`}
                            />
                          )}
                          {!showInDesktopView
                            ? `${part.number} ${part.name}`
                            : part.name}
                        </div>
                      </Tooltip>
                      <div className={styles.additionalInfo}>
                        {gridCheckboxes[grid.id]
                          ?.map((it) => part[it])
                          .filter((partVal) => partVal)
                          .join(', ')}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            <div className={styles.notesAndAdditionalWrapper}>
              {/* Notes */}
              <div className={styles.notesWrapper}>
                {notesResults?.map((note, noteIdx) => (
                  <div key={noteIdx} className={styles.noteCell}>
                    <div className={styles.disciplinesNames}>
                      <div
                        className={`${styles.label} ${styles.labelDisciplinesNames}`}>
                        {note.label}
                      </div>
                      <div className={styles.label}>{t('secondTry')}</div>
                    </div>
                    <div className={styles.disciplinesNames}>
                      <div className={styles.value}>{note?.value ?? 0}</div>
                      <div className={styles.value}>
                        <CheckboxBtn
                          formControlClassName={styles.checkboxIcon}
                          className={styles.checkboxIcon}
                          checked={!!+note?.attempts}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Additional columns */}
              <div className={styles.additionalColumns}>
                {[
                  {
                    label: t('outcome').toLowerCase(),
                    value:
                      participant?.results?.reduce(
                        (total, current) => total + current.value,
                        0
                      ) || 0
                  },
                  {
                    label: t('rank').toLowerCase(),
                    value: participant?.place ?? '-'
                  },
                  {
                    label: t('weight').toLowerCase(),
                    value: findParticipants?.[0]?.weight ?? '-'
                  }
                ]?.map((col, colIdx) => (
                  <div key={colIdx} className={styles.additionalCell}>
                    <div
                      className={`${styles.label} ${
                        showInDesktopView && styles.labelDisciplinesNames
                      }`}>
                      {col.label}
                    </div>
                    <div className={styles.value}>{col.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

const Tameshiwaris = ({
  data,
  grid,
  category,
  gridCheckboxes,
  viewportWidth,
  tournamentData,
  onSwapParticipants
}) => {
  return (
    <div className={styles.wrapper}>
      {grid?.skirmishes?.map((skirmish, skirmishIdx) => (
        <RowComponent
          key={skirmishIdx}
          {...{
            data,
            grid,
            category,
            skirmish,
            skirmishIdx,
            gridCheckboxes,
            viewportWidth,
            tournamentData,
            onSwapParticipants
          }}
        />
      ))}
    </div>
  );
};

export default Tameshiwaris;
