import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  btn: {
    height: '3.429em',
    padding: '0.2em 0.5rem',
    minWidth: 'fit-content',
    textTransform: 'none',
    borderRadius: '0.3rem'
  },

  defaultBtn: {
    color: '#8996AC',
    border: '1px solid #8996AC',
    backgroundColor: '#E9ECF6',
    '&:hover': {
      opacity: 0.6
    }
  },

  activeBtn: {
    backgroundColor: '#005FEE',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1C48B9'
    }
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  whiteBackground: {
    backgroundColor: '#FFFFFF'
  },

  disabledBtn: {
    backgroundColor: '#D0D0D0',
    color: '#262441 !important'
  }
}));
