import React, { useEffect, useState, Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { getParticipantDetails } from 'helpers/constants';

import WonPlaces from '../../../../components/WonPlaces';

import './styles.css';

const MIN_SKIRMISH_HEIGHT = 72;
const SKIRMISH_WIDTH = 249;
const ROUND_WIDTH = 280;
const MIN_MARGIN = 5;
const MIN_SPACE = 15; // Min padding between skirmishes

const calculatePositions = (skirmishes, category) => {
  const positions = [];

  // Calculate the max number of participants from all skirmishes
  const maxIdsLength = Math.max(
    ...skirmishes.flatMap((skirmish) =>
      skirmish.participants.map((p) => (p.ids ? p.ids.length : 0))
    )
  );

  // Calculate skirmish height based on the max number of participants, 45px space for one participant
  const teamTypeAdjustment = category?.teamType ? 40 : 0; // 40px height for teamName
  const skirmishHeight = Math.max(
    Math.min(45 * maxIdsLength + teamTypeAdjustment),
    MIN_SKIRMISH_HEIGHT + teamTypeAdjustment
  );

  // Sort skirmishes by order ASC
  const sortedSkirmishes = [...skirmishes].sort((a, b) => a.order - b.order);

  sortedSkirmishes.forEach((skirmish) => {
    const { round, block } = skirmish.position;
    const winnerTo = skirmish.winnerTo;
    const loserTo = skirmish.loserTo;

    // Default x position based on round
    let x = (round - 1) * ROUND_WIDTH + MIN_MARGIN;

    // Inward shift logic (based on previous rounds)
    if (round >= 3) {
      const previousSkirmishes = getPreviousRoundSkirmishes(
        positions,
        block,
        round
      );

      if (previousSkirmishes.length >= 2) {
        // Use the left position of the last skirmish to adjust inward
        const [lastSkirmish, secondLastSkirmish] = previousSkirmishes.map(
          (skirmish) => skirmish
        );
        const verticalSpaceAvailable = Math.abs(
          lastSkirmish.top + skirmishHeight - secondLastSkirmish.top
        );

        // Only adjust inward if there’s enough space
        if (verticalSpaceAvailable >= skirmishHeight + MIN_SPACE) {
          x = lastSkirmish.left + MIN_MARGIN + SKIRMISH_WIDTH / 2;
        }
      }
    }

    // Find the y position considering block and dynamic space for eliminated skirmishes
    const block2SkirmishY =
      Math.max(...positions.map((it) => it.top)) + skirmishHeight;
    const y =
      block === 1
        ? getVerticalPosition(positions, skirmish.position, skirmishHeight) +
          MIN_SPACE
        : block2SkirmishY + MIN_SPACE;

    // Push the calculated position to the positions array
    positions.push({
      ...skirmish.position,
      top: y,
      left: x,
      winnerTo,
      loserTo
    });
  });

  // Get the left position of the last skirmish from block:1
  let lastBlock1SkirmishLeft = null;

  // Loop through positions in reverse to find the last block:1 skirmish
  for (let i = positions.length - 1; i >= 0; i--) {
    const pos = positions[i];
    if (pos.block === 1) {
      lastBlock1SkirmishLeft = pos.left;
      break;
    }
  }

  // If we found the last block:1 skirmish, set the left position for block:2 skirmish
  if (lastBlock1SkirmishLeft !== null) {
    const block2Skirmish = positions.find((pos) => pos.block === 2);
    if (block2Skirmish) {
      block2Skirmish.left = lastBlock1SkirmishLeft;
    }
  }

  return { positions, skirmishHeight };
};

// Helper function to get previous skirmishes from the same block and round
function getPreviousRoundSkirmishes(positions, block, round) {
  return positions.filter(
    (pos) => pos.block === block && pos.round === round - 1
  );
}

// Vertical position calculation
function getVerticalPosition(allPositions, currentPosition, skirmishHeight) {
  const emptySlots = currentPosition.skirmishAbs - currentPosition.skirmish;
  const dynamicGap = emptySlots * (skirmishHeight / 2 + MIN_SPACE);

  let yPosition =
    (currentPosition.skirmish - 1) * (skirmishHeight + MIN_SPACE) + dynamicGap;

  // Filter previous skirmishes that are related to the current position
  const previousSkirmishes = allPositions.filter(
    (pos) =>
      (pos.winnerTo &&
        pos.winnerTo.round === currentPosition.round &&
        pos.winnerTo.block === currentPosition.block &&
        pos.winnerTo.skirmish === currentPosition.skirmishAbs) ||
      (pos.loserTo &&
        pos.loserTo.round === currentPosition.round &&
        pos.loserTo.block === currentPosition.block &&
        pos.loserTo.skirmish === currentPosition.skirmishAbs)
  );

  if (previousSkirmishes.length > 0) {
    const yPositions = previousSkirmishes.map((el) => el.top);

    if (previousSkirmishes.length === 1) {
      // Adjust y position for a single previous skirmish
      const singlePosition = yPositions[0];
      yPosition = Math.max(singlePosition - skirmishHeight + MIN_SPACE, 0);
    } else if (previousSkirmishes.length === 2) {
      // Adjust y position for two previous skirmishes (centered)
      const [y1, y2] = yPositions;
      yPosition = (y1 + y2) / 2 - MIN_SPACE;
    }
  }

  // Prevent overlapping within the same round
  const sameRoundSkirmishes = allPositions.filter(
    (pos) =>
      pos.block === currentPosition.block &&
      pos.round === currentPosition.round &&
      pos.skirmishAbs !== currentPosition.skirmishAbs
  );

  if (sameRoundSkirmishes.length > 0) {
    const yPositions = sameRoundSkirmishes.map((el) => el.top);
    yPosition = Math.max(yPosition, Math.max(...yPositions) + skirmishHeight);
  }

  return Math.max(yPosition, MIN_MARGIN);
}

const Skirmish = ({
  grid,
  skirmish,
  position,
  data,
  onSwapParticipants,
  tournamentData,
  gridCheckboxes,
  skirmishHeight,
  category
}) => {
  const { t } = useTranslation();
  const { participants, name } = skirmish;

  return (
    <div
      id={`block_${skirmish.position.block}_round_${skirmish.position.round}_skirmishAbs_${skirmish.position.skirmishAbs}`}
      className="skirmish"
      style={{
        height: skirmishHeight, // Adjust the Skirmish Height dynamically to accommodate multiple participants in a column if necessary.
        left: position?.left ?? 0,
        top: position?.top ?? 0,
        position: 'absolute'
      }}>
      {skirmish.roundName && (
        <div className="round-name-badge">{skirmish.roundName}</div>
      )}
      <h6 className="skirmish-name">{name}</h6>
      <div className="participants">
        {participants.map((p, pIdx) => {
          const winner = grid.skirmishes.find(
            (it) =>
              it.winnerTo?.block === skirmish.position.block &&
              it.winnerTo?.round === skirmish.position.round &&
              it.winnerTo?.skirmish === skirmish.position.skirmishAbs &&
              it.winnerTo?.position === pIdx + 1
          );
          const loser = grid.skirmishes.find(
            (it) =>
              it.loserTo?.block === skirmish.position.block &&
              it.loserTo?.round === skirmish.position.round &&
              it.loserTo?.skirmish === skirmish.position.skirmishAbs &&
              it.loserTo?.position === pIdx + 1
          );

          const waitingParticipant = winner
            ? t('winnerOf', { name: winner?.name })
            : loser
            ? t('loserOf', { name: loser?.name })
            : `${t('wainting')}...`;

          const participant = [];

          if (Array.isArray(p.ids)) {
            for (const pId of p.ids) {
              // Find a matching participant for the current pId
              const currentP = data.participants.find((it) => it.id === pId);

              if (participant) {
                participant.push(currentP);
              }
            }
          }

          const wonPlace =
            participant?.length > 0 &&
            skirmish.winnerPos &&
            ((skirmish.winnerPos === pIdx + 1 && skirmish.winnerPlace) ||
              skirmish.loserPlace);
          const isSwapActive =
            data.participant2Swap &&
            data.participant2Swap.categoryId === grid.id &&
            data.participant2Swap.block === skirmish.position.block &&
            data.participant2Swap.round === skirmish.position.round &&
            data.participant2Swap.skirmish === skirmish.position.skirmish &&
            data.participant2Swap.position === pIdx + 1;

          return (
            <span
              key={`${name}_${pIdx}`}
              className={`participant ${p.isStartPosition && 'active'} ${
                isSwapActive && 'swap'
              }`}
              style={{
                position: 'relative'
              }}
              onClick={
                p.isStartPosition
                  ? () =>
                      onSwapParticipants({
                        block: skirmish.position.block,
                        round: skirmish.position.round,
                        skirmish: skirmish.position.skirmish,
                        position: pIdx + 1,
                        categoryId: grid.id
                      })
                  : undefined
              }>
              {/* The border span */}
              <span
                className="border_participant"
                style={{
                  backgroundColor:
                    participant?.length > 0 && pIdx === 0
                      ? `#${tournamentData?.participant_up_color_hex}`
                      : pIdx !== 0 && participant
                      ? `#${tournamentData?.participant_down_color_hex}`
                      : 'transparent' // Default to transparent if no color is found
                }}></span>

              {category?.teamType &&
                participant?.length > 0 && ( // If a team exists, add an additional 40px for the team name height.
                  <span className="kumiteTeamName ellipsis">
                    {`${p?.teamName ?? t('team')}:`}
                  </span>
                )}
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {wonPlace && (
                  <WonPlaces
                    specificDetails={{
                      place: wonPlace,
                      style: { icon: { width: '1.5em', height: '1.5em' } }
                    }}
                  />
                )}
                <span
                  style={{
                    width: wonPlace ? 'calc(100% - 1.5em)' : '100%'
                  }}>
                  <span
                    className={'participant_name ellipsis'}
                    style={{
                      ...(participant?.length > 0
                        ? {}
                        : {
                            color: !(loser || winner) ? '#f44741' : '#8996ac',
                            fontWeight: 'normal'
                          })
                    }}>
                    {participant?.length > 0 ? (
                      <span
                        style={{
                          display: 'grid',
                          ...(!category?.teamType ? { fontWeight: 500 } : {})
                        }}>
                        {participant?.map((part, partIdx) => {
                          if (!part) return null;

                          const participantDetails = getParticipantDetails(
                            t,
                            part
                          );

                          return (
                            <Tooltip
                              key={partIdx}
                              title={
                                <span style={{ display: 'grid' }}>
                                  {participantDetails.map(
                                    (partDetail, partDetailsIdx) => (
                                      <span key={partDetailsIdx}>
                                        {partDetail.label}: {partDetail.val}
                                      </span>
                                    )
                                  )}
                                </span>
                              }
                              arrow>
                              <span key={partIdx}>
                                {!+tournamentData?.no_country &&
                                  part.countryISO2 && (
                                    <img
                                      className="flag"
                                      src={`/images/flags/${part.countryISO2?.toLowerCase()}.svg`}
                                      alt={`${part.countryISO2} flag`}
                                    />
                                  )}
                                {part.number && `${part.number}. `}
                                {part?.name}
                              </span>
                            </Tooltip>
                          );
                        })}
                      </span>
                    ) : (
                      waitingParticipant
                    )}
                  </span>
                  {/* IS WINNER */}
                  {skirmish.winnerPos === pIdx + 1 && (
                    <span className={'participant_winner_circle'} />
                  )}

                  {/* PARTICIPANT DETAILS */}
                  {participant?.length > 0 && (
                    <span className="ellipsis" style={{ fontSize: '10px' }}>
                      {gridCheckboxes[grid.id]
                        ?.map((it) => participant[0][it])
                        .filter((partVal) => partVal)
                        .join(', ')}
                    </span>
                  )}
                </span>
              </span>
            </span>
          );
        })}
      </div>
    </div>
  );
};

const Block = ({
  grid,
  data,
  positions,
  rounds,
  onSwapParticipants,
  tournamentData,
  gridCheckboxes,
  skirmishHeight,
  category
}) => {
  const calculatePath = (currentSkirmish, targetSkirmish) => {
    // Start from the right edge of the current skirmish
    const startX = currentSkirmish.left + SKIRMISH_WIDTH;
    const startY = currentSkirmish.top + skirmishHeight / 2; // Center of current skirmish

    // Adjust targetY based on whether the target skirmish is above or below the current one
    const targetY =
      targetSkirmish.top < currentSkirmish.top
        ? targetSkirmish.top + skirmishHeight // Target skirmish is above, so use its bottom edge
        : targetSkirmish.top; // Target skirmish is below, so use its top edge

    const horizontalLineLength = 42; // horizontal line length

    // Calculate the end of the horizontal line
    const endX = startX + horizontalLineLength;

    // Calculate the rounded corner control points for smooth transition between horizontal and vertical lines
    const curveOffset = 10; // Adjust for the curve smoothness
    const curveControlX = endX + curveOffset; // Curve control point in X direction

    // Start the path from the current skirmish (right edge)
    let path = `M${startX} ${startY}`;

    // Add the horizontal segment (horizontalLineLength)
    path += ` H${endX}`;

    // Add the curve for the rounded corner, transitioning into the vertical line
    path += ` C${curveControlX} ${startY} ${curveControlX} ${startY} ${curveControlX} ${targetY}`;

    // Now the line moves straight vertically to the target skirmish
    path += ` V${targetY}`;

    return path;
  };

  const pathData = positions.map((skirmish) => {
    const paths = [];

    // winnerTo
    if (skirmish.winnerTo) {
      const target = positions.find(
        (s) =>
          s.round === skirmish.winnerTo.round &&
          s.block === skirmish.winnerTo.block &&
          s.skirmishAbs === skirmish.winnerTo.skirmish
      );
      if (target) {
        paths.push(calculatePath(skirmish, target));
      }
    }

    // loserTo
    if (skirmish.loserTo) {
      const target = positions.find(
        (s) =>
          s.round === skirmish.loserTo.round &&
          s.block === skirmish.loserTo.block &&
          s.skirmishAbs === skirmish.loserTo.skirmish
      );
      if (target && skirmish.loserTo.round === 1) {
        paths.push(calculatePath(skirmish, target));
      }
    }

    return paths;
  });

  return (
    <>
      <svg className="connections">
        {pathData.map((d, index) => (
          <path key={index} d={d} stroke="#8996AC" fill="transparent" />
        ))}
      </svg>
      {Object.keys(rounds).map((round) => {
        return (
          <Fragment key={round}>
            {rounds[round].map((skirmish, skirmishIdx) => {
              let position = positions.find((pos) => {
                return (
                  pos.block === skirmish.position.block &&
                  pos.round === skirmish.position.round &&
                  pos.skirmishAbs === skirmish.position.skirmishAbs
                );
              });

              return (
                <Skirmish
                  key={skirmishIdx}
                  {...{
                    grid,
                    skirmish,
                    data,
                    position,
                    onSwapParticipants,
                    tournamentData,
                    gridCheckboxes,
                    skirmishHeight,
                    category
                  }}
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

// Draw Kumite
const KumiteGrid = ({
  data,
  grid,
  category,
  onSwapParticipants,
  tournamentData,
  gridCheckboxes
}) => {
  const [positions, setPositions] = useState([]);
  const [skirmishHeight, setSkirmishHeight] = useState(MIN_SKIRMISH_HEIGHT);

  useEffect(() => {
    const skirmishes = grid.skirmishes ?? [];
    const { positions: result, skirmishHeight } = calculatePositions(
      skirmishes,
      category
    );

    setPositions(result);
    setSkirmishHeight(skirmishHeight);
  }, [grid.skirmishes]);

  // organize data by blocks and rounds
  const blocks = { 1: [], 2: [] };
  grid.skirmishes?.forEach((skirmish) => {
    const block = skirmish.position.block;
    const round = skirmish.position.round;

    blocks[block]?.push({ ...skirmish, round });
  });

  const rounds = Object.values(blocks).map((skirmishes) => {
    return skirmishes.reduce((acc, skirmish) => {
      if (!acc[skirmish.position.round]) acc[skirmish.position.round] = [];
      acc[skirmish.position.round].push(skirmish);
      return acc;
    }, {});
  });

  const bracketHeight =
    (positions
      ? Math.max(...positions.map((it) => it.top)) + skirmishHeight
      : skirmishHeight) +
    skirmishHeight / 2 +
    MIN_MARGIN;
  const bracketWidth = Object.keys(rounds?.[0]).pop() * ROUND_WIDTH + 40;

  return (
    <PerfectScrollbar id="bracket-grid" className="bracket-scroll">
      <div
        className="bracket-inner"
        style={{
          width: `${bracketWidth}px`,
          height: `${bracketHeight}px`
        }}>
        {Object.keys(blocks).map((block, blockIdx) => (
          <Block
            key={block}
            skirmishes={blocks[block]}
            {...{
              data,
              grid,
              positions,
              block,
              onSwapParticipants,
              tournamentData,
              gridCheckboxes,
              skirmishHeight,
              category
            }}
            rounds={rounds?.[blockIdx]}
          />
        ))}
      </div>
    </PerfectScrollbar>
  );
};

export default KumiteGrid;
