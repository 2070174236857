import { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as Clear } from 'assets/img/clear_grey_large.svg';

import { ALL_TEAMS, CATEGORY_ID, ID } from '../../helpers/constants';
import { selectedValue, isDesktopView } from '../../helpers/selectors';

import Filter from '../Filter/Filter';

import { useStyles } from './Styles';

const AssignCategories = (props) => {
  const {
    categoryTypes,
    selectedCategories,
    removeCategory,
    selectCategories,
    selectCategoryType,
    selectTeam,
    selectedCategoriesErrors,
    shouldDisableInput,
    switchTournament,
    tournamentsList,
    viewportWidth,
    tournamentIdParams,
    tournamentData,
    hasSelectTournament
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const hasDesktopSize = isDesktopView(viewportWidth);

  return (
    <>
      {selectedCategories?.map((val, idx) => {
        const selectors = [
          ...(!tournamentIdParams && idx === 0 && hasSelectTournament //show tournament selector on 'My Team' page
            ? [
                {
                  value: tournamentData?.id,
                  key: ID,
                  options: tournamentsList,
                  selectOption: switchTournament,
                  label: t('tournament'),
                  item: 'tournament_name',
                  int: true
                }
              ]
            : []),
          {
            value: val.type,
            key: ID,
            options: categoryTypes,
            selectOption: selectCategoryType,
            label: t('discipline'),
            item: 'name',
            error: Boolean(selectedCategoriesErrors[idx]?.type),
            helperText: selectedCategoriesErrors[idx]?.type,
            int: true,
            disabled: shouldDisableInput
          },
          {
            current_category_name: val.name,
            value: val.id,
            key: CATEGORY_ID,
            options: val.filteredCategories,
            selectOption: selectCategories,
            label: t('category'),
            specificDetails: {
              renderOptionLabel: (op) => (
                <span>
                  {op.category_name}
                  {!!+tournamentData?.use_alt_category_name &&
                    op.category_alt_name && (
                      <span
                        style={{
                          ...(op.category_name !== op.category_alt_name
                            ? { color: '#005FEE' }
                            : {})
                        }}>{` (${op.category_alt_name})`}</span>
                    )}
                </span>
              )
            },
            item: {
              lastName: 'category_alt_name',
              firstName: 'category_name'
            },
            error: Boolean(selectedCategoriesErrors[idx]?.id),
            helperText: selectedCategoriesErrors[idx]?.id,
            int: true,
            disabled: shouldDisableInput
          },
          val?.typeInfo?.team && {
            value: val.team_id,
            key: 'team_id',
            options: ALL_TEAMS,
            selectOption: selectTeam,
            label: t('team'),
            item: 'team_id',
            error: Boolean(selectedCategoriesErrors[idx]?.team_id),
            helperText: selectedCategoriesErrors[idx]?.team_id,
            int: true
          }
        ];
        return (
          <Fragment key={idx}>
            <div className={clsx(classes.marginTop, classes.gridFilters)}>
              {selectors
                .filter((it) => it)
                .map(
                  (
                    {
                      value,
                      options,
                      selectOption,
                      label,
                      item,
                      key,
                      error,
                      helperText,
                      current_category_name,
                      int,
                      disabled,
                      specificDetails
                    },
                    index
                  ) => (
                    <div
                      className={
                        hasDesktopSize || index === 0
                          ? classes.gridFilterType
                          : undefined
                      }
                      key={index}>
                      <Filter
                        className={clsx(classes.placeholder)}
                        {...{ options }}
                        {...{ label }}
                        value={selectedValue(options, key, value, int) || ''}
                        inputLabelProps={
                          value && !selectedValue(options, key, value)
                            ? {
                                shrink: true
                              }
                            : {}
                        }
                        placeholder={
                          value && !selectedValue(options, key, value)
                            ? current_category_name
                            : ''
                        }
                        onChange={(evt, val) => {
                          selectOption(
                            evt,
                            val,
                            idx,
                            selectedCategories,
                            selectedCategoriesErrors?.[idx],
                            { categoryTypes }
                          );
                        }}
                        {...{ item, specificDetails }}
                        variant="outlined"
                        {...{ error }}
                        {...{ helperText }}
                        {...{ disabled }}
                      />

                      {index === 0 && (
                        <Clear
                          className={classes.icon}
                          onClick={() => removeCategory(val)}
                        />
                      )}
                    </div>
                  )
                )}
            </div>
            {selectedCategories?.length - 1 !== idx && (
              <span
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #AEBBD0',
                  margin: '1.5rem 0'
                }}></span>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
export default AssignCategories;
