export const styles = () => ({
  flex: { display: 'flex' },

  centerHorizontally: {
    justifyContent: 'center'
  },

  centerVertically: {
    alignItems: 'center'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  label: {
    width: '10em',
    marginRight: '0.5rem',
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },

  outlined: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 0
    }
  },

  wrapperSelectTeamNum: {
    margin: '0.5rem 0'
  },

  checkboxItem: {
    margin: '10px 5px',
    minWidth: '120px',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  interactiveHeaderWrapper: {
    marginTop: '1em',
    display: 'grid',
    gridGap: '1%',
    '@media (min-width:1024px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateAreas: `'search search search filter copy transfer'`
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridGap: '1em',
      gridTemplateAreas: `
        'search search search'
        'filter copy transfer'
      `,
      gridTemplateColumns: '1fr 1fr 1fr'
    },
    '@media (max-width:767px)': {
      gridGap: '0.5em',
      gridTemplateAreas: `
        'search search'
        'filter filter'
        'copy transfer'
      `
    }
  },

  activeState: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },

  marginBottom1: {
    marginBottom: '1rem'
  }
});

export default styles;
