import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ID, TEST_ID_INFO_BTN } from 'helpers/constants';
import { selectedValue } from 'helpers/selectors';

import RadioBtn from 'components/RadioBtn/RadioBtn';

import classes from './styles.module.css';

const tatamiStyle = (bg, color) => ({
  color: color,
  background: bg || '#E9ECF6',
  border: bg ? 'none' : '1px solid #A2ABBA'
});

const GridSelection = (props) => {
  const {
    selectBlocksOrTatamis,
    selectRadioBtn,
    showModal,
    filters,
    tatamis,
    tatamisTheme,
    viewportWidth
  } = props;

  const { t } = useTranslation();
  const smallScreen = viewportWidth <= 776;

  return (
    <>
      {/* Select Category Type */}
      <span
        className={`${classes.sectionHeader} ${classes.sectionHeaderTypes}`}>
        {t('selectDiscipline')}
      </span>
      <RadioBtn
        options={filters?.categoryTypes}
        item="id"
        value={+filters?.typeId || ''}
        onClick={selectRadioBtn}
        specificDetails={{
          labelElem: (el) => el.name,
          className: {
            radioIcon: classes.radioIc,
            radioGroup: [classes.fullWidth, classes.radioWrapper]
          }
        }}
      />

      {/* Select All Blocks */}
      {filters?.typeId && (
        <>
          {tatamis?.length > 0 && (
            <span
              onClick={() =>
                selectBlocksOrTatamis(tatamis, 'allBlocks', { block: null })
              }
              className={`${classes.sectionHeader} ${classes.sectionHeaderAllBlocks} ${classes.marginTop1} ${classes.onHover}`}>
              {t('selectAllBlocks')}
            </span>
          )}
          {Array.isArray(tatamis) &&
            tatamis.map((block, blockIdx) => {
              const blockName =
                block?.find((it) => it.block_name)?.block_name ||
                t('block', { count: block?.[0]?.block });

              return (
                <span
                  key={blockIdx}
                  className={clsx(
                    classes.flex,
                    classes.marginTop1,
                    classes.column
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.blockWrapper,
                      classes.column
                    )}>
                    <span
                      onClick={() =>
                        selectBlocksOrTatamis(block, 'block', {
                          block: block?.[0]?.block
                        })
                      }
                      className={`${classes.sectionHeader} ${classes.sectionHeaderAllBlocks} ${classes.onHover}`}>
                      {`${t('select')} `}
                      {t('block', { count: block?.[0]?.block })}
                      {!smallScreen &&
                        t('block', { count: block?.[0]?.block }) !==
                          blockName &&
                        ` (${blockName})`}
                    </span>

                    <span className={`${classes.blockTatamisWrapper}`}>
                      {Array.isArray(block) &&
                        block.map((item, idx) => {
                          const tatamiBg = selectedValue(
                            tatamisTheme,
                            ID,
                            item.theme_id,
                            true
                          )?.color1;

                          return (
                            <span
                              onClick={() =>
                                selectBlocksOrTatamis(item, 'tatami', {
                                  block: item.block
                                })
                              }
                              style={tatamiStyle(tatamiBg, '#fff')}
                              className={clsx(
                                classes.flex,
                                classes.relative,
                                classes.tatamiWrapper,
                                classes.onHover,
                                classes.column
                              )}
                              key={idx}>
                              {item?.categories?.[filters?.typeId] && (
                                <span
                                  onClick={(evt) => {
                                    evt.stopPropagation();

                                    showModal(evt, item, TEST_ID_INFO_BTN);
                                  }}
                                  className={clsx(classes.badge)}>
                                  {item?.categories?.[filters?.typeId].length}
                                </span>
                              )}
                              <span className={clsx(classes.tatami)}>
                                {t('tatami')} {item.tatami_name}
                              </span>
                            </span>
                          );
                        })}
                    </span>
                  </span>
                </span>
              );
            })}
        </>
      )}
    </>
  );
};

export default GridSelection;
