import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as ScheduleBlackIcon } from 'assets/img/schedule.svg';
import { ReactComponent as ScheduleWhiteIcon } from 'assets/img/schedule-white.svg';
import { ReactComponent as SkirmishesWhiteIcon } from 'assets/img/skirmishes-white.svg';
import { ReactComponent as SkirmishesBlackIcon } from 'assets/img/skirmishes_black.svg';
import { ReactComponent as PersonBlackIcon } from 'assets/img/members_num_black.svg';
import { ReactComponent as PersonWhiteIcon } from 'assets/img/members_num_white.svg';
import { ReactComponent as ListWhiteIcon } from 'assets/img/list-white.svg';

import GroupIcon from '@material-ui/icons/Group';

import { convertMs, isMobileView } from '../../helpers/selectors';
import { REASSIGN_CATEGORIES_SORT_OPTIONS } from '../../helpers/constants';

import Button from '../Buttons/ActionButtons';

import {
  getListStyle,
  getItemStyle,
  useStyles,
  getTatamiTabStyle
} from './StylesDroppableContent';

const blockStyle = (isDraggingOver) => ({
  ...(isDraggingOver ? { background: '#8996AC', color: '#fff' } : {})
});

const DroppableContent = (props) => {
  const {
    windowWidth,
    onChangeTatami,
    onChangeBlock,
    activeBlockIdx,
    activeTatamiIdx,
    blocks,
    tatamisTheme,
    shouldDisableEditing,
    categoryTypes,
    tournamentData,
    onCategoryTatamiSort
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const mobileScreen = isMobileView(windowWidth);
  const summary = (array, key, cond) =>
    array.reduce((accumulator, value) => accumulator + value[key], 0);

  const onFlipCard = (item) => {
    const allFlippedElem = document.querySelector(classes.cardIsFliped);
    const cardIdxEl = document.getElementById(
      `TEST_ID_CATEGORY_CARD_IDX_${item.category_id}`
    );

    if (allFlippedElem) {
      allFlippedElem.classList.remove(classes.cardIsFliped);
    }

    const card = document.getElementById(`TEST_ID_CARD_${item.category_id}`);

    card.classList.toggle(classes.cardIsFliped);

    if (card.classList.contains(classes.cardIsFliped)) {
      cardIdxEl.classList.add(classes.hide);
    } else {
      cardIdxEl.classList.remove(classes.hide);
    }
  };

  const iconsTatami = (it, content) => {
    const convertedTime = convertMs(summary(it[content], 'total_time'));
    const resultTime = [convertedTime?.hours, convertedTime.minutes].join(':');

    return [
      {
        icon: <ListWhiteIcon className={classes.subSvg} />,
        count: it[content].length
      },
      {
        icon: <PersonWhiteIcon className={classes.subSvg} />,
        count: summary(it[content], 'participants_count')
      },
      {
        icon: <SkirmishesWhiteIcon className={classes.subSvg} />,
        count: summary(it[content], 'skirmishes_count')
      },
      {
        icon: <ScheduleWhiteIcon className={classes.subSvg} />,
        count: resultTime
      }
    ];
  };

  const iconsCategories = (item) => {
    const convertedTime = convertMs(item.total_time);
    const resultTime = [
      convertedTime?.hours,
      convertedTime.minutes,
      convertedTime.seconds
    ].join(':');

    return [
      ...(item?.typeInfo?.team
        ? [
            {
              icon: <GroupIcon className={clsx(classes.subSvg)} />,
              count: item.teams_count
            }
          ]
        : []),
      {
        icon: <PersonBlackIcon className={clsx(classes.subSvg)} />,
        count: item.participants_count
      },
      ...(`${item.category_type}` !== '4'
        ? [
            {
              icon: <SkirmishesBlackIcon className={clsx(classes.subSvg)} />,
              count: item.skirmishes_count
            }
          ]
        : []),
      {
        icon: <ScheduleBlackIcon className={clsx(classes.subSvg)} />,
        count: resultTime
      }
    ];
  };

  const overConstraintWidth = windowWidth > 1680;
  const extraTime = (item) => [
    {
      label: overConstraintWidth ? 'Main Time' : 'MT',
      value: item.category_main_time
    },
    {
      label: overConstraintWidth ? 'Extra Time' : 'ET',
      value: item.extra_time_ms
    },
    {
      label: overConstraintWidth ? 'Final Main Time' : 'FMT',
      value: item.final_main_time_ms
    },
    {
      label: overConstraintWidth ? 'Final Extra Time' : 'FET',
      value: item.final_extra_time_ms
    }
  ];
  const SubItem = ({ icon, count, className, txtStyle }) => (
    <span className={clsx(classes.flex, classes.wrap, className)}>
      {icon}
      <span className={txtStyle}>{count}</span>
    </span>
  );

  const currentOpenedList =
    blocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]?.filteredCategories;

  const half = Math.ceil(currentOpenedList?.length / 2);
  const column1 = currentOpenedList.slice(0, half);
  const column2 = currentOpenedList.slice(half);

  return (
    <div className={classes.upperWrapper}>
      <div className={clsx(classes.flex, classes.column)}>
        <span
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '1rem'
          }}>
          {REASSIGN_CATEGORIES_SORT_OPTIONS.map((el) => (
            <Button
              key={el.id}
              onClick={() => onCategoryTatamiSort(el.id)}
              label={el.label}
              className={classes.sortBtns}
            />
          ))}
        </span>

        <sub
          className={clsx(
            classes.flex,
            classes.subLabel,
            classes.subLabelCategories,
            classes.centerHorizontally
          )}>
          {t('categories')}
        </sub>
        <div className={classes.droppableWrapper}>
          <Droppable droppableId={`droppable_active_1`}>
            {(provided, snapshot) => (
              <div
                className={clsx(classes.listStyle)}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {column1?.map((item, index) => {
                  const findCategoryType = categoryTypes.find(
                    (it) => +it.id === +item.category_type
                  );
                  let subItemTime;

                  if (findCategoryType?.weighted) {
                    const subItemConvertedTime = convertMs(
                      extraTime(item)?.[0]?.value
                    );

                    subItemTime = [
                      subItemConvertedTime.minutes,
                      subItemConvertedTime.seconds
                    ].join(':');
                  }

                  return (
                    <Draggable
                      key={item.category_id}
                      draggableId={`${item.category_id}`}
                      index={index}
                      isDragDisabled={shouldDisableEditing}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          id={`TEST_ID_CARD_${item.category_id}`}
                          className={clsx(classes.flipCard, classes.relative)}
                          onClick={() => onFlipCard(item)}>
                          <sub
                            id={`TEST_ID_CATEGORY_CARD_IDX_${item.category_id}`}
                            className={classes.itemIndexes}>
                            {index + 1}
                          </sub>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.column,
                              classes.wordBreak,
                              classes.side,
                              classes.flipCardInner,
                              classes.front
                            )}>
                            <span
                              className={classes.textContainer}
                              style={{
                                ...(+item?.participants_count === 1 ||
                                +item.teams_count === 1
                                  ? { color: '#ff0000' }
                                  : {})
                              }}>
                              {item.category_name}
                              {!!+tournamentData?.use_alt_category_name &&
                                item.category_alt_name && (
                                  <span
                                    style={{
                                      ...(item.category_name !==
                                      item.category_alt_name
                                        ? { color: '#005FEE' }
                                        : {})
                                    }}>{` (${item.category_alt_name})`}</span>
                                )}
                            </span>
                            <sub
                              className={clsx(
                                classes.subText,
                                classes.flex,
                                classes.wrap,
                                classes.centerVertically
                              )}>
                              {iconsCategories({
                                ...item,
                                typeInfo: findCategoryType
                              }).map(({ icon, count }, idx) => (
                                <SubItem
                                  key={`${icon + idx}`}
                                  txtStyle={classes.subTextDigit}
                                  {...{ icon }}
                                  {...{ count }}
                                />
                              ))}
                              {!findCategoryType?.weighted ? (
                                +item?.category_type !== 4 &&
                                !!+item?.kata_final && (
                                  <span className={clsx(classes.leftAuto)}>
                                    {[t('round'), 'II'].join(' ')}:{' '}
                                    {item.kata_final}
                                  </span>
                                )
                              ) : (
                                <SubItem
                                  className={classes.leftAuto}
                                  txtStyle={classes.extraTime}
                                  icon={extraTime(item)?.[0]?.label}
                                  count={subItemTime}
                                />
                              )}
                            </sub>
                          </span>
                          {+item?.category_type !== 4 && ( // tamishewari has not round II
                            <sub className={clsx(classes.back, classes.side)}>
                              {!findCategoryType?.weighted ? (
                                <div
                                  className={clsx(
                                    classes.flex,
                                    classes.centerVertically
                                  )}>
                                  {!!+item?.kata_final && (
                                    <>
                                      {[t('round'), 'II'].join(' ')}:{' '}
                                      {item.kata_final}
                                    </>
                                  )}
                                </div>
                              ) : (
                                extraTime(item).map(({ label, value }) => {
                                  const itemConvertedTime = convertMs(value);
                                  const itemTime = [
                                    itemConvertedTime.minutes,
                                    itemConvertedTime.seconds
                                  ].join(':');

                                  return (
                                    <Fragment key={label}>
                                      <SubItem
                                        txtStyle={classes.extraTime}
                                        icon={label}
                                        count={itemTime}
                                      />
                                    </Fragment>
                                  );
                                })
                              )}
                            </sub>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId={`droppable_active_2`}>
            {(provided, snapshot) => (
              <div
                className={clsx(classes.listStyle)}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                {column2?.map((item, index) => {
                  const findCategoryType = categoryTypes.find(
                    (it) => +it.id === +item.category_type
                  );
                  let subItemTime;

                  if (findCategoryType?.weighted) {
                    const subItemConvertedTime = convertMs(
                      extraTime(item)?.[0]?.value
                    );

                    subItemTime = [
                      subItemConvertedTime.minutes,
                      subItemConvertedTime.seconds
                    ].join(':');
                  }

                  return (
                    <Draggable
                      key={item.category_id}
                      draggableId={`${item.category_id}`}
                      index={index}
                      isDragDisabled={shouldDisableEditing}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          id={`TEST_ID_CARD_${item.category_id}`}
                          className={clsx(classes.flipCard, classes.relative)}
                          onClick={() => onFlipCard(item)}>
                          <sub
                            id={`TEST_ID_CATEGORY_CARD_IDX_${item.category_id}`}
                            className={classes.itemIndexes}>
                            {half + index + 1}
                          </sub>
                          <span
                            className={clsx(
                              classes.flex,
                              classes.column,
                              classes.wordBreak,
                              classes.side,
                              classes.flipCardInner,
                              classes.front
                            )}>
                            <span
                              className={classes.textContainer}
                              style={{
                                ...(+item?.participants_count === 1 ||
                                +item.teams_count === 1
                                  ? { color: '#ff0000' }
                                  : {})
                              }}>
                              {item.category_name}
                              {!!+tournamentData?.use_alt_category_name &&
                                item.category_alt_name && (
                                  <span
                                    style={{
                                      ...(item.category_name !==
                                      item.category_alt_name
                                        ? { color: '#005FEE' }
                                        : {})
                                    }}>{` (${item.category_alt_name})`}</span>
                                )}
                            </span>
                            <sub
                              className={clsx(
                                classes.subText,
                                classes.flex,
                                classes.wrap,
                                classes.centerVertically
                              )}>
                              {iconsCategories({
                                ...item,
                                typeInfo: findCategoryType
                              }).map(({ icon, count }, idx) => (
                                <SubItem
                                  key={`${icon + idx}`}
                                  txtStyle={classes.subTextDigit}
                                  {...{ icon }}
                                  {...{ count }}
                                />
                              ))}
                              {!findCategoryType?.weighted ? (
                                +item?.category_type !== 4 &&
                                +item?.category_type !== 5 &&
                                !!+item?.kata_final && (
                                  <span className={clsx(classes.leftAuto)}>
                                    {[t('round'), 'II'].join(' ')}:{' '}
                                    {item.kata_final}
                                  </span>
                                )
                              ) : (
                                <SubItem
                                  className={classes.leftAuto}
                                  txtStyle={classes.extraTime}
                                  icon={extraTime(item)?.[0]?.label}
                                  count={subItemTime}
                                />
                              )}
                            </sub>
                          </span>
                          {+item?.category_type !== 4 && ( // tamishewari has not round II
                            <sub className={clsx(classes.back, classes.side)}>
                              {!findCategoryType?.weighted ? (
                                <div
                                  className={clsx(
                                    classes.flex,
                                    classes.centerVertically
                                  )}>
                                  {!!+item?.kata_final && (
                                    <>
                                      {[t('round'), 'II'].join(' ')}:{' '}
                                      {item.kata_final}
                                    </>
                                  )}
                                </div>
                              ) : (
                                extraTime(item).map(({ label, value }) => {
                                  const itemConvertedTime = convertMs(value);
                                  const itemTime = [
                                    itemConvertedTime.minutes,
                                    itemConvertedTime.seconds
                                  ].join(':');

                                  return (
                                    <Fragment key={label}>
                                      <SubItem
                                        txtStyle={classes.extraTime}
                                        icon={label}
                                        count={itemTime}
                                      />
                                    </Fragment>
                                  );
                                })
                              )}
                            </sub>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
      {!mobileScreen ? (
        <div className={classes.flex}>
          <span className={clsx(classes.flex, classes.column)}>
            <sub
              className={clsx(
                classes.flex,
                classes.subLabel,
                classes.subLabelTatamiBlock,
                classes.centerHorizontally
              )}>
              {t('tatamis')}
            </sub>
            <span
              className={clsx(
                classes.flex,
                classes.column,
                classes.tatamisWrapper
              )}>
              {Array.isArray(blocks?.[activeBlockIdx]?.tatamis) &&
                blocks?.[activeBlockIdx]?.tatamis?.map((tab, tabIdx) => {
                  const droppableId = `droppable_block_${activeBlockIdx}_tatami_${tabIdx}`;

                  const findThemeColor = tatamisTheme?.find(
                    (it) => +it?.id === +tab?.theme_id
                  )?.color1;

                  return (
                    <Droppable key={tabIdx} {...{ droppableId }}>
                      {(provided, snapshot) => (
                        <div
                          id={droppableId}
                          ref={provided.innerRef}
                          onClick={() => {
                            onChangeTatami(tab, tabIdx);
                          }}
                          className={clsx(
                            classes.flex,
                            classes.wrap,
                            classes.column,
                            classes.droppableBrick,
                            classes.tab,
                            tabIdx === activeTatamiIdx && classes.seletedTab
                          )}
                          {...provided.droppableProps}
                          style={getTatamiTabStyle(
                            snapshot.isDraggingOver,
                            findThemeColor
                          )}>
                          {tab.tatami_name}
                          <sub className={clsx(classes.flex, classes.wrap)}>
                            {iconsTatami(tab, 'filteredCategories').map(
                              ({ icon, count }, idx) => (
                                <Fragment key={`${icon + idx}`}>
                                  <SubItem
                                    className={classes.tatamiSubWrapper}
                                    {...{ icon }}
                                    {...{ count }}
                                  />
                                </Fragment>
                              )
                            )}
                          </sub>
                        </div>
                      )}
                    </Droppable>
                  );
                })}
            </span>
          </span>
          <span
            className={clsx(classes.flex, classes.column, classes.fullWidth)}>
            <sub
              className={clsx(
                classes.flex,
                classes.subLabel,
                classes.subLabelTatamiBlock,
                classes.centerHorizontally
              )}>
              {t('block', { count: '' })}
            </sub>
            {Array.isArray(blocks) &&
              blocks.map((block, blockIdx) => {
                const droppableId = `droppable_blocks_${blockIdx}_tatami_0`;

                return (
                  <Droppable key={blockIdx} {...{ droppableId }}>
                    {(provided, snapshot) => (
                      <div
                        id={droppableId}
                        ref={provided.innerRef}
                        onClick={() => {
                          onChangeBlock(blockIdx);
                        }}
                        {...provided.droppableProps}
                        className={clsx(
                          classes.flex,
                          classes.column,
                          classes.droppableBrick,
                          activeBlockIdx === blockIdx && classes.selectedBlock
                        )}>
                        <span
                          className={clsx(classes.flex, classes.block)}
                          style={blockStyle(snapshot.isDraggingOver)}>
                          <span
                            className={clsx(
                              classes.ellipsis,
                              classes.blockTxt
                            )}>
                            {t('block', { count: block?.block_id })}. {'  '}
                            <span
                              style={{
                                ...(activeBlockIdx === blockIdx
                                  ? { color: '#000' }
                                  : {})
                              }}>
                              {block?.block_name}
                            </span>
                          </span>
                        </span>
                      </div>
                    )}
                  </Droppable>
                );
              })}
          </span>
        </div>
      ) : (
        // MOBILE VIEW
        <div>
          <span
            className={clsx(classes.flex, classes.column, classes.fullWidth)}>
            <sub
              className={clsx(
                classes.flex,
                classes.subLabel,
                classes.centerHorizontally
              )}>
              {[
                mobileScreen ? `${t('block', { count: '' })} / ` : '',
                t('tatamis')
              ].join('')}
            </sub>
            {Array.isArray(blocks) &&
              blocks.map((block, blockIdx) => {
                const droppableId = `droppable_blocks_${blockIdx}_tatami_0`;

                return (
                  <>
                    <Droppable key={blockIdx} {...{ droppableId }}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          onClick={() => {
                            onChangeBlock(blockIdx);
                          }}
                          className={clsx(
                            classes.flex,
                            classes.column,
                            classes.droppableBrick,
                            classes.block,
                            activeBlockIdx === blockIdx && classes.selectedBlock
                          )}
                          style={blockStyle(snapshot.isDraggingOver)}>
                          <span
                            className={clsx(
                              classes.ellipsis,
                              classes.blockTxt
                            )}>
                            {t('block', { count: block?.block_id })}. {'  '}
                            {block?.block_name}
                          </span>
                        </div>
                      )}
                    </Droppable>

                    {blockIdx === activeBlockIdx && (
                      <span
                        className={clsx(classes.flex, classes.column)}
                        style={{
                          borderRadius: '0.3rem',
                          padding: '0.5rem',
                          background: '#fff',
                          width: 'fit-content',
                          alignSelf: 'end',
                          marginBottom: '1rem'
                        }}>
                        {Array.isArray(blocks?.[activeBlockIdx]?.tatamis) &&
                          blocks?.[activeBlockIdx]?.tatamis?.map(
                            (tab, tabIdx) => {
                              const droppableId = `droppable_block_${activeBlockIdx}_tatami_${tabIdx}`;

                              const findThemeColor = tatamisTheme?.find(
                                (it) => +it?.id === +tab?.theme_id
                              )?.color1;

                              return (
                                <Droppable key={tabIdx} {...{ droppableId }}>
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      onClick={() => {
                                        onChangeTatami(tab, tabIdx);
                                      }}
                                      className={clsx(
                                        classes.flex,
                                        classes.wrap,
                                        classes.column,
                                        classes.droppableBrick,
                                        classes.tab,

                                        tabIdx === activeTatamiIdx &&
                                          classes.seletedTab
                                      )}
                                      style={getTatamiTabStyle(
                                        snapshot.isDraggingOver,
                                        findThemeColor
                                      )}>
                                      {tab.tatami_name}
                                      <sub
                                        className={clsx(
                                          classes.flex,
                                          classes.wrap
                                        )}>
                                        {iconsTatami(
                                          tab,
                                          'filteredCategories'
                                        ).map(({ icon, count }, idx) => (
                                          <Fragment key={`${icon + idx}`}>
                                            <SubItem
                                              className={
                                                classes.tatamiSubWrapper
                                              }
                                              {...{ icon }}
                                              {...{ count }}
                                            />
                                          </Fragment>
                                        ))}
                                      </sub>
                                    </div>
                                  )}
                                </Droppable>
                              );
                            }
                          )}
                      </span>
                    )}
                  </>
                );
              })}
          </span>
        </div>
      )}
    </div>
  );
};
export default DroppableContent;
