import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';
import { ReactComponent as Exit } from 'assets/img/close_white_bg&grey_border.svg';
import { ReactComponent as NoPassed } from 'assets/img/no_passed.svg';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ClickAwayListener,
  Popper
} from '@material-ui/core';

import { selectedValue } from '../../helpers/selectors';
import { IMPORTS_BY_TOURNAMENT } from '../../helpers/constants';

import Dropzone from '../CertificateBuilder/Dropzone/Dropzone';
import Button from '../Buttons/ActionButtons';
import Filter from '../Filter/Filter';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import FilterCheckboxes from '../FilterOptions/FilterCheckboxes';

import { useStyles } from './Style';

const DropFile = ({ onDropExcel, specificDetails }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dropzone
      onDrop={onDropExcel}
      specificDetails={{
        accept:
          'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ...specificDetails,
        childrenElem: (
          <span
            className={clsx(specificDetails?.className?.childrenElemWrapper)}>
            <Button
              label={
                <span
                  className={clsx(
                    classes.uploadBtnLabel,
                    specificDetails?.className?.btnLabel
                  )}>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.uploadBtnPlus,
                      specificDetails?.className?.plusBtn
                    )}>
                    <span style={{ fontSize: '2rem' }}>+</span>
                  </span>
                  {t('uploadDoc')}
                </span>
              }
              isSaveBtn={true}
            />
            {specificDetails?.infoTxt && (
              <span style={{ marginTop: '1rem', fontSize: '0.8rem' }}>
                {specificDetails?.infoTxt}
              </span>
            )}
          </span>
        )
      }}
    />
  );
};

const borderColor = '1px solid #A2ABBA';

const TableComponent = (props) => {
  const {
    onDropExcel,
    onResizeColumn,
    excelData,
    onToggleOptionalFields,
    onTogglePassedStatus,
    onToggleCategoryTypes,
    onDragEnd,
    onSwitchRepresentative,
    onImportExcel,
    showModal,
    isFilterOpen,
    onToggleFilter,
    cancelFilter,
    selectedCheckboxes,
    onSelectCheckbox,
    role
  } = props;

  const {
    currentFields,
    optionalFields,
    data,
    filteredData,
    selectedRepresentative,
    teamRepresentatives
  } = excelData;
  const { fields: selectedFields, category_types: selectedCategoryTypes } =
    currentFields;
  const {
    fields: notRequiredFields,
    category_types: notRequiredCategoryTypes
  } = optionalFields;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isFailedImports, setIsFailedImports] = useState(false);

  const { t } = useTranslation();
  const classes = useStyles(props);
  const navigate = useNavigate();

  const onOpenPopper = (evt) => {
    setAnchorEl(anchorEl ? null : evt.currentTarget);
  };

  const onClosePopper = () => {
    setAnchorEl(null);
  };
  const id = Boolean(anchorEl) ? 'POPPER' : undefined;

  return (
    <>
      <Popper
        style={{ zIndex: 10 }}
        {...{ id }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClosePopper}
        disablePortal={true}>
        <ClickAwayListener onClickAway={onClosePopper}>
          <div className={classes.popperContentWrapper}>
            <span
              style={{
                background: '#E9ECF6',
                padding: '1rem',
                display: 'flex',
                alignItems: 'center'
              }}>
              {t('addRecord', { name: '' })}

              <Exit
                style={{ marginLeft: 'auto' }}
                className={clsx(classes.onHover)}
                onClick={onClosePopper}
              />
            </span>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                height: '18em',
                overflow: 'auto'
              }}>
              {notRequiredFields?.length > 0 ||
              notRequiredCategoryTypes?.length > 0 ? (
                <>
                  {notRequiredFields?.map((it, itIdx) => (
                    <span key={itIdx} style={{ padding: '0 1rem' }}>
                      <CheckboxBtn
                        checked={selectedFields.some(
                          (field) => it.name === field.name
                        )}
                        onChange={() => {
                          onToggleOptionalFields(it, {
                            param: 'fields',
                            arg: 'name'
                          });
                        }}
                        label={it.label}
                      />
                    </span>
                  ))}
                  {notRequiredCategoryTypes && (
                    <span
                      style={{
                        background: '#f4f4f4',
                        gridColumn: 'span 2',
                        padding: '5px',
                        justifyContent: 'center',
                        display: 'flex',
                        margin: '1rem 0 0.5rem'
                      }}>
                      {t('categoryTypes')}
                    </span>
                  )}
                  {notRequiredCategoryTypes?.map((it, itIdx) => (
                    <span key={itIdx} style={{ padding: '0 1rem' }}>
                      <CheckboxBtn
                        checked={selectedCategoryTypes.some(
                          (field) => it.id === field.id
                        )}
                        onChange={() => {
                          onToggleOptionalFields(it, {
                            param: 'category_types',
                            arg: 'id'
                          });
                        }}
                        label={it.name}
                      />
                    </span>
                  ))}
                </>
              ) : (
                <span style={{ padding: '1rem' }}>{t('noRecords')}</span>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </Popper>

      <h3 className={classes.header}>{t('importExcelHeader')}</h3>

      {/* Toolbar table */}
      <div className={classes.toolbar}>
        <Filter
          value={
            selectedValue(
              teamRepresentatives,
              'id',
              selectedRepresentative,
              true
            ) || ''
          }
          className={clsx(classes.filterOutlined)}
          options={teamRepresentatives}
          onChange={onSwitchRepresentative}
          label={t('teamRepresentative')}
          item={{ lastName: 'c_last_name', firstName: 'c_first_name' }}
          optional={'email'}
          variant="outlined"
          placeholder={[
            t('firstname'),
            t('lastname').toLowerCase(),
            t('email').toLowerCase()
          ].join(', ')}
          error={!!excelData?.selectedRepresentativeHelperTxtError}
        />
        <DropFile
          {...{ onDropExcel }}
          specificDetails={{
            className: {
              wrapper: classes.dropFileInsideToolbar,
              plusBtn: classes.uploadBtnPlusToolbar,
              childrenElemWrapper: [classes.flex, classes.fullWidth]
            }
          }}
        />
        <Button
          onClick={() => showModal({ targetID: IMPORTS_BY_TOURNAMENT })}
          label={t('importsByTourn')}
          isSaveBtn={true}
        />
        <div className={classes.btnForMobileView}>
          <Button
            className={`${isFilterOpen && classes.selected}`}
            onClick={onToggleFilter}
            label={
              <span className={clsx(classes.flex, classes.flexGrow1)}>
                <span className={classes.flexGrow1}>{t('filter')}</span>
                <ArrowDropDownIcon
                  className={isFilterOpen && classes.arrowDropUp}
                />
              </span>
            }
            isSaveBtn={!isFilterOpen}
            disabled={!data || data?.length === 0}
          />
          <Button
            onClick={onImportExcel}
            label={t('save')}
            isSaveBtn={true}
            disabled={!data || data?.length === 0}
          />
        </div>
      </div>

      <FilterCheckboxes
        {...{
          isFilterOpen,
          selectedCheckboxes,
          onSelectCheckbox,
          cancelFilter
        }}
        categoryTypes={notRequiredCategoryTypes || []}
        filterDetails={{
          ownerCategories: false,
          noUnisex: false,
          reds: true
        }}
      />

      <PerfectScrollbar className={clsx(classes.paper)}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={onOpenPopper}
                style={{
                  textAlign: 'center',
                  borderBottom: borderColor,
                  fontWeight: 500,
                  ...(data?.length > 0
                    ? {
                        paddingTop: '0.3rem',
                        paddingBottom: '0.3rem',
                        width: '2em'
                      }
                    : {
                        padding: 0,
                        width: '7.15em',
                        minWidth: '5em',
                        position: 'relative'
                      })
                }}>
                {data?.length > 0 ? (
                  '#'
                ) : (
                  <Button
                    className={classes.plusBtn}
                    label={
                      <span
                        className={clsx(
                          classes.flex,
                          classes.tableOptionalPlusIc
                        )}>
                        +
                      </span>
                    }
                  />
                )}
              </TableCell>

              {data?.length > 0 && (
                <TableCell
                  style={{
                    textAlign: 'center',
                    fontWeight: 500,
                    paddingTop: '0.3rem',
                    paddingBottom: '0.3rem',
                    borderBottom: borderColor,
                    borderLeft: borderColor
                  }}>
                  {t('approbation')}
                </TableCell>
              )}
              <DragDropContext
                onDragEnd={(result) => onDragEnd(result, 'fields')}>
                {selectedFields?.map((column, index) => (
                  <Droppable
                    key={index}
                    droppableId={`droppable_${column.name}`}>
                    {(provided) => (
                      <TableCell
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          borderBottom: borderColor,
                          borderLeft: borderColor,
                          textAlign: 'center',
                          fontWeight: 500,
                          paddingTop: '0.3rem',
                          paddingBottom: '0.3rem',
                          position: 'relative'
                        }}>
                        <div
                          style={{
                            minWidth: 'max-content',
                            width: column.width,
                            textAlign: 'center'
                          }}>
                          <Draggable
                            id={column.name}
                            key={column.name}
                            draggableId={column.name}
                            index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                style={{
                                  ...provided.draggableProps.style
                                }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                {column.label}
                              </div>
                            )}
                          </Draggable>
                        </div>
                        {provided.placeholder}
                        {!column.isRequired && (
                          <Clear
                            className={clsx(
                              classes.onHover,
                              classes.clearOptinalFromTable
                            )}
                            onClick={() =>
                              onToggleOptionalFields(column, {
                                param: 'fields',
                                arg: 'name'
                              })
                            }
                          />
                        )}
                        <div
                          className={classes.divResize}
                          style={{ cursor: 'ew-resize' }}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            const initialWidth =
                              e.target.parentNode.offsetWidth;
                            const startX = e.pageX;
                            const onMouseMove = (event) => {
                              const newWidth =
                                initialWidth + (event.pageX - startX);
                              onResizeColumn(newWidth, index);
                            };
                            document.addEventListener('mousemove', onMouseMove);
                            document.addEventListener('mouseup', () => {
                              document.removeEventListener(
                                'mousemove',
                                onMouseMove
                              );
                            });
                          }}
                        />
                      </TableCell>
                    )}
                  </Droppable>
                ))}
              </DragDropContext>

              <TableCell
                style={{
                  fontWeight: 500,
                  borderBottom: borderColor,
                  borderLeft: borderColor,
                  padding: 0
                }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={selectedCategoryTypes?.length ?? 0}
                        style={{
                          border: 'none',
                          ...(selectedCategoryTypes?.length > 0
                            ? { borderBottom: borderColor }
                            : {}),
                          fontWeight: 500,
                          textAlign: 'center'
                        }}>
                        {t('typeOfCategories')}
                      </TableCell>
                    </TableRow>
                    <DragDropContext
                      onDragEnd={(result) =>
                        onDragEnd(result, 'category_types')
                      }>
                      {selectedCategoryTypes?.length > 0 && (
                        <TableRow>
                          {selectedCategoryTypes.map((cat, catIdx) => (
                            <Droppable
                              key={catIdx}
                              droppableId={`droppable_${cat.id}`}>
                              {(provided) => (
                                <TableCell
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  key={cat.id}
                                  style={{
                                    fontWeight: 500,
                                    border: 'none',
                                    ...(catIdx !== 0
                                      ? { borderLeft: borderColor }
                                      : {}),

                                    writingMode: 'vertical-rl',
                                    textOrientation: 'mixed',
                                    whiteSpace: 'nowrap',
                                    transform: 'rotate(180deg)'
                                  }}>
                                  <div
                                    style={{
                                      minWidth: 'max-content',
                                      width: cat.width,
                                      textAlign: 'center'
                                    }}>
                                    <Draggable
                                      id={cat.id}
                                      key={cat.id}
                                      draggableId={`${cat.id}`}
                                      index={catIdx}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          style={{
                                            ...provided.draggableProps.style
                                          }}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          {cat.name}
                                        </div>
                                      )}
                                    </Draggable>
                                  </div>
                                  {provided.placeholder}
                                  <Clear
                                    style={{
                                      bottom: '0.1rem',
                                      left: '0.1rem',
                                      position: 'absolute'
                                    }}
                                    className={clsx(classes.onHover)}
                                    onClick={() =>
                                      onToggleOptionalFields(cat, {
                                        param: 'category_types',
                                        arg: 'id'
                                      })
                                    }
                                  />
                                </TableCell>
                              )}
                            </Droppable>
                          ))}
                        </TableRow>
                      )}
                    </DragDropContext>
                  </TableHead>
                </Table>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredData?.length > 0 &&
              filteredData.map((row, rowIdx) => (
                <TableRow key={rowIdx}>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      ...(rowIdx !== data?.length - 1
                        ? { borderBottom: borderColor }
                        : {})
                    }}>
                    {rowIdx + 1}
                  </TableCell>
                  {data?.length > 0 && (
                    <TableCell
                      style={{
                        textAlign: 'center',
                        ...(rowIdx !== data?.length - 1
                          ? { borderBottom: borderColor }
                          : {}),
                        borderLeft: borderColor
                      }}>
                      {row.passed ? (
                        <CheckboxBtn
                          classControlLabel={classes.margin0}
                          checked={row.isChecked}
                          onChange={(evt) => {
                            onTogglePassedStatus(evt, {
                              idx: rowIdx
                            });
                          }}
                        />
                      ) : (
                        <NoPassed />
                      )}
                    </TableCell>
                  )}
                  {selectedFields?.map((column) => (
                    <TableCell
                      key={column.name}
                      style={{
                        textAlign: 'center',
                        border: borderColor,
                        borderBottom: 'none'
                      }}>
                      {row[column.name] ? String(row[column.name]) : 'N/A'}
                    </TableCell>
                  ))}
                  {data?.length > 0 && (
                    <TableCell
                      style={{
                        padding: 0,
                        textAlign: 'center',
                        ...(rowIdx !== data?.length - 1
                          ? { borderBottom: borderColor }
                          : {}),
                        height: '100px'
                      }}>
                      <Table style={{ height: '100%' }}>
                        <TableBody>
                          <TableRow>
                            {selectedCategoryTypes?.map((cat, catIdx) => (
                              <TableCell
                                key={cat.id}
                                style={{
                                  textAlign: 'center',
                                  border: 'none',
                                  ...(catIdx !== 0
                                    ? { borderLeft: borderColor }
                                    : {}),
                                  height: '100%'
                                }}>
                                <CheckboxBtn
                                  classControlLabel={classes.margin0}
                                  className={classes.padding0}
                                  checked={row.categoryTypes?.some(
                                    (it) => +it === +cat.id
                                  )}
                                  disabled={!row?.passed}
                                  onChange={() => {
                                    onToggleCategoryTypes({
                                      idx: rowIdx,
                                      type: cat.id
                                    });
                                  }}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </>
  );
};

export default TableComponent;
