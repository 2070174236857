import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    width: '54px',
    height: '54px',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.6
    }
  },

  marginTop: {
    marginTop: '20px'
  },

  gridFilters: {
    display: 'grid',
    gap: '1rem'
  },

  gridFilterType: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: '1fr 54px'
  },

  placeholder: {
    '& .MuiInputBase-input': {
      '&::placeholder': {
        color: 'red',
        opacity: 1
      }
    }
  }
}));
