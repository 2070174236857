import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as KumiteIcon } from 'assets/img/grid_skirmish.svg';
import { ReactComponent as KataIcon } from 'assets/img/kata.svg';
import { ReactComponent as TeamKataIcon } from 'assets/img/teamkata.svg';
import { ReactComponent as TameshiwariIcon } from 'assets/img/grid_skirmish.svg';
import { ReactComponent as ArrowDropdown } from 'assets/img/dropdown_arrow_set_white.svg';
import { ReactComponent as Edit } from 'assets/img/edit_white_ic.svg';

import { Collapse } from '@material-ui/core';

import { MODAL_EDIT_IC, SELECTION_OPTIONS } from 'helpers/constants';

import KumiteGrid from './Grids/Kumite/Kumite';
import KatasGrid from './Grids/Katas/Katas';
import TameshiwariGrid from './Grids/Tameshiwari/Tameshiwari';

import classes from './stylesIndividualGrid.module.css';

const Grid = ({
  grid,
  data,
  onSwapParticipants,
  tournamentData,
  itemToggle,
  collapsed,
  showModal,
  gridCheckboxes,
  viewportWidth,
  categoryTypes,
  specificDetails
}) => {
  const { t } = useTranslation();

  const category = data.categories.find((it) => +it.id === +grid.id);
  const findCategoryType = categoryTypes?.find(
    (it) => +it.id === +category?.typeId
  );
  const findCategoryTatami = data?.tatamis?.find(
    (it) => it.id === grid.tatamiId
  );

  const typeIds = {
    2: { icon: KumiteIcon, bg: '#6955d3', draw: KumiteGrid },
    1: { icon: KataIcon, bg: '#34D2E8', draw: KatasGrid },
    3: { icon: TeamKataIcon, bg: '#00409F', draw: KatasGrid },
    4: { icon: TameshiwariIcon, bg: '#1566DB', draw: TameshiwariGrid }
  };

  const defaultCateory = typeIds?.[2]; // use Kumite styling if there are no any set for this category
  const Icon = typeIds?.[category.typeId]?.icon || defaultCateory?.icon;
  const DrawType = typeIds?.[category.typeId]?.draw || defaultCateory?.draw;

  // Sort each array inside gridCheckboxes
  const sortedGridCheckboxes = Object.keys(gridCheckboxes).reduce(
    (acc, gridId) => {
      const sortedArray = gridCheckboxes[gridId].sort((a, b) => {
        const indexA = SELECTION_OPTIONS(t).findIndex(
          (option) => option.id === a
        );
        const indexB = SELECTION_OPTIONS(t).findIndex(
          (option) => option.id === b
        );
        return indexA - indexB;
      });

      acc[gridId] = sortedArray;
      return acc;
    },
    {}
  );
  const renderDrawType = (
    <DrawType
      {...{
        data,
        grid,
        onSwapParticipants,
        tournamentData,
        viewportWidth
      }}
      category={{
        ...(category ?? {}),
        ruleType: findCategoryType?.rule,
        teamType: findCategoryType?.team
      }}
      gridCheckboxes={sortedGridCheckboxes}
    />
  );

  return (
    <>
      <div
        className={clsx(classes.categoryName, classes.onHover)}
        style={{
          background: typeIds?.[category.typeId]?.bg || defaultCateory?.bg
        }}
        onClick={() => itemToggle(category.id)}>
        <Icon className={classes.categoryNameIc} />
        {category?.name}
        {!!+tournamentData?.use_alt_category_name && category.altName && (
          <span
            style={{
              paddingLeft: '0.2rem',
              ...(category.name !== category.altName
                ? { color: '#005FEE' }
                : {})
            }}>{` (${category.altName})`}</span>
        )}
        <div className={classes.categoryNameIcons}>
          {t('block', { count: findCategoryTatami?.block })}. {t('tatami')}{' '}
          {findCategoryTatami?.name}
          <Edit
            className={classes.onHover}
            onClick={(evt) => {
              evt.stopPropagation();

              showModal(
                evt,
                {
                  ...category,
                  tatami_name: findCategoryTatami.name,
                  block: findCategoryTatami.block,
                  block_name: findCategoryTatami.blockName
                },
                MODAL_EDIT_IC
              );
            }}
          />
          {!specificDetails?.bracketOnly && (
            <ArrowDropdown
              className={clsx(
                classes.onHover,
                collapsed[category.id] && classes.rotateDropdownArrow
              )}
            />
          )}
        </div>
      </div>
      {specificDetails?.bracketOnly ? (
        renderDrawType
      ) : (
        <Collapse in={collapsed[category.id]} timeout="auto" unmountOnExit>
          {renderDrawType}
        </Collapse>
      )}
    </>
  );
};

export default Grid;
