import { Fragment, Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { DragDropContext } from 'react-beautiful-dnd';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import LoadingState from '../../components/LoadingState/LoadingState';
import DroppableContent from '../../components/DroppableContent/DroppableContent';
import SideModal from '../../components/Snackbar/SideModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import Button from '../../components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';
import SearchInput from 'components/SearchInput/SearchInput';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';

import {
  fetchTatmisCategories,
  reassignCategories,
  changeModal,
  generateCategoriesOnTatami,
  getTatamisTheme,
  downloadFile,
  exportTournamentTatamiReport
} from '../../helpers/util';
import {
  categoryTypesPresentOnTournament,
  convertMs
} from '../../helpers/selectors';

import { styles } from './StylesDragDrop';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
//moves an item from one list to another list
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const STATISTICS_DETAILS = (t, statistics) => ({
  title: t('statistics'),
  state: 'statisticsReassignCategories',
  info: [
    {
      label: t('estimatedEventTime'),
      name: statistics.eventTimeFinal
    },
    {
      label: t('totalBlocks'),
      name: statistics.totalBlocks
    },
    {
      label: t('totalTatami'),
      name: statistics.totalTatamis
    },
    {
      label: t('totalCategories'),
      name: statistics.totalCategories
    }
  ]
});

class DragDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tournamentData: props.tournamentData || {},
      categoryTypes: categoryTypesPresentOnTournament(
        props?.categoryTypes ?? [],
        props?.tournamentData?.category_types ?? []
      ),
      loading: true,
      isFilterOpen: false,
      showModal: false,
      selectedCheckboxes: [],
      blocks: [],
      tatamisTheme: [],
      statistics: {},
      searchBar: '',
      activeBlockIdx: 0,
      activeTatamiIdx: 0,
      openModal: false,
      openModalId: ''
    };

    this.fetchTatmisCategories = fetchTatmisCategories.bind(this);
    this.reassignCategories = reassignCategories.bind(this);
    this.changeModal = changeModal.bind(this);
    this.generateCategoriesOnTatami = generateCategoriesOnTatami.bind(this);
    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.exportTournamentTatamiReport = exportTournamentTatamiReport.bind(this);
    this.downloadFile = downloadFile.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryTypes, tournamentData } = this.props;

    if (prevProps?.categoryTypes !== categoryTypes) {
      const tournamentCat = categoryTypesPresentOnTournament(
        categoryTypes,
        tournamentData?.category_types
      );
      this.setState({ categoryTypes: tournamentCat });
    }
  }

  componentDidMount() {
    const { tournamentData } = this.state;
    const { id } = tournamentData;

    this.fetchTatmisCategories(id, (categories) => {
      this.getTatamisTheme();
      this.fetchData(categories);
    });
  }

  fetchData = (reassignedCategories, cb) => {
    const { t, onGetPageHeaderStatistics } = this.props;
    let blocks = reassignedCategories.reduce((acc, item) => {
      const { block } = item;
      if (!acc[block]) {
        acc[block] = {
          tatamis: [],
          block_id: item.block,
          block_name: item.block_name
        };
      }

      acc[block].tatamis.push({
        ...item,
        filteredCategories: item.categories
      });

      return acc;
    }, {});

    blocks = Object.values(blocks);

    let statistics = {
      eventTimeFinal: 0,
      totalBlocks: blocks.length,
      totalTatamis: 0,
      tatamis: [],
      totalCategories: 0
    };
    let tatamiGrouped = {};

    blocks.map((it) => {
      it.tatamis.map((tatami) => {
        tatamiGrouped[tatami.tatami_base_name] =
          (tatamiGrouped[tatami.tatami_base_name] || 0) + tatami.total_time;

        statistics.totalCategories += tatami.categories?.length || 0;
        statistics.tatamis.push(tatami.tatami_base_name);
      });
    });

    statistics.totalTatamis = [...new Set(statistics.tatamis)]?.length;

    const maxTime = Math.max(...Object.values(tatamiGrouped), 0);
    const convertedTime = convertMs(maxTime);

    statistics.eventTimeFinal = [
      convertedTime.hours,
      convertedTime?.minutes
    ].join(':');

    onGetPageHeaderStatistics(STATISTICS_DETAILS(t, statistics));

    this.setState({ blocks }, () => cb && cb());
  };

  resetCategories = () => {
    const { tournamentData } = this.state;
    this.fetchTatmisCategories(tournamentData.id, (categories) => {
      this.setState({ selectedCheckboxes: [] });
      this.fetchData(categories);
    });
    this.handleCloseModal();
  };

  generateCategoriesAndCloseModal = (evt, id) => {
    this.generateCategoriesOnTatami(evt, id);
    this.handleCloseModal();
  };

  onChangeTatami = (el, elIdx) => {
    this.setState({ activeTatamiIdx: elIdx });
  };

  onChangeBlock = (elIdx) => {
    this.setState({
      activeBlockIdx: elIdx,
      activeTatamiIdx: 0
    });
  };

  onDragEnd = (result) => {
    const { tournamentData, blocks, activeTatamiIdx, activeBlockIdx } =
      this.state;
    const { source, destination } = result;
    const destinationSplit = destination.droppableId.split('_');
    const destinationBlockIdx = destinationSplit[2];
    const destinationTatamiIdx = destinationSplit[4];

    let cpyBlocks = [...blocks];
    const tatamiDroppableId = `droppable_block_${activeBlockIdx}_tatami_${activeTatamiIdx}`;
    const blockDroppableId = `droppable_blocks_${activeBlockIdx}_tatami_${activeTatamiIdx}`;
    const isDestinationColumn =
      destination.droppableId.includes('droppable_active');
    const isColumn1 = source.droppableId.includes('droppable_active_1');
    const filteredList =
      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories;
    const filteredHalf = Math.ceil(filteredList?.length / 2);
    const filteredColumn1 = filteredList.slice(0, filteredHalf);
    const filteredColumn2 = filteredList.slice(filteredHalf);
    const filteredColumn = isColumn1 ? filteredColumn1 : filteredColumn2;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      // Retrieve the movable filteredCategories using the source and destination index

      const sourceCategory = filteredColumn[source.index];
      const destinationCategory = filteredColumn[destination.index];

      const reorderedListFiltered = reorder(
        filteredColumn,
        source.index,
        destination.index
      );

      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories = [
        ...(isColumn1 ? reorderedListFiltered : filteredColumn1),
        ...(!isColumn1 ? reorderedListFiltered : filteredColumn2)
      ];

      // Locate the index of the source category in the unfiltered list, remove the item, and then assign it to the destination index
      const findIdxSourceCategory = cpyBlocks[activeBlockIdx].tatamis[
        activeTatamiIdx
      ].categories.findIndex(
        (it) => +it.category_id === +sourceCategory?.category_id
      );

      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
        findIdxSourceCategory,
        1
      );

      const findIdxDestinationCategory = cpyBlocks[activeBlockIdx].tatamis[
        activeTatamiIdx
      ].categories.findIndex(
        (it) => +it.category_id === +destinationCategory?.category_id
      );

      cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
        source.index < destination.index
          ? findIdxDestinationCategory + 1
          : findIdxDestinationCategory,
        0,
        sourceCategory
      );

      this.reassignCategories({
        tournament_id: tournamentData.id,
        tatami_id:
          cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]?.tatami_id,
        categories:
          cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]?.categories
      });
    } else {
      //dragging a category to the tab that is active - dropableTabActive
      if (
        destination.droppableId === tatamiDroppableId ||
        destination.droppableId === blockDroppableId
      )
        return;
      else {
        const sourceCategory = filteredColumn[source.index];
        const findIdx = cpyBlocks[activeBlockIdx].tatamis[
          activeTatamiIdx
        ].categories.findIndex(
          (it) => +it.category_id === +sourceCategory.category_id
        );
        const filteredDestinationColumn = !isColumn1
          ? filteredColumn1
          : filteredColumn2;

        if (isDestinationColumn) {
          const result = move(
            filteredColumn,
            filteredDestinationColumn,
            source,
            destination
          );

          // remove from droppables moved categories based on sourceFrom/ destinationTo
          cpyBlocks[activeBlockIdx].tatamis[
            activeTatamiIdx
          ].filteredCategories = [
            ...result.droppable_active_1,
            ...result.droppable_active_2
          ];

          cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
            findIdx,
            1
          );

          const destinationCategory =
            filteredDestinationColumn[destination.index];
          const findIdxDestinationCategory = cpyBlocks[activeBlockIdx].tatamis[
            activeTatamiIdx
          ].categories.findIndex(
            (it) => +it.category_id === +destinationCategory?.category_id
          );

          cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
            findIdxDestinationCategory !== -1
              ? findIdxDestinationCategory
              : cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories
                  .length,
            0,
            sourceCategory
          );

          this.reassignCategories({
            tournament_id: tournamentData.id,
            tatami_id:
              cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]
                ?.tatami_id,
            categories:
              cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]
                ?.categories
          });
        } else {
          const result = move(
            filteredColumn,
            cpyBlocks[destinationBlockIdx].tatamis[destinationTatamiIdx]
              .filteredCategories,
            source,
            destination
          );

          // remove from droppables moved categories based on sourceFrom/ destinationTo
          cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories.splice(
            findIdx,
            1
          );
          cpyBlocks[destinationBlockIdx].tatamis[
            destinationTatamiIdx
          ].categories.unshift(sourceCategory);

          cpyBlocks[activeBlockIdx].tatamis[
            activeTatamiIdx
          ].filteredCategories = [
            ...(isColumn1 ? result[source.droppableId] : filteredColumn1),
            ...(!isColumn1 ? result[source.droppableId] : filteredColumn2)
          ];
          cpyBlocks[destinationBlockIdx].tatamis[
            destinationTatamiIdx
          ].filteredCategories = result[destination.droppableId];

          this.reassignCategories({
            tournament_id: tournamentData.id,
            tatami_id:
              cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
                ?.tatami_id,
            categories:
              cpyBlocks?.[destinationBlockIdx]?.tatamis?.[destinationTatamiIdx]
                ?.categories
          });
        }
      }
    }

    this.setState({
      blocks: cpyBlocks
    });
  };

  hideSnackBar = () => this.setState({ showModal: false });

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes, blocks } = this.state;
    const checkedValues = key?.id
      ? selectedCheckboxes.some((item) => item.id === key.id)
        ? selectedCheckboxes.filter((it) => it.id !== key.id)
        : [...selectedCheckboxes, key]
      : [];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      const blockLen = blocks.length;
      let allBlocks = [];

      let genderArray = [];
      let ageArray = [];
      let typeArray = [];

      checkedValues.map((it) => {
        if (it.gender) genderArray = [...genderArray, it.gender];
        if (it.age) ageArray = [...ageArray, it.age];
        if (it.type) typeArray = [...typeArray, it.id];
        return true;
      });

      for (let y = 0; y < blockLen; y++) {
        const len = blocks[y].tatamis.length;
        let filteredCategoriesPerTatami = [];

        for (let i = 0; i < len; i++) {
          let categories = [];
          const categoriesPerTatamiLen = blocks[y].tatamis[i].categories.length;

          for (let j = 0; j < categoriesPerTatamiLen; j++) {
            const category = blocks[y].tatamis[i].categories[j];

            const filterByGenderList =
              genderArray.length > 0
                ? genderArray.some((el) => category.category_gender === el)
                : category;

            const filterByAgeList =
              ageArray.length > 0
                ? ageArray.some((el) =>
                    category.category_age_to
                      ? +category.category_age_from >= el[0] &&
                        +category.category_age_to <= el[1]
                      : +category.category_age_from >= el[0] &&
                        +category.category_age_from <= el[1]
                  )
                : category;

            const filterByTypeList =
              typeArray.length > 0
                ? typeArray.some((el) => +category.category_type === +el)
                : category;

            if (filterByGenderList && filterByAgeList && filterByTypeList) {
              categories = [...categories, category];
            }
          }

          filteredCategoriesPerTatami = [
            ...filteredCategoriesPerTatami,
            { ...blocks[y].tatamis[i], filteredCategories: [...categories] }
          ];
        }

        allBlocks = [
          ...allBlocks,
          { ...blocks[y], tatamis: filteredCategoriesPerTatami }
        ];
      }

      allBlocks = this.onTxtSearch(this.state.searchBar, allBlocks);

      this.setState({ blocks: allBlocks });
    });
  };

  onCategoryTatamiSort = (selCheckbox) => {
    const { blocks, activeBlockIdx, activeTatamiIdx } = this.state;
    let cpyBlocks = [...blocks];
    const genderOrder = { M: 0, F: 1 };

    const sortCategories_FMFM = (a, b, genderOrder) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_gender !== b.category_gender) {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      } else if (a.origin_category_order !== b.origin_category_order) {
        return sortByOriginCategoryOrder(a, b);
      }
    };

    const sortByOriginCategoryOrder = (a, b) => {
      if (a.origin_category_order > b.origin_category_order) {
        return 1;
      } else if (a.origin_category_order < b.origin_category_order) {
        return -1;
      }
    };

    const sortCategories_MFMF_FMFM = (a, b, genderOrder, type) => {
      if (b.category_type !== a.category_type) {
        if (+a?.category_type === 2) {
          return -1;
        } else if (+b?.category_type === 2) {
          return 1;
        } else {
          return a.category_type - b.category_type;
        }
      } else if (a.category_age_from !== b.category_age_from) {
        return a.category_age_from - b.category_age_from;
      } else if (a.category_weight > b.category_weight) {
        return 1;
      } else if (a.category_weight < b.category_weight) {
        return -1;
      } else if (a.category_gender !== b.category_gender && type === 'MFMF') {
        return genderOrder[a.category_gender] - genderOrder[b.category_gender];
      } else if (a.category_gender !== b.category_gender && type === 'FMFM') {
        return genderOrder[b.category_gender] - genderOrder[a.category_gender];
      }
    };

    const customSort = (a, b, selCheckbox, genderOrder) => {
      switch (selCheckbox) {
        case 'MFMF':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'MFMF');
        case 'MMFF':
          return sortByOriginCategoryOrder(a, b);
        case 'FFMM':
          return sortCategories_FMFM(a, b, genderOrder);
        case 'FMFM':
          return sortCategories_MFMF_FMFM(a, b, genderOrder, 'FMFM');
        default:
          return 0; // Default value if selCheckbox doesn't match any case
      }
    };

    const newOrder1 = cpyBlocks[activeBlockIdx].tatamis[
      activeTatamiIdx
    ].categories.sort((a, b) => customSort(a, b, selCheckbox, genderOrder));
    const newOrder2 = cpyBlocks[activeBlockIdx].tatamis[
      activeTatamiIdx
    ].filteredCategories.sort((a, b) =>
      customSort(a, b, selCheckbox, genderOrder)
    );

    cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].categories = newOrder1;
    cpyBlocks[activeBlockIdx].tatamis[activeTatamiIdx].filteredCategories =
      newOrder2;

    this.setState(
      {
        blocks: cpyBlocks
      },
      () => {
        const { tournamentData } = this.state;

        this.reassignCategories({
          tournament_id: tournamentData.id,
          tatami_id:
            cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]?.tatami_id,
          categories:
            cpyBlocks?.[activeBlockIdx]?.tatamis?.[activeTatamiIdx]?.categories
        });
      }
    );
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox());
  };

  onTxtSearch = (value, data) => {
    const blockLen = data.length;
    let allBlocks = [];

    for (let y = 0; y < blockLen; y++) {
      const len = data[y]?.tatamis?.length;
      let filteredCategoriesPerTatami = [];

      for (let i = 0; i < len; i++) {
        let categories = [];

        const categoriesPerTatamiLen =
          data[y].tatamis[i].filteredCategories.length;

        for (let j = 0; j < categoriesPerTatamiLen; j++) {
          const category = data[y].tatamis[i].filteredCategories[j];

          if (
            category.category_name
              .toLowerCase()
              .includes(value.toLowerCase().trim())
          ) {
            categories = [...categories, category];
          }
        }

        filteredCategoriesPerTatami = [
          ...filteredCategoriesPerTatami,
          { ...data[y].tatamis[i], filteredCategories: [...categories] }
        ];
      }

      allBlocks = [
        ...allBlocks,
        { ...data[y], tatamis: filteredCategoriesPerTatami }
      ];
    }

    return allBlocks;
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox());
  };

  onClearFilters = () => {
    this.setState({ selectedCheckboxes: [] }, () => this.onSelectCheckbox());
  };

  handleOpenModal = (id) => {
    this.setState({ openModal: true, openModalId: id });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, openModalId: null });
  };

  modalOnClickToggle = (openModalId, id) => {
    switch (openModalId) {
      case 'resetCategories':
        return this.resetCategories;
      case 'generateCategories':
        return (evt) => this.generateCategoriesAndCloseModal(evt, id);
      default:
        return null;
    }
  };

  onToggleFilter = () => {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  render() {
    const {
      loading,
      success,
      showModal,
      modalInfo,
      selectedCheckboxes,
      tournamentData,
      categoryTypes,
      blocks,
      searchBar,
      activeBlockIdx,
      activeTatamiIdx,
      tatamisTheme,
      openModal,
      openModalId,
      isFilterOpen
    } = this.state;
    const { t, classes, shouldDisableEditing, viewportWidth } = this.props;

    const INTERACTIVE_HEADER = [
      {
        label: (
          <>
            <span style={{ flexGrow: 1 }}>{t('filter')}</span>
            {isFilterOpen ? (
              <ArrowDropUpIcon style={{ color: '#fff' }} />
            ) : (
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            )}
          </>
        ),
        onClick: () => this.onToggleFilter(),
        className: [isFilterOpen && classes.activeState, classes.filter]
      },
      // So far they have asked to remove this button
      // {
      //   label: t('resetAll'),
      //   onClick: () => this.handleOpenModal('resetCategories'),
      //   id: 'resetCategories'
      // },
      {
        label: t('saveList'),
        onClick: () => this.exportTournamentTatamiReport(tournamentData.id),
        className: classes.saveList
      },
      {
        label: t('generate'),
        onClick: () => this.handleOpenModal('generateCategories'),
        id: 'generateCategories',
        className: classes.generate
      },
      { search: true, isInput: true }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : blocks?.length > 0 ? (
          <>
            {!shouldDisableEditing && (
              <div
                className={clsx(
                  classes.interactiveHeaderWrapper,
                  classes.marginTopBottom1
                )}>
                {INTERACTIVE_HEADER.map((el, index) => (
                  <Fragment key={index}>
                    {!el.isInput ? (
                      <Button
                        onClick={el.onClick}
                        label={el.label}
                        isSaveBtn
                        className={el.className}
                      />
                    ) : (
                      <SearchInput
                        className={clsx(classes.margin0, classes.search)}
                        onChange={this.onSearch}
                        clearSearch={this.onClearSearch}
                        value={searchBar}
                        isSearchInactive={!searchBar}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            <FilterCheckboxes
              {...{
                categoryTypes,
                selectedCheckboxes,
                isFilterOpen
              }}
              onSelectCheckbox={this.onSelectCheckbox}
              cancelFilter={this.onClearFilters}
            />
            <Modal
              open={openModal}
              onClick={this.modalOnClickToggle(openModalId, tournamentData.id)}
              close={this.handleCloseModal}
              closeButton={this.handleCloseModal}
              buttonPurpose={t('yes')}
              dialogContent={t('provideThisAction')}
              closeButtonlabel={t('no')}
              dialogTitle={t('generate')}
              classNameBtn={classes.classNameBtn}
            />
            <DragDropContext onDragEnd={this.onDragEnd}>
              <DroppableContent
                {...{ blocks }}
                windowWidth={viewportWidth}
                {...{ categoryTypes }}
                onChangeTatami={this.onChangeTatami}
                onChangeBlock={this.onChangeBlock}
                {...{ activeBlockIdx, activeTatamiIdx }}
                {...{ tatamisTheme, shouldDisableEditing, tournamentData }}
                onCategoryTatamiSort={this.onCategoryTatamiSort}
              />
            </DragDropContext>
          </>
        ) : (
          <EmptyState
            className={classes.emptyState}
            emptyStateText={t('noResults')}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(DragDrop));
