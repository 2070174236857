import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

import { isDesktopView } from '../../../../helpers/selectors';
import { getParticipantDetails } from 'helpers/constants';

import styles from './styles.module.css';

const RowComponent = ({
  data,
  grid,
  category,
  skirmish,
  skirmishIdx,
  gridCheckboxes,
  viewportWidth,
  tournamentData,
  onSwapParticipants
}) => {
  const { t } = useTranslation();
  const { participants } = skirmish;
  const showInDesktopView = isDesktopView(viewportWidth);

  if (!participants[0].id) return <></>;

  return (
    <div className={styles.rowWrapper}>
      {/* Order */}
      <div className={styles.order}>{skirmishIdx + 1}</div>

      {/* Participant */}
      {participants?.map((participant, participantIdx) => {
        const seenIds = new Set(); // To track already encountered IDs

        const findParticipants = participant?.ids?.map((id) => {
          const participantData = data.participants?.find((it) => it.id === id);

          // Check if the participant exists and hasn't been seen already
          if (participantData && !seenIds.has(participantData.id)) {
            seenIds.add(participantData.id);

            return participantData;
          }
        });

        const isSwapActive =
          data.participant2Swap &&
          data.participant2Swap.categoryId === grid.id &&
          data.participant2Swap.block === skirmish.position.block &&
          data.participant2Swap.round === skirmish.position.round &&
          data.participant2Swap.skirmish === skirmish.position.skirmish &&
          data.participant2Swap.position === participantIdx + 1;

        return (
          <Fragment key={participantIdx}>
            <div
              className={`${styles.wrapperParticipantsAbout} ${
                participant.isStartPosition ? styles.active : ''
              } ${isSwapActive ? styles.swap : ''}`}
              style={{
                ...(showInDesktopView &&
                +category?.typeId !== 3 &&
                findParticipants?.[0]?.number
                  ? { gridTemplateColumns: '3em 10px 1fr' }
                  : {})
              }}
              onClick={
                participant.isStartPosition
                  ? () =>
                      onSwapParticipants({
                        block: skirmish.position.block,
                        round: skirmish.position.round,
                        skirmish: skirmish.position.skirmish,
                        position: participantIdx + 1,
                        categoryId: grid.id
                      })
                  : undefined
              }>
              {findParticipants?.map((part, partIdx) => {
                const participantDetails = getParticipantDetails(t, part);

                return (
                  <Fragment key={partIdx}>
                    {+category?.typeId !== 3 &&
                      showInDesktopView &&
                      part.number && (
                        <div className={styles.participantNumber}>
                          {part.number}
                        </div>
                      )}

                    {/* Small gap */}
                    {+category?.typeId !== 3 &&
                      showInDesktopView &&
                      part.number && <div className={styles.gap}></div>}

                    {/* Name and City */}
                    <div
                      className={styles.participantAbout}
                      style={{
                        ...(!showInDesktopView && +category?.typeId === 3
                          ? { flexDirection: 'column' }
                          : {})
                      }}>
                      {+category?.typeId === 3 && partIdx === 0 && (
                        <span className={styles.name}>
                          {`${participant?.teamName ?? t('team')}:`}
                        </span>
                      )}
                      <Tooltip
                        key={partIdx}
                        title={
                          <span style={{ display: 'grid' }}>
                            {participantDetails.map(
                              (partDetail, partDetailsIdx) => (
                                <span key={partDetailsIdx}>
                                  {partDetail.label}: {partDetail.val}
                                </span>
                              )
                            )}
                          </span>
                        }
                        arrow>
                        <div className={styles.name}>
                          {!+tournamentData?.no_country && part.countryISO2 && (
                            <img
                              className={styles.flag}
                              src={`/images/flags/${part.countryISO2?.toLowerCase()}.svg`}
                              alt={`${part.countryISO2} flag`}
                            />
                          )}
                          {(!showInDesktopView || +category?.typeId === 3) &&
                          part.number
                            ? `${part.number} ${part.name}`
                            : part.name}
                        </div>
                      </Tooltip>
                      {partIdx === findParticipants?.length - 1 && ( //Display the additional information only once, at the very end, and not for each participant.
                        <div className={styles.additionalInfo}>
                          {gridCheckboxes[grid.id]
                            ?.map((it) => findParticipants?.[0]?.[it])
                            .filter((partVal) => partVal)
                            .join(', ')}
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })}
            </div>

            <div className={styles.notesAndAdditionalWrapper}>
              {/* Notes */}
              <div className={styles.notesWrapper}>
                {[...Array(5)]?.map((_, noteIdx) => (
                  <div key={noteIdx} className={styles.noteCell}>
                    <div key={noteIdx} className={styles.label}>
                      {[t('judge').toLowerCase(), noteIdx + 1].join(' ')}
                    </div>
                    <div className={styles.value}>
                      {participant?.notes?.[noteIdx] ?? 0}
                    </div>
                  </div>
                ))}
              </div>

              {/* Additional columns */}
              <div className={styles.additionalColumns}>
                {[
                  {
                    label: t('min'),
                    value: Math.min(...participant?.notes, 0)
                  },

                  {
                    label: t('max'),
                    value: Math.max(...participant?.notes, 0)
                  },
                  {
                    label: t('grade').toLowerCase(),
                    value: participant?.notes.reduce(
                      (total, current) => total + current,
                      0
                    )
                  },
                  {
                    label: t('rank').toLowerCase(),
                    value: participant?.place ?? '-'
                  }
                ]?.map((col, colIdx) => (
                  <div key={colIdx} className={styles.additionalCell}>
                    <div className={styles.label}>{col.label}</div>
                    <div className={styles.value}>{col.value}</div>
                  </div>
                ))}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

const Katas = ({
  data,
  grid,
  category,
  gridCheckboxes,
  viewportWidth,
  tournamentData,
  onSwapParticipants
}) => {
  return (
    <div className={styles.wrapper}>
      {grid?.skirmishes?.map((skirmish, skirmishIdx) => (
        <RowComponent
          key={skirmishIdx}
          {...{
            data,
            grid,
            category,
            skirmish,
            skirmishIdx,
            gridCheckboxes,
            viewportWidth,
            tournamentData,
            onSwapParticipants
          }}
        />
      ))}
    </div>
  );
};

export default Katas;
