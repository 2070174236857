import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ExpandMore } from '@material-ui/icons';

import { Collapse } from '@material-ui/core';

import EmptyState from '../../components/EmptyState/EmptyState';

import { useStyles } from './styles';

const Accordion = (props) => {
  const { accordionContent, itemToggle, data, collapsed, tournamentData } =
    props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {data?.length > 0 ? (
        data.map((it, idx) => (
          <Fragment key={idx}>
            <span
              className={clsx(classes.flex, classes.paper)}
              onClick={() => itemToggle(idx)}>
              <span>
                <span className={classes.numberWrapper}>{`${idx + 1}`}</span>
                <span className={classes.textWrapper}>
                  {`${it.category_name}`}
                  {!!+tournamentData?.use_alt_category_name &&
                    it.category_alt_name && (
                      <span
                        style={{
                          ...(it.category_name !== it.category_alt_name
                            ? { color: '#005FEE' }
                            : {})
                        }}>{` (${it.category_alt_name})`}</span>
                    )}
                </span>
              </span>
              <ExpandMore
                className={clsx(
                  classes.left,
                  collapsed[idx] && classes.expandLess
                )}
              />
            </span>
            <Collapse in={collapsed[idx]} timeout="auto" unmountOnExit>
              {accordionContent && accordionContent(it.winners, it)}
            </Collapse>
          </Fragment>
        ))
      ) : (
        <EmptyState
          className={classes.emptyState}
          emptyStateText={t('noResults')}
        />
      )}
    </>
  );
};
export default Accordion;
