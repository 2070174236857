import { ReactComponent as Gold } from 'assets/img/medal_prize_1.svg';
import { ReactComponent as Silver } from 'assets/img/medal_prize_2.svg';
import { ReactComponent as Bronze } from 'assets/img/medal_prize_3.svg';
import { ReactComponent as Copper } from 'assets/img/medal_prize_4.svg';

const WonPlaces = ({ specificDetails }) => {
  // Mapping place to corresponding icon
  const medals = {
    1: <Gold style={{ ...(specificDetails?.style?.icon ?? {}) }} />,
    2: <Silver style={{ ...(specificDetails?.style?.icon ?? {}) }} />,
    3: <Bronze style={{ ...(specificDetails?.style?.icon ?? {}) }} />,
    4: <Copper style={{ ...(specificDetails?.style?.icon ?? {}) }} />
  };

  return medals[specificDetails?.place] || null;
};

export default WonPlaces;
