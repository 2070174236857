const styles = () => ({
  marginLeft0: {
    marginLeft: 0
  },

  marginLeft065: {
    marginLeft: '0.65rem'
  },

  flex: {
    display: 'flex'
  },

  marginTop1: {
    marginTop: '1rem'
  },
  marginTop15: {
    marginTop: '1.5rem'
  },

  margin0: {
    margin: 0
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  icon: {
    width: '2.3rem',
    height: '2.3rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  wrapperCopyTransfer: {
    display: 'grid',
    marginRight: '0.5rem',
    gap: '0.25rem'
  },

  trounamentGrid: {
    marginRight: '0.5rem'
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content'
    }
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginBottom1: {
    marginBottom: '-1rem'
  },

  column: {
    flexDirection: 'column'
  },

  marginRight0: {
    marginRight: 0
  },

  marginLeft0dot5: {
    marginLeft: '0.5rem'
  },

  smallViewportColumn: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },

  checkboxSvg: {
    width: '1em',
    height: '1em'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  gridDropdownList: {
    gridTemplateColumns: '1fr minmax(1em, 3.5em)'
  },

  numOfCategories: {
    color: '#fff',
    backgroundColor: '#005FEE',
    borderRadius: '0.3rem',
    marginBottom: '0.2rem',
    width: '1.5rem',
    height: '1.5rem',
    textAlign: 'center',
    fontWeight: 500
  },

  marginLeft0dot4: {
    marginLeft: '0.4rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  checkedActionIcon: {
    '&::before': {
      display: 'block',
      width: '1em',
      height: '1em'
    }
  },

  labelCheckbox: {
    marginBottom: '.1rem',
    alignItems: 'center'
  },

  fitContent: {
    minWidth: 'fit-content'
  },

  padding0: {
    padding: 0
  },

  expandLabel: {
    '&.MuiTypography-body1': {
      fontSize: '16px',
      fontWeight: 600
    }
  },

  styleFilter: {
    width: '9em',
    marginLeft: '.5rem',
    height: 'calc(100% + 0.4rem)',
    border: 'none',
    '& .MuiFormControl-root, & .MuiInputBase-root': {
      height: '100%'
    }
  },

  mainTableRowColor: {
    backgroundColor: 'rgba(0,29,61,0.2)'
  },

  wrap: {
    flexWrap: 'wrap'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  marginRight0dot5: {
    marginRight: '.5rem'
  },

  bgWhite: { background: '#fff' },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },

  checkboxItem: {
    margin: '0.6rem 0.3rem',
    minWidth: '120px',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginLeft: '10px'
  },

  modalWidthInfo: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  },

  modalWidthAssign: {
    '@media (min-width:768px)': {
      minWidth: '738px'
    }
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  rowOrder: {
    minWidth: '3em',
    borderRight: '1px solid #8996AC'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  cardMainPart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  gridRecordsAttachments: {
    '@media (min-width:1024px)': {
      gridTemplateColumns: '25em 1fr'
    },
    '@media (min-width: 768px) and (max-width: 1023px)': {
      gridTemplateColumns: '15em 1fr'
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  marginLeft05: {
    marginLeft: '0.5rem'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginBottom20: {
    marginBottom: '20px'
  },

  labelView: {
    fontSize: '16px',
    fontWeight: 600
  },

  center: {
    textAlign: 'center'
  },

  issues: {
    color: '#ff0000'
  },

  isSaveBtn: {
    width: '225px',
    height: '50px',
    '@media (max-width:767px)': {
      marginRight: 'auto'
    }
  },

  spinnerIconWrapper: {
    display: 'flex',
    background: 'rgb(233, 236, 246)',
    alignItems: 'center',
    justifyContent: 'center'
  },

  redText: {
    color: 'red'
  },
  emptyState: {
    marginTop: '40px'
  },

  thumbnail: {
    border: '1px solid #8996AC',
    marginTop: '0.5rem',
    width: '54px',
    height: '72px',
    objectFit: 'cover',
    borderRadius: '0.5rem'
  },

  thumbnailModal: {
    border: '1px solid #8996AC',
    width: '48px',
    height: '64px',
    objectFit: 'cover',
    borderRadius: '0.5rem'
  },

  participantDetailsModal: {
    justifyContent: 'space-between'
  },

  closeBtnModalAssign: {
    color: '#fff',
    background: '#8996AC',
    '@media (min-width: 768px)': {
      marginRight: 0
    },
    '&:hover': {
      background: '#AEBBD0',
      opacity: 0.8
    },
    '@media (max-width: 767px)': {
      margin: 0,
      width: '100%'
    }
  },

  closeBtnModalInfo: {
    '@media (max-width:1023px) and (min-width:768px)': {
      marginLeft: '1.5rem'
    },
    '@media (min-width: 768px)': {
      marginRight: 0
    },
    '@media (max-width: 767px)': {
      margin: 0
    }
  },

  saveBtnModalOrder: {
    order: 2
  },

  saveBtnModal: {
    order: 2,
    '@media (min-width: 768px)': {
      marginLeft: '1rem'
    },
    '@media (max-width: 767px)': {
      margin: 0,
      marginTop: '1rem',
      width: '100%'
    }
  },

  saveBtnModalInfo: {
    order: 2,
    '@media (max-width: 767px)': {
      margin: 0
    },
    marginLeft: '1rem !important'
  },

  wrapperFooterModal: {
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '1rem'
    }
  },

  wrapperFooterModalInfo: {
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '1rem'
    }
  }
});

export default styles;
