const styles = () => ({
  center: {
    textAlign: 'center'
  },

  category: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '0.5rem'
  },

  marginRight0dot5: {
    marginRight: '0.5rem'
  },

  subSvg: {
    width: '16px',
    height: '16px',
    marginRight: '0.1rem'
  },

  statisctics: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px'
  }
});

export default styles;
