import React, { Component } from 'react';
import clsx from 'clsx';

import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as ScheduleBlackIcon } from 'assets/img/schedule.svg';
import { ReactComponent as SkirmishesBlackIcon } from 'assets/img/skirmishes_black.svg';
import { ReactComponent as PersonBlackIcon } from 'assets/img/members_num_black.svg';

import GroupIcon from '@material-ui/icons/Group';

import { TextField } from '@material-ui/core';

import {
  getTatamisTheme,
  fetchTatamis,
  textChangeHandler,
  selectOption,
  setTatamiConfig,
  addTatamisToTournament,
  changeModal,
  changeTatamisName,
  fetchTatmisCategories
} from '../../helpers/util';
import { ID, NAME, TEST_ID_INFO_BTN } from '../../helpers/constants';
import { selectedValue, convertMs } from '../../helpers/selectors';

import AddTournTatamis from '../../components/AddTournTatamis/AddTournTatamis';
import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/ModalNewDesign';
import Filter from '../../components/Filter/Filter';

import styles from './Styles';

const STATISTICS_DETAILS = (t, statistics) => ({
  title: t('tatamiStatistics'),
  state: 'statisticsTatami',
  info: [
    {
      label: [t('total'), t('tatamis').toLowerCase()].join(' '),
      name: statistics.tatamisCount
    },
    {
      label: t('blocksCount'),
      name: statistics.blocksCount
    },
    {
      label: t('videoLinks'),
      name: statistics.linksCount
    }
  ]
});

class TournamentAddTatamis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentData: { ...(props?.tournamentData ?? {}) },
      langOnLoad: localStorage.getItem('i18nextLng'), //need this to update the list of colors based on the selected language
      open: false,
      tatamis: [],
      selectedTatamis: [],
      tatamiConfig: {},
      tatamisTheme: [],
      loading: true,
      lastBlockWithSelection: 0,
      nameInTatami: {}
    };

    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.fetchTatamis = fetchTatamis.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.selectOption = selectOption.bind(this);
    this.setTatamiConfig = setTatamiConfig.bind(this);
    this.addTatamisToTournament = addTatamisToTournament.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeTatamisName = changeTatamisName.bind(this);
    this.selectedValue = selectedValue.bind(this);
    this.fetchTatmisCategories = fetchTatmisCategories.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad, statistics } = this.state;
    const { onGetPageHeaderStatistics, t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      this.getTatamisTheme();
      onGetPageHeaderStatistics(STATISTICS_DETAILS(t, statistics));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { tournamentData } = this.state;
    let cpy = { ...tournamentData };

    this.getTatamisTheme();
    this.fetchMainData(tournamentData?.id);

    cpy.tatamiHieroglyphs = tournamentData?.tatami_set;

    this.setState({ tournamentData: cpy });
  }

  fetchMainData(tournamentId) {
    const { t, onGetPageHeaderStatistics } = this.props;

    this.fetchTatamis(tournamentId, () => {
      const { lastBlockWithSelection, tatamis } = this.state;
      const len = tatamis?.length;
      let newCopy = {};
      let allBlockName = {};
      let copyLastBlockWithSelection = lastBlockWithSelection;

      for (let i = 0; i < len; i++) {
        const tatami = tatamis[i];

        newCopy[`block_${tatami.tatami_block}`] = newCopy[
          `block_${tatami.tatami_block}`
        ]
          ? [...newCopy?.[`block_${tatami.tatami_block}`], tatami]
          : [tatami];

        if (!!+tatami.is_picked) {
          copyLastBlockWithSelection = +tatami.tatami_block;
        }
      }

      newCopy = Object.values(newCopy);

      newCopy.map((block, blockIdx) => {
        const findName = block.find(
          (it) => !!+it.is_picked && it.block_name
        )?.block_name;

        allBlockName = {
          ...allBlockName,
          [`block_name_${blockIdx}`]:
            findName || t('block', { count: blockIdx })
        };
      });

      const blocksWithTatamisDetails = [...newCopy]?.splice(
        0,
        copyLastBlockWithSelection + 1
      );
      const flattenedArray = [].concat(...blocksWithTatamisDetails);
      const numOfTranslationLinks = flattenedArray?.filter(
        (it) => it.translation_link_1
      )?.length;
      const pickedTatamis = flattenedArray.reduce((acc, tatami) => {
        if (!!+tatami.is_picked) {
          acc.push(
            !+tatami.tatami_block ? tatami.tatami_id : tatami.tatami_parent_id
          );
        }

        return acc;
      }, []);

      const uniquePickedTatamisCount = [...new Set(pickedTatamis)]?.length;

      const statistics = {
        tatamisCount: uniquePickedTatamisCount,
        blocksCount: copyLastBlockWithSelection + 1,
        linksCount: numOfTranslationLinks ?? 0
      };

      onGetPageHeaderStatistics(STATISTICS_DETAILS(t, statistics));

      this.setState({
        statistics,
        loading: false,
        tatamis: newCopy,
        lastBlockWithSelection: copyLastBlockWithSelection,
        tatamiConfig: {},
        allBlockName
      });
    });
  }

  addTatamis = (evt, item) => {
    evt.persist();

    const { selectedTatamis } = this.state;
    const { tournamentId } = this.props;
    let newArray = [];

    if (selectedTatamis.some((key) => +key.tatami_id === +item?.tatami_id)) {
      newArray = selectedTatamis.filter(
        (key) => +key.tatami_id !== +item?.tatami_id
      );
    } else {
      newArray = [...selectedTatamis, item];
    }

    this.setState({ selectedTatamis: newArray }, () => {
      const selectedTatamisIds = this.state.selectedTatamis.map(
        (key) => key.tatami_id
      );

      this.addTatamisToTournament(evt, tournamentId, selectedTatamisIds, () => {
        this.fetchMainData(tournamentId);
        this.hideModal();
      });
    });
  };

  showModalFrom = (pressedBtn, item, tatamiCounter) => {
    const { tournamentId } = this.props;

    if (pressedBtn === TEST_ID_INFO_BTN) {
      this.fetchTatmisCategories(
        tournamentId,
        (data) => {
          const modalData = data.find(
            (it) => +it?.tatami_id === +item?.tatami_id
          );

          this.setState({
            modalData,
            pressedBtn,
            open: true
          });
        },
        null,
        null,
        true
      );
    } else {
      const tatamiConfigDetails = {
        ...item,
        translation_link_1: item.translation_link_1 ?? ''
      };

      this.setState({
        pressedBtn,
        tatamiConfig: tatamiConfigDetails,
        ...(tatamiCounter ? { tatamiCounter } : {}),
        open: true
      });
    }
  };

  onSaveTatamiConfig = (evt) => {
    //adding broadcast link and theme of tatami
    const { tournamentId } = this.props;
    const { tatamiConfig } = this.state;

    this.setTatamiConfig(evt, tournamentId, tatamiConfig, () => {
      this.fetchMainData(tournamentId);
      this.hideModal();
    });
  };

  onAddBlock = () => {
    const { lastBlockWithSelection } = this.state;
    const newVal = lastBlockWithSelection + 1;

    this.setState({ lastBlockWithSelection: newVal });
  };

  onRemoveBlock = () => {
    const { lastBlockWithSelection, tatamis } = this.state;
    const tatamiBlock = tatamis[lastBlockWithSelection];

    if (tatamiBlock.every((it) => !+it.is_picked)) {
      const newVal = lastBlockWithSelection - 1;

      this.setState({ lastBlockWithSelection: newVal });
    }
  };

  onSaveBlockName = (evt) => {
    const { tournamentId } = this.props;
    const { tatamiConfig } = this.state;

    if (tatamiConfig.block_name?.length > 0) {
      this.setTatamiConfig(
        evt,
        tournamentId,
        tatamiConfig,
        () => {
          this.fetchMainData(tournamentId);
          this.hideModal();
        },
        true
      );
    }
  };

  changeTatamisNameAndGetAllTatamis = (evt) => {
    const { tournamentData } = this.state;

    this.changeTatamisName(
      evt,
      tournamentData?.id,
      { id: tournamentData?.tatamiHieroglyphs },
      () => {
        this.getTatamisTheme();
        this.fetchMainData(tournamentData?.id);
      }
    );
  };

  hideSnackbar = () => this.setState({ showModal: false });

  hideModal = () =>
    this.setState({
      open: false,
      tatamiConfig: {}
    });

  tatamiRemovalLabels = (label1, label2) => {
    const { classes } = this.props;

    return (
      <div className={classes.center}>
        <span>{label1}</span>
        <div>{label2}</div>
      </div>
    );
  };

  render() {
    const {
      tatamis,
      tatamiConfig,
      tatamisTheme,
      loading,
      showModal,
      success,
      modalInfo,
      lastBlockWithSelection,
      open,
      allBlockName,
      pressedBtn,
      tatamiCounter,
      modalData,
      tournamentData
    } = this.state;
    const { shouldDisableEditing, t, viewportWidth, classes } = this.props;

    const smallScreen = viewportWidth <= 776;

    let onClick, dialogTitle, dialogContent, buttonPurpose, subHeader;

    if (pressedBtn === 'EDIT_TATAMI') {
      onClick = (e) => this.onSaveTatamiConfig(e);
      dialogTitle = [t('edit'), t('tatami')].join(' ');
      dialogContent = (
        <div
          style={{
            display: 'grid',
            gridRowGap: '1rem',
            ...(!smallScreen && { width: '500px' })
          }}>
          <Filter
            label={t('color')}
            options={tatamisTheme}
            onChange={(evt, val) =>
              this.selectOption(evt, val, 'tatamiConfig', 'theme_id', ID)
            }
            item={NAME}
            value={selectedValue(tatamisTheme, ID, tatamiConfig.theme_id) || ''}
            variant="outlined"
          />
          <TextField
            value={tatamiConfig.translation_link_1 || ''}
            label={t('broadcastLink')}
            name="translation_link_1"
            onChange={(evt) => this.textChangeHandler(evt, 'tatamiConfig')}
            variant="outlined"
          />
        </div>
      );
      buttonPurpose = t('save');
      subHeader = [t('tatami'), tatamiConfig.tatami_name].join(': ');
    } else if (pressedBtn === 'REMOVE_CONFIRMATION') {
      onClick = (evt) => {
        if (tatamiCounter > 1) {
          this.addTatamis(evt, tatamiConfig);
        }
      };
      dialogTitle = [t('remove'), t('tatami')].join(' ');
      dialogContent =
        tatamiCounter > 1
          ? this.tatamiRemovalLabels(
              t('categoriesWillBeMoved'),
              t('deleteTatamiMsg')
            )
          : this.tatamiRemovalLabels(
              t('lastTatamiCannotBeRemoved'),
              t('tournamentMustHaveOneTatami')
            );
      buttonPurpose = t('remove');
      subHeader = [t('tatami'), tatamiConfig.tatami_name].join(': ');
    } else if (pressedBtn === 'EDIT_BLOCK') {
      onClick = (evt) => this.onSaveBlockName(evt);
      dialogTitle = [t('edit'), t('tatamiBlock')].join(' ');
      dialogContent = (
        <div
          style={{
            display: 'grid',
            gridRowGap: '1rem',
            ...(!smallScreen && { width: '500px' })
          }}>
          <TextField
            label={t('blockName')}
            value={tatamiConfig?.block_name || ''}
            name="block_name"
            onChange={(evt) => this.textChangeHandler(evt, 'tatamiConfig')}
            variant="outlined"
          />
          <TextField
            label={t('tournamentDay')}
            value={tatamiConfig?.day || ''}
            name="day"
            onChange={(evt) => this.textChangeHandler(evt, 'tatamiConfig')}
            variant="outlined"
          />
        </div>
      );
      buttonPurpose = t('save');
      subHeader = t('block', { count: tatamiConfig.tatami_block });
    } else if (pressedBtn === TEST_ID_INFO_BTN) {
      dialogContent = modalData?.categories?.map((cat, catIdx) => {
        const convertedTime = convertMs(cat.total_time);
        const resultTime = [
          convertedTime?.hours,
          convertedTime.minutes,
          convertedTime.seconds
        ].join(':');

        return (
          <span className={classes.category} key={cat.category_id}>
            <span className={classes.marginRight0dot5}>
              {catIdx + 1}. {cat.category_name}
              {!!+tournamentData?.use_alt_category_name &&
                cat.category_alt_name && (
                  <span
                    style={{
                      ...(cat.category_name !== cat.category_alt_name
                        ? { color: '#005FEE' }
                        : {})
                    }}>{` (${cat.category_alt_name})`}</span>
                )}
            </span>

            {'('}
            {+cat.category_type === 3 && (
              <span
                className={clsx(classes.statisctics, classes.marginRight0dot5)}>
                <GroupIcon className={classes.subSvg} />
                {cat.teams_count}
              </span>
            )}
            <span
              className={clsx(classes.statisctics, classes.marginRight0dot5)}>
              <PersonBlackIcon className={classes.subSvg} />
              {cat.participants_count}
            </span>
            {+cat.category_type !== 4 && (
              <span
                className={clsx(classes.statisctics, classes.marginRight0dot5)}>
                <SkirmishesBlackIcon className={classes.subSvg} />
                {cat.skirmishes_count}
              </span>
            )}
            <span className={classes.statisctics}>
              <ScheduleBlackIcon className={classes.subSvg} />
              {resultTime}
            </span>
            {')'}
          </span>
        );
      });
      dialogTitle = t('categoriesList');
      subHeader = (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>{t('block', { count: modalData.block })}</span>
          <span>{[t('tatami'), modalData.tatami_name].join(' ')}</span>
        </span>
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{
            open,
            onClick,
            dialogTitle,
            dialogContent,
            buttonPurpose,
            subHeader
          }}
          close={this.hideModal}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <AddTournTatamis
            openEditTatamiForm={this.showModalFrom}
            {...{ tatamis }}
            {...{ tatamisTheme }}
            addTatamis={this.addTatamis}
            {...{ shouldDisableEditing }}
            {...{ lastBlockWithSelection }}
            onAddBlock={this.onAddBlock}
            onRemoveBlock={this.onRemoveBlock}
            {...{ allBlockName }}
            {...{ smallScreen }}
            selectedValue={this.selectedValue}
            changeTatamisNameAndGetAllTatamis={
              this.changeTatamisNameAndGetAllTatamis
            }
            {...{ tournamentData }}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(TournamentAddTatamis));
