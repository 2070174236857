import { Component, Fragment } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';

import {
  Group as GroupIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@material-ui/icons';

import { ReactComponent as FormatListNumberedIcon } from 'assets/img/list-white.svg';
import { ReactComponent as Edit } from 'assets/img/edit_white_ic.svg';
import { ReactComponent as Bronze } from 'assets/img/thirdPlaceMedal.svg';
import { ReactComponent as CircleRound } from 'assets/img/circle_round.svg';
import { ReactComponent as ScheduleBlackIcon } from 'assets/img/schedule.svg';
import { ReactComponent as SkirmishesBlackIcon } from 'assets/img/skirmishes_black.svg';
import { ReactComponent as PersonBlackIcon } from 'assets/img/members_num_black.svg';

import {
  CircularProgress,
  TextField,
  Box,
  Chip,
  Switch,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { AuthContext } from 'AuthContext';

import { changeTitle } from 'helpers/actions';
import {
  changeHead,
  changeModal,
  fetchBracketOld,
  fetchBracket,
  fetchTournaments,
  swapParticipants,
  getTatamisTheme,
  fetchAllCategoryTypesV2,
  renumberGridParticipants,
  renumberGridSkirmishes,
  fetchTatmisCategories,
  updateTournamentCategorySettings
} from 'helpers/util';
import {
  finishedTournament,
  selectedValue,
  convertMs
} from 'helpers/selectors';
import {
  ID,
  NAME,
  MODAL_EDIT_IC,
  SELECTION_OPTIONS,
  TEST_ID_INFO_BTN,
  RULE_KUMITE
} from 'helpers/constants';

import SideModal from 'components/Snackbar/SideModal';
import HeaderTournInfo from 'components/HeaderTournInfo/HeaderTournInfo';
import Filter from 'components/Filter/Filter';
import Button from 'components/Buttons/ActionButtons';
import IndividualGrid from './IndividualGrid';
import Modal from 'components/Modal/ModalNewDesign';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import GridSelection from './Grids/GridSelection/GridSelection';
import FilterCheckboxes from 'components/FilterOptions/FilterCheckboxes';

import classes from './stylesGrid.module.css';

export const STATUS_OPTIONS = [
  { id: 1, label: 'Not Started', name: 'NOT_STARTED', color: '#808080' },
  { id: 2, label: 'In-progress', name: 'IN_PROGRESS', color: '#516dff' },
  { id: 3, label: 'Completed', name: 'COMPLETED', color: '#008080' }
];

class GridTournament extends Component {
  constructor(props) {
    super(props);

    this.searchParams = new URLSearchParams(location.search);
    this.state = {
      tournamentId: props.match.params.id,
      tournamentData: {},
      loading: true,
      role: localStorage.getItem('role'),
      langOnLoad: localStorage.getItem('i18nextLng'),
      showModal: false,
      success: false,
      open: false,
      tatamis: [],
      tatamisTheme: [],
      selectedCheckboxes: [],
      gridData: { categories: [] },
      gridCheckboxes: { general: ['coachName'] },
      modalData: { open: false },
      filters: {
        ...(props?.specificDetails
          ? props?.specificDetails
          : {
              typeId: null,
              categoryTypes: []
            })
      },
      showFiltersOnFirstLoad: true, // When the grids page loads, display the selection blocks in a tatami layout initially, then hide them afterward
      collapsed: {},
      allCollapsed: false
    };

    this.getTatamisTheme = getTatamisTheme.bind(this);
    this.changeHead - changeHead.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchBracket = fetchBracket.bind(this);
    this.fetchBracketOld = fetchBracketOld.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.swapParticipants = swapParticipants.bind(this);
    this.fetchAllCategoryTypesV2 = fetchAllCategoryTypesV2.bind(this);
    this.fetchTatmisCategories = fetchTatmisCategories.bind(this);
    this.renumberGridParticipants = renumberGridParticipants.bind(this);
    this.renumberGridSkirmishes = renumberGridSkirmishes.bind(this);
    this.updateTournamentCategorySettings =
      updateTournamentCategorySettings.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeHead(null, t('bracketInformation'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { t, specificDetails } = this.props;
    const { tournamentId, filters } = this.state;

    changeTitle(t('bracketInformation'));

    this.setState({ loading: true });

    if (tournamentId) {
      if (!specificDetails?.bracketOnly) {
        // If it's a grid page, retrieve all tatamis for the tournament and their associated theme.
        this.fetchTournaments(tournamentId, null, null, () => {
          const { tournamentData, role } = this.state;
          const shouldDisableEditing =
            finishedTournament(tournamentData) || !!+tournamentData?.finished;

          this.setState({ shouldDisableEditing });

          this.fetchAllCategoryTypesV2(
            {
              tournament_id: tournamentId,
              ...(role === 'administrator'
                ? { user_id: tournamentData?.organizer_id }
                : {})
            },
            (types) => {
              this.setState((prev) => ({
                filters: { ...prev.filters, categoryTypes: types }
              }));
            }
          );

          this.getTatamisTheme();
          this.fetchMainData(tournamentId);
        });
      } else {
        this.onFetchData(null, (data) => {
          this.onSetGeneralCheckboxes(data);
        });
      }
    }
  }

  fetchMainData(tournamentId) {
    this.fetchTatmisCategories(
      tournamentId,
      (tatamis) => {
        const len = tatamis?.length;
        let newCopy = {};

        for (let i = 0; i < len; i++) {
          const tatami = tatamis[i];

          // Group categories by category_type
          const categories = tatami?.categories?.reduce((acc, c) => {
            if (!acc[c.category_type]) {
              acc[c.category_type] = [];
            }

            acc[c.category_type].push(c);
            return acc;
          }, {});

          // Group tatamis by blocks
          newCopy[`block_${tatami.block}`] = newCopy[`block_${tatami.block}`]
            ? [...newCopy[`block_${tatami.block}`], { ...tatami, categories }]
            : [{ ...tatami, categories }];
        }

        newCopy = Object.values(newCopy);

        this.setState({
          loading: false,
          tatamis: newCopy
        });
      },
      null,
      null,
      true
    );
  }

  onSetGeneralCheckboxes = (data) => {
    const { gridCheckboxes } = this.state;
    const generalCheckboxes = gridCheckboxes.general || [];

    // Generate updated values for all grids
    const updatedValues = Array.isArray(data?.grids)
      ? data.grids.reduce((acc, grid) => {
          acc[grid.id] = [...generalCheckboxes];
          return acc;
        }, {})
      : {};

    this.setState({
      gridCheckboxes: {
        general: [...generalCheckboxes],
        ...updatedValues
      }
    });
  };

  onFetchData = (payload, cb) => {
    const { filters, tournamentId } = this.state;

    this.fetchBracket(
      {
        tournamentId,
        categoryType: filters.typeId,
        tatamiIds: filters.tatamiElems?.map((it) => it.tatami_id),
        categoryId: filters.categoryId,
        ...(payload ?? {})
      },
      (data) => {
        this.setState((prev) => ({
          gridData: { ...prev.gridData, filteredGrid: data?.grids ?? [] }
        }));

        cb && cb(data);
      }
    );
  };

  onSelect = (_, value, elem) => {
    const { param, arg } = elem;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [param]: value ? value[arg] : null,
          ...(elem?.param === 'typeId' ? { tatamiElems: [] } : {})
        },
        allCollapsed: false
      }),
      () => {
        if (elem?.param === 'typeId') {
          this.fetchTatamisBySelectedCategoryType(value?.[arg]);
        }

        this.onFetchData(null, (data) => {
          this.onSetGeneralCheckboxes(data);
        });
      }
    );
  };

  itemToggle = (key) => {
    this.setState((prevState) => ({
      collapsed: {
        ...prevState.collapsed,
        [key]: !this.state.collapsed[key]
      }
    }));
  };

  toggleAll = () => {
    const { gridData } = this.state;

    this.setState((prev) => {
      const newCollapsedState = {};
      const newAllCollapsedState = !prev.allCollapsed;

      // Set all items to either collapsed or expanded based on the new state
      gridData?.categories?.forEach((category) => {
        newCollapsedState[category.id] = newAllCollapsedState;
      });

      return {
        collapsed: newCollapsedState,
        allCollapsed: newAllCollapsedState
      };
    });
  };

  onSwapParticipants = (elem) => {
    const { gridData, tournamentData } = this.state;

    // Reset the swap data
    const resetSwap = () => {
      this.setState((prev) => ({
        gridData: { ...prev.gridData, participant2Swap: null }
      }));
    };

    // Perform swap of participants
    const performSwap = (participantPosition1, participantPosition2) => {
      this.swapParticipants(
        {
          tournamentId: tournamentData.id,
          categoryId: elem.categoryId,
          participantPosition1,
          participantPosition2
        },
        () =>
          this.onFetchData(
            {
              updateOneGridOnly: true,
              categoryId: elem.categoryId
            },
            resetSwap
          )
      );
    };

    // Check if there's a participant to swap
    if (gridData?.participant2Swap?.categoryId) {
      const { categoryId, block, round, skirmish, position } =
        gridData.participant2Swap;

      // Check if the current swap needs to be reset
      const shouldResetSwap =
        categoryId === elem.categoryId &&
        block === elem.block &&
        round === elem.round &&
        skirmish === elem.skirmish &&
        position === elem.position;

      if (shouldResetSwap) {
        resetSwap();

        return;
      }

      // If the swap involves a different category, reset and set the new participant
      if (categoryId !== elem.categoryId) {
        this.setState((prev) => ({
          gridData: { ...prev.gridData, participant2Swap: elem }
        }));
        return;
      }

      // Perform the swap for participants in the same category
      const participantPosition1 = { block, round, skirmish, position };
      const participantPosition2 = {
        block: elem.block,
        round: elem.round,
        skirmish: elem.skirmish,
        position: elem.position
      };

      performSwap(participantPosition1, participantPosition2);
      return;
    }

    // If no existing swap, initialize with the current element
    this.setState((prev) => ({
      gridData: { ...prev.gridData, participant2Swap: elem }
    }));
  };

  onRenumberBracket = () => {
    const { tournamentData } = this.state;

    this.renumberGridParticipants({ tournamentId: tournamentData?.id }, () => {
      this.onFetchData();
    });
  };

  showModal = (evt, item, targetId) => {
    evt.preventDefault();

    if (targetId === TEST_ID_INFO_BTN) {
      const { filters } = this.state;

      this.setState({
        modalData: {
          ...item,
          categories: item?.categories?.[filters?.typeId] ?? [],
          targetId,
          open: true
        }
      });
    } else {
      this.setState({
        modalData: {
          targetId,
          open: true,
          ...item
        }
      });
    }
  };

  hideModal = () => {
    this.setState({
      modalData: { open: false }
    });
  };

  onToggleCheckbox = (evt, name) => {
    const { checked } = evt.target;

    this.setState((prev) => ({
      modalData: {
        ...prev.modalData,
        [name]: checked
      }
    }));
  };

  onSelectCheckbox = (gridId, item) => {
    this.setState((prevState) => {
      const gridCheckboxes = { ...prevState.gridCheckboxes };

      // Check if the current gridId is "general"
      if (gridId === 'general') {
        // Get the current general settings (global selection state)
        const generalCheckboxes = gridCheckboxes[gridId] || [];

        // Check if the item is currently selected or not
        const isSelected = generalCheckboxes.includes(item.id);

        if (isSelected) {
          // If it's already selected, remove
          gridCheckboxes[gridId] = generalCheckboxes.filter(
            (id) => id !== item.id
          );
        } else {
          // If it's not selected, add it to the general array
          gridCheckboxes[gridId] = [...generalCheckboxes, item.id];
        }

        // set the same selected checkboxes for all grids
        Object.keys(gridCheckboxes).forEach((id) => {
          gridCheckboxes[id] = [...gridCheckboxes[gridId]];
        });
      } else {
        // update that specific grid
        const selectedCheckboxes = gridCheckboxes[gridId] || [];
        const isSelected = selectedCheckboxes.includes(item.id);

        if (isSelected) {
          // Remove checkbox, if already selected
          gridCheckboxes[gridId] = selectedCheckboxes.filter(
            (id) => id !== item.id
          );
        } else {
          // Otherwise, add checkbox id
          gridCheckboxes[gridId] = [...selectedCheckboxes, item.id];
        }
      }

      return { gridCheckboxes };
    });
  };

  fetchTatamisBySelectedCategoryType = (typeId) => {
    // Filter tatamis and blocks according to their availability in the chosen category type.
    const { tatamis } = this.state;
    let tatamiByBlocks = [];

    for (let i = 0; i < tatamis?.length; i++) {
      const items = tatamis[i];
      let block = [];

      for (let j = 0; j < items?.length; j++) {
        const tatami = items[j];

        if (tatami.categories[typeId]) {
          block.push(tatami);
        }
      }

      if (block.length > 0) {
        tatamiByBlocks.push(block);
      }
    }

    this.setState({ tatamiByBlocks });
  };

  selectRadioBtn = (evt) => {
    evt.preventDefault();

    const { value } = evt.target;

    if (value) {
      this.setState((prev) => ({
        filters: { ...prev.filters, typeId: value }
      }));

      this.fetchTatamisBySelectedCategoryType(value);
    }
  };

  selectBlocksOrTatamis = (elems, targetId, params) => {
    // setting the tatamiElems for fetching grid
    const setTatamiIdsAndFetchData = (tatamiElems) => {
      this.setState(
        (prev) => ({
          filters: { ...prev.filters, tatamiElems, block: params?.block },
          showFiltersOnFirstLoad: false,
          loading: true
        }),
        () => {
          this.onFetchData(null, (data) => {
            this.onSetGeneralCheckboxes(data);
            this.setState({ loading: false });
          });
        }
      );
    };

    switch (targetId) {
      case 'allBlocks':
        const allTatamiElems = elems.flatMap(
          (block) => block?.map((tatami) => tatami) || []
        );
        setTatamiIdsAndFetchData(allTatamiElems);
        break;
      case 'block':
        const blockTatamiElems = elems?.map((tatami) => tatami) || [];
        setTatamiIdsAndFetchData(blockTatamiElems);
        break;
      case 'tatami':
        const tatamiElems = [elems];
        setTatamiIdsAndFetchData(tatamiElems);
        break;
      default:
        break;
    }
  };

  selectMultipleValues = (_, value, specificDetails) => {
    if (value === null || value === undefined) return;

    this.setState(
      (prev) => ({
        filters: {
          ...prev.filters,
          [specificDetails.param]: value
        },
        allCollapsed: false
      }),
      () => {
        this.onFetchData(null, (data) => {
          this.onSetGeneralCheckboxes(data);
        });
      }
    );
  };

  onSelectCheckboxFilter = (item) => {
    const { selectedCheckboxes, gridData } = this.state;

    const checkedValues = selectedCheckboxes.some((it) => it.id === item.id)
      ? selectedCheckboxes.filter((it) => it.id !== item.id)
      : [...selectedCheckboxes, item];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      let genderList = [];
      let ageList = [];
      const len = this.state.selectedCheckboxes.length;

      for (let i = 0; i < len; i++) {
        const item = this.state.selectedCheckboxes[i];

        if (item.gender) {
          genderList = [...genderList, item.gender];
        } else {
          ageList = [...ageList, item.age];
        }
      }

      const gridsLen = gridData?.grids?.length;
      let newData = [];

      for (let i = 0; i < gridsLen; i++) {
        const grid = gridData?.grids[i];
        const category = gridData?.categories?.find(
          (it) => +it.id === +grid.id
        );

        const filterByGenderList =
          genderList.length > 0
            ? genderList.some((el) => category.gender === el)
            : grid;

        const filterByAgeList =
          ageList.length > 0
            ? ageList.some((el) =>
                category.ageTo
                  ? +category.ageFrom >= el[0] && +category.ageTo <= el[1]
                  : +category.ageFrom >= el[0] && +category.ageFrom <= el[1]
              )
            : grid;

        if (filterByGenderList && filterByAgeList) {
          newData = [...newData, grid];
        }
      }

      this.setState((prev) => ({
        gridData: { ...prev.gridData, filteredGrid: newData }
      }));
    });
  };

  cancelFilter = () => {
    this.setState((prev) => {
      const gridData = prev.gridData || {};
      const grids = gridData.grids || [];

      return {
        selectedCheckboxes: [],
        gridData: {
          ...gridData,
          filteredGrid: grids
        }
      };
    });
  };

  onToggleFilter = () => {
    this.setState((prevState) => ({ isFilterOpen: !prevState.isFilterOpen }));
  };

  onHideSnackbar = () => this.setState({ showModal: false });

  render() {
    const {
      gridData,
      filters,
      showModal,
      success,
      modalInfo,
      tournamentData,
      collapsed,
      allCollapsed,
      loading,
      showFiltersOnFirstLoad,
      modalData,
      shouldDisableEditing,
      tatamiByBlocks,
      tatamisTheme,
      selectedCheckboxes,
      isFilterOpen,
      gridCheckboxes
    } = this.state;
    const { t, specificDetails } = this.props;
    const { viewportWidth } = this.context;

    const pageHeaderStatistics = !specificDetails?.bracketOnly && {
      title: t('drawStatistics'),
      info: [
        {
          label: t('totalCategories'),
          name: gridData?.categories?.length
        },
        {
          label: t('totalParticipants'),
          name: gridData?.participants?.length
        }
      ]
    };
    const filterData = [
      {
        options: filters.categoryTypes,
        onChange: (e, val) =>
          this.onSelect(e, val, { arg: ID, param: 'typeId' }),
        label: t('discipline'),
        item: NAME,
        value: selectedValue(filters.categoryTypes, ID, +filters?.typeId, true)
      },
      { isTatamiFilter: true },
      ...(gridData?.categories
        ? [
            {
              options: gridData.categories,
              onChange: (e, val) =>
                this.onSelect(e, val, { arg: ID, param: 'categoryId' }),
              label: t('categories'),
              item: NAME,
              value: selectedValue(
                gridData.categories,
                ID,
                +filters?.categoryId,
                true
              ),
              arg: 'categoryId'
            }
          ]
        : []),
      ...(!shouldDisableEditing
        ? [
            ...(!+tournamentData?.no_participant_number
              ? [{ renumberParticipants: true }]
              : []),
            { renumberSkirmishes: true }
          ]
        : []),
      { isFilterBtn: true }
    ];
    const filtersCount = filterData?.length;
    const wrapperActionsByCount =
      filtersCount === 6
        ? classes.wrapperActions5
        : filtersCount === 5
        ? classes.wrapperActions4
        : classes.wrapperActions3;
    const iconsTatami = (val) => [
      {
        icon: <FormatListNumberedIcon className={classes.tatamiIcons} />,
        count: val?.categories?.[filters?.typeId]?.length || 0
      }
    ];

    let onClick, dialogTitle, buttonPurpose, dialogContent, subHeader;

    if (modalData?.targetId === TEST_ID_INFO_BTN) {
      dialogContent = modalData?.categories?.map((cat, catIdx) => {
        const convertedTime = convertMs(cat.total_time);
        const resultTime = [
          convertedTime?.hours,
          convertedTime.minutes,
          convertedTime.seconds
        ].join(':');

        return (
          <span className={classes.category} key={cat.category_id}>
            <span className={classes.marginRight0dot5}>
              {catIdx + 1}. {cat.category_name}
              {!!+tournamentData?.use_alt_category_name &&
                cat.category_alt_name && (
                  <span
                    style={{
                      ...(cat.category_name !== cat.category_alt_name
                        ? { color: '#005FEE' }
                        : {})
                    }}>{` (${cat.category_alt_name})`}</span>
                )}
            </span>

            {'('}
            {+cat.category_type === 3 && (
              <span
                className={clsx(classes.statisctics, classes.marginRight0dot5)}>
                <GroupIcon className={classes.subSvg} />
                {cat.teams_count}
              </span>
            )}
            <span
              className={clsx(classes.statisctics, classes.marginRight0dot5)}>
              <PersonBlackIcon className={classes.subSvg} />
              {cat.participants_count}
            </span>
            {+cat.category_type !== 4 && (
              <span
                className={clsx(classes.statisctics, classes.marginRight0dot5)}>
                <SkirmishesBlackIcon className={classes.subSvg} />
                {cat.skirmishes_count}
              </span>
            )}
            <span className={classes.statisctics}>
              <ScheduleBlackIcon className={classes.subSvg} />
              {resultTime}
            </span>
            {')'}
          </span>
        );
      });
      dialogTitle = t('categoriesList');
      subHeader = (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>{t('block', { count: modalData.block })}</span>
          <span>{[t('tatami'), modalData.tatami_name].join(' ')}</span>
        </span>
      );
    } else if (modalData?.targetId === MODAL_EDIT_IC) {
      const findType = filters.categoryTypes.find(
        (it) => +it.id === +modalData.typeId
      );
      const convertedTime = convertMs(modalData.statTime);
      const resultTime = [
        convertedTime?.hours,
        convertedTime.minutes,
        convertedTime.seconds
      ].join(':');

      onClick =
        modalData?.id !== 'general'
          ? (evt) =>
              this.updateTournamentCategorySettings(
                evt,
                {
                  tournamentId: tournamentData?.id,
                  categoryId: modalData?.id,
                  bronze: modalData?.isBronze,
                  circle: modalData?.isCircular
                },
                () => {
                  this.hideModal();
                  this.onFetchData();
                }
              )
          : undefined;
      dialogTitle = t('generalCategorySettings');
      buttonPurpose = modalData?.id !== 'general' && t('save');
      dialogContent = (
        <>
          <h5>{t('additionalInfo')}</h5>
          <Divider style={{ marginBottom: '0.5rem' }} />
          {SELECTION_OPTIONS(t).map((item, idx) => (
            <CheckboxBtn
              key={idx}
              checked={gridCheckboxes[modalData.id]?.includes(item.id) || false} // Check if checkbox is selected for this grid
              formControlClassName={classes.paper}
              classControlLabel={classes.checkboxItem}
              className={classes.checkboxIcon}
              onChange={() => this.onSelectCheckbox(modalData.id, item)} // Pass grid ID and checkbox item to handle selection
              label={item.label}
            />
          ))}

          {findType?.rule === RULE_KUMITE && (
            <>
              <h5 style={{ marginTop: '1.5rem' }}>{t('categorySettings')}</h5>
              <Divider style={{ marginBottom: '0.5rem' }} />
              <div
                style={{
                  gap: '1rem',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}>
                <CheckboxBtn //set bronze round (Kumite)
                  classControlLabel={classes.marginRight0}
                  checked={!!+modalData.isBronze}
                  onChange={(e) => this.onToggleCheckbox(e, 'isBronze')}
                  label={
                    <span
                      className={clsx(classes.flex, classes.centerVertically)}>
                      {t('enableBronzeFight')}
                      <Bronze className={classes.marginLeft08} />
                    </span>
                  }
                />
                <CheckboxBtn //set circle round (Kumite)
                  classControlLabel={classes.marginRight0}
                  checked={!!+modalData.isCircular}
                  onChange={(e) => this.onToggleCheckbox(e, 'isCircular')}
                  label={
                    <span
                      className={clsx(classes.flex, classes.centerVertically)}>
                      {t('roundedRound')}
                      <CircleRound className={classes.marginLeft08} />
                    </span>
                  }
                />
              </div>
            </>
          )}
        </>
      );

      subHeader = modalData?.id !== 'general' && (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>
            {[
              t('block', {
                count: modalData.block
              }),
              modalData.block_name ? `: ${modalData.block_name}` : ''
            ].join('')}
          </span>
          <span>{[t('tatami'), modalData.tatami_name].join(' ')}</span>
          <span>{modalData.name}</span>
          <span className={classes.flex}>
            <span
              className={clsx(classes.statisctics, classes.marginRight0dot5)}>
              <PersonBlackIcon className={classes.subSvg} />
              {modalData.statParticipants}
            </span>
            {+modalData.typeId !== 4 && (
              <span
                className={clsx(classes.statisctics, classes.marginRight0dot5)}>
                <SkirmishesBlackIcon className={classes.subSvg} />
                {modalData.statSkirmishes}
              </span>
            )}
            <span className={classes.statisctics}>
              <ScheduleBlackIcon className={classes.subSvg} />
              {resultTime}
            </span>
          </span>
        </span>
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.onHideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />

        {/* MODAL */}
        <Modal
          open={modalData?.open}
          close={this.hideModal}
          {...{
            onClick,
            dialogTitle,
            buttonPurpose,
            dialogContent,
            subHeader
          }}
        />

        {tournamentData && !specificDetails?.bracketOnly && (
          <HeaderTournInfo
            {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
          />
        )}
        {!loading ? (
          <>
            {/* GRID SELECTION */}
            {!specificDetails?.bracketOnly && showFiltersOnFirstLoad && (
              <GridSelection
                {...{
                  filters,
                  viewportWidth,
                  tatamisTheme
                }}
                tatamis={tatamiByBlocks}
                selectBlocksOrTatamis={this.selectBlocksOrTatamis}
                selectRadioBtn={this.selectRadioBtn}
                showModal={this.showModal}
              />
            )}

            {!specificDetails?.bracketOnly && !showFiltersOnFirstLoad && (
              <div
                className={clsx(classes.wrapperActions, wrapperActionsByCount)}>
                {filterData.map((elem, index) => {
                  const {
                    options,
                    onChange,
                    label,
                    item,
                    value,
                    className,
                    multiple
                  } = elem;

                  return (
                    <Fragment key={index}>
                      {item && (
                        <Filter
                          className={className}
                          {...{ options }}
                          {...{ onChange }}
                          {...{ label }}
                          {...{ item, multiple }}
                          value={value || ''}
                          variant="outlined"
                          disableClearable={true}
                        />
                      )}
                      {elem?.isTatamiFilter && (
                        <Autocomplete
                          multiple
                          options={tatamiByBlocks.flat()}
                          groupBy={(option) => option.block_name} // Group by block_name
                          getOptionLabel={(option) => option.tatami_name}
                          value={filters?.tatamiElems ?? []}
                          onChange={(e, val) =>
                            this.selectMultipleValues(e, val, {
                              param: 'tatamiElems'
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('tatamis')}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          )}
                          renderOption={(props, option) => {
                            const tatamiBg = selectedValue(
                              tatamisTheme,
                              ID,
                              props.theme_id,
                              true
                            )?.color1;

                            return (
                              <span
                                {...props}
                                key={props.tatami_id}
                                className={clsx(classes.tatamiOption)}
                                style={{
                                  background: tatamiBg,
                                  color: '#fff'
                                }}>
                                <span
                                  className={clsx(classes.tatamiOptionName)}>
                                  {props.tatami_name}
                                </span>

                                <span className={clsx(classes.flex)}>
                                  {iconsTatami(props)?.map(
                                    ({ icon, count }, idx) => (
                                      <span
                                        key={idx}
                                        className={clsx(classes.tatamiDetails)}>
                                        {icon}
                                        {count}
                                      </span>
                                    )
                                  )}
                                </span>
                              </span>
                            );
                          }}
                          renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                              const tatamiBg = selectedValue(
                                tatamisTheme,
                                ID,
                                option.theme_id,
                                true
                              )?.color1;

                              return (
                                <Chip
                                  key={index}
                                  label={option.tatami_name}
                                  {...getTagProps({ index })}
                                  style={{
                                    margin: 2,
                                    background: tatamiBg,
                                    color: '#fff'
                                  }}
                                />
                              );
                            });
                          }}
                        />
                      )}
                      {elem?.renumberParticipants &&
                        !+tournamentData?.no_participant_number && (
                          <Button
                            label={t('renumberParticipants')}
                            className={classes.saveBtn}
                            isSaveBtn
                            onClick={this.onRenumberBracket}
                          />
                        )}
                      {elem.renumberSkirmishes && (
                        <Button
                          label={t('renumberSkirmishes')}
                          className={classes.saveBtn}
                          isSaveBtn
                          onClick={() =>
                            this.renumberGridSkirmishes(
                              {
                                tournamentId: tournamentData?.id,
                                typeId: filters?.typeId
                              },
                              () => this.onFetchData()
                            )
                          }
                        />
                      )}
                      {elem?.isFilterBtn && (
                        <Button
                          className={`${classes.saveBtn} ${
                            isFilterOpen && classes.selected
                          }`}
                          onClick={this.onToggleFilter}
                          label={
                            <span
                              className={clsx(classes.flex, classes.flexGrow1)}>
                              <span className={classes.flexGrow1}>
                                {t('filter')}
                              </span>
                              <ArrowDropDownIcon
                                className={isFilterOpen && classes.arrowDropUp}
                              />
                            </span>
                          }
                          isSaveBtn={!isFilterOpen}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            )}

            <FilterCheckboxes
              {...{
                selectedCheckboxes,
                isFilterOpen
              }}
              filterDetails={{
                tounamentPresence: false,
                noUnisex: false
              }}
              onSelectCheckbox={this.onSelectCheckboxFilter}
              cancelFilter={() => this.cancelFilter()}
            />

            {!specificDetails?.bracketOnly && !showFiltersOnFirstLoad && (
              <Box className={clsx(classes.flex, classes.centerVertically)}>
                <Box sx={{ fontSize: '13px', paddingRight: '0.5rem' }}>
                  {t('enableDisableCheckboxesMsg', {
                    name: SELECTION_OPTIONS(t)
                      .map((it) => it.label)
                      .join(', ')
                  })}
                </Box>
                <Edit
                  className={`${classes.pencilToggleInfoDetails} ${classes.onHover}`}
                  onClick={(evt) => {
                    evt.stopPropagation();

                    this.showModal(evt, { id: 'general' }, MODAL_EDIT_IC);
                  }}
                />

                {/* toggle all categoties */}
                <FormControlLabel
                  className={classes.toggleAll}
                  control={
                    <Switch
                      checked={allCollapsed}
                      onChange={this.toggleAll}
                      color="primary"
                    />
                  }
                  label={t('expandList')}
                />
              </Box>
            )}

            {gridData?.filteredGrid?.map((grid, gridIdx) => {
              return (
                <IndividualGrid
                  key={gridIdx}
                  {...{
                    grid,
                    tournamentData,
                    collapsed,
                    gridCheckboxes,
                    viewportWidth,
                    specificDetails
                  }}
                  data={gridData}
                  categoryTypes={filters.categoryTypes}
                  onSwapParticipants={this.onSwapParticipants}
                  itemToggle={this.itemToggle}
                  showModal={this.showModal}
                />
              );
            })}
          </>
        ) : (
          <CircularProgress style={{ display: 'flex', margin: 'auto' }} />
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(GridTournament));
