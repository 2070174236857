import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '../../Buttons/ActionButtons';
import { SearchInput } from 'components';
import Filter from '../../Filter/Filter';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from './NewStyles';

const NewUsersToolbar = (props) => {
  const {
    textChange,
    pressEnter,
    onClick,
    search,
    clearSearch,
    showButton,
    styleCard,
    className,
    filter,
    role,
    content,
    showParticipantForm,
    specificDetails,
    shouldHideSearch,
    closeIcon,
    filterButton,
    isFilterOpen,
    filterSwitch
  } = props;

  const isContentExists = content ? 'content' : 'searchInput';
  const isFilterExists = filter ? 'filter' : 'searchInput';

  const classes = useStyles({
    isContentExists,
    isFilterExists,
    showButton
  });
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.row,
        !isFilterOpen && classes.marginBottom1,
        styleCard
      )}>
      {filterButton && (
        <Button
          onClick={() => filterSwitch()}
          label={
            <span
              className={clsx(
                classes.flex,
                classes.verticallyCentered,
                classes.center,
                classes.flexGrow1
              )}>
              <span className={classes.flexGrow1}>{t('filter')}</span>
              <ArrowDropDownIcon
                className={isFilterOpen && classes.arrowDropUp}
              />
            </span>
          }
          isSaveBtn={!isFilterOpen}
          className={isFilterOpen && classes.selected}
          style={{ gridArea: 'filterButton' }}
        />
      )}
      <div style={{ gridArea: 'content' }}>{content && content}</div>
      {filter &&
        role !== 'coach' &&
        filter
          .filter((it) => it)
          .map(
            (
              {
                options,
                onChange,
                label,
                item,
                optional,
                value,
                specificDetails
              },
              idx
            ) => (
              <Filter
                classes={{
                  inputRoot: classes.outlined
                }}
                value={value || ''}
                disableClearable
                key={`${item}_${idx}`}
                className={clsx(
                  classes.filter,
                  idx !== 0 && classes.paddingLeft
                )}
                {...{ options }}
                {...{ onChange }}
                {...{ label }}
                {...{ item, specificDetails }}
                {...{ optional }}
                variant="outlined"
                style={{ gridArea: 'filter' }}
              />
            )
          )}
      {!shouldHideSearch && (
        <SearchInput
          onChange={textChange}
          id="search-input"
          onKeyPress={pressEnter}
          isSearchInactive={!search}
          value={search || ''}
          {...{ clearSearch }}
          className={clsx(className)}
          {...{ specificDetails }}
          style={{ gridArea: 'searchInput' }}
        />
      )}
      {showButton && !showParticipantForm && (
        <Button
          id="add_new_button"
          {...{ onClick }}
          isSaveBtn={true}
          label={
            <>
              <span className={classes.plus}>&#x002B;</span>
              {t('addNew')}
            </>
          }
          style={{ gridArea: 'add' }}
        />
      )}
      {closeIcon}
    </div>
  );
};

export default NewUsersToolbar;
