import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  tag: {
    backgroundColor: 'transparent',
    border: '1px solid #8996AC',
    borderRadius: '10px'
  },
  icons: {
    '& .MuiSvgIcon-root': {
      color: '#8996AC'
    }
  },

  hideOption: {
    '&.MuiAutocomplete-listbox': {
      '& li:nth-of-type(1)': { display: 'none' }
    }
  },

  placeholder: {
    '& .MuiInputLabel-root': {
      color: '#8996AC !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8996AC'
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        textOverflow: 'ellipsis !important'
      },
      '&:focus': {
        '&::placeholder': {
          color: 'transparent'
        }
      }
    }
  },

  langListbox: {
    marginLeft: '-9.5px',
    fontSize: 18,
    color: '#8996AC',
    weight: 700
  }
}));
