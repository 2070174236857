import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { Tooltip } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/img/new_trash_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';

import { DELETE_ICON, TEST_ID_FORM } from '../../helpers/constants';
import {
  changeHead,
  textChangeHandler,
  changeDate,
  changeImage,
  onBlur,
  fetchReference,
  selectOption,
  fetchQdan,
  validateForm,
  fetchStaff,
  pageChangeHandler,
  rowsPerPageHandler,
  deleteStaff,
  updateStaff,
  addNewStaff,
  fetchCountries
} from '../../helpers/util';
import {
  findSortDirection,
  compareValueForSorting,
  DDMMYYYY
} from '../../helpers/selectors';

import { AuthContext } from 'AuthContext';
import { SearchInput } from 'components';

import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/CardsList';
import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import Pagination from '../../components/TablePagination/TablePagination';
import Button from '../../components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';
import CoachForm from 'components/QuickTournRegistration/CoachForm/CoachForm';

import Styles from './Styles';

const defaultForm = {
  first_name: '',
  last_name: '',
  gender: '',
  birthday: '',
  country_id: ''
};

class Judges extends Component {
  state = {
    open: false,
    showModal: false,
    modalInfo: '',
    success: false,
    filters: {
      searchBar: '',
      isSwitchSet: true
    },
    isLoading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    allRegistrar: [],
    modalData: {},
    errors: {},
    sortDirection: [],
    recordsCount: 0,
    rowsPerPage: 25,
    page: 1,
    showError: {},
    all_qual_rf: [],
    qdanList: [],
    countries: []
  };

  static contextType = AuthContext;

  changeHead = changeHead.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  changeDate = changeDate.bind(this);
  changeImage = changeImage.bind(this);
  onBlur = onBlur.bind(this);
  fetchReference = fetchReference.bind(this);
  selectOption = selectOption.bind(this);
  fetchQdan = fetchQdan.bind(this);
  validateForm = validateForm.bind(this);
  fetchStaff = fetchStaff.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  deleteStaff = deleteStaff.bind(this);
  updateStaff = updateStaff.bind(this);
  addNewStaff = addNewStaff.bind(this);
  fetchCountries = fetchCountries.bind(this);

  componentDidMount() {
    this.fetchData(() => {
      this.changeHead(null, this.props.t('judges'));
      this.fetchQdan();
      this.fetchCountries();
      this.fetchReference('qual_rf', (data) =>
        this.setState({ all_qual_rf: data })
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { sortDirection, shouldUpdateTable, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeHead(null, t('judges'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  fetchData = (cb) => {
    this.fetchStaff(null, null, (staffData) => {
      const recordsCount = staffData.length;

      this.setState(
        {
          recordsCount,
          allRegistrar: staffData,
          filteredRegistrar: staffData,
          isLoading: false
        },
        () => cb && cb()
      );
    });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  closeModalHandler = () => this.setState({ showModal: false });

  searchHandler = (evt) => {
    const { value } = evt.target;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchBar: value },
        page: 1
      }),
      () => {
        this.onFilterRecords();
      }
    );
  };

  showModalForm = (evt, item = defaultForm, idx = null, action = 'edit') => {
    evt.preventDefault();

    const id = item?.id;
    let state = {
      selectedRow: id,
      open: true,
      pressedIcon: TEST_ID_FORM,
      errors: {}
    };

    if (id) {
      state = {
        ...state,
        modalData: {
          ...item,
          first_name: item.firstName,
          last_name: item.lastName,
          gender: item.gender,
          country_id: item.countryId,
          birthday: item.birthday,
          qdan: item.qdanId,
          qual_rf: item.qualificationRfRid,
          currentRecordIdx: idx
        }
      };
    } else {
      state = { ...state, modalData: defaultForm };
    }

    if (action === DELETE_ICON) {
      this.confirmDeleteJudge(id);
    } else {
      this.setState(state);
    }
  };

  confirmDeleteJudge = (id) => {
    this.setState({
      open: true,
      pressedIcon: DELETE_ICON,
      modalData: { id }
    });
  };

  deleteJudge = (id) => {
    this.deleteStaff({ id }, () => {
      this.fetchData(() => {
        this.hideModal();
      });
    });
  };

  hideModal = () => {
    this.setState({
      open: false,
      selectedRow: null,
      modalData: {}
    });
  };

  saveForm = (evt) => {
    evt.preventDefault();
    const { sortDirection, errors, modalData } = this.state;
    const copyErrors = { ...errors };

    if (copyErrors?.age?.length > 0) {
      copyErrors.birthday = this.props.t('newerDate');
      this.setState({ errors: copyErrors });
      return;
    }

    this.validateForm(evt, defaultForm, modalData, 'errors', () => {
      const formattedData = {
        id: modalData.id,
        first_name: modalData.first_name,
        last_name: modalData.last_name,
        gender: modalData.gender,
        country_id: modalData.country_id,
        birthday: modalData.birthday,
        qdan: modalData.qdan,
        qual_rf: modalData.qual_rf
      };

      if (modalData?.id) {
        this.updateStaff(evt, formattedData, () => {
          this.fetchData(() => {
            this.hideModal();
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }
          });
        });
      } else {
        this.addNewStaff(evt, formattedData, () => {
          this.fetchData(() => {
            this.hideModal();
            if (sortDirection?.length > 0) {
              this.onSort(sortDirection[2], null, true);
            }
          });
        });
      }
    });
  };

  onFilterRecords = () => {
    const { allRegistrar, filters } = this.state;
    const len = allRegistrar?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = allRegistrar[i];
      const bySearch = filters.searchBar
        ? [el.firstName, el.lastName].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      if (bySearch) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredRegistrar: newFilteredRecords }, () => {
      const { filteredRegistrar } = this.state;
      const len = filteredRegistrar?.length;

      this.setState({ recordsCount: len });
    });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allRegistrar, sortDirection } = this.state;
    const clone = [...allRegistrar];

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, sortField, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        allRegistrar: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      success,
      modalInfo,
      showModal,
      filters,
      isLoading,
      filteredRegistrar,
      modalData,
      errors,
      selectedRow,
      sortDirection,
      open,
      recordsCount,
      page,
      rowsPerPage,
      pressedIcon,
      qdanList,
      countries,
      all_qual_rf
    } = this.state;
    const { t, classes } = this.props;

    const langRu = localStorage.getItem('i18nextLng') === 'ru';
    const FIRST_NAME_JUDGE = langRu ? 'lastName' : 'firstName';
    const LAST_NAME_JUDGE = langRu ? 'firstName' : 'lastName';

    const recordDataParams = (item, idx) => {
      return {
        selected: selectedRow === item?.id,
        main: {
          label: t('name') + ' ' + t('judges'),
          hasSort: true,
          sort: FIRST_NAME_JUDGE,
          style: {
            cardMainPart: classes.cardMainPart,
            cardValuePart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1,
            class: classes.rowOrder
          },
          name: [item?.[FIRST_NAME_JUDGE], item?.[LAST_NAME_JUDGE]].join(' ')
        },
        info: [
          {
            sort: 'id',
            value: item?.id,
            name: t('ID'),
            width: 'minmax(2em, 5.5em)'
          },
          {
            sort: 'gender',
            value: item?.gender,
            name: t('gender'),
            width: 'minmax(2em, 5.5em)'
          },
          {
            sort: 'country',
            value: item?.country,
            name: t('country'),
            width: 'minmax(2em, 5.5em)'
          },
          {
            sort: 'birthday',
            value: DDMMYYYY(item?.birthday),
            name: t('birthday'),
            width: '4.5em'
          },
          {
            sort: 'qdan_name',
            value: item?.qdan_name,
            name: t('kyuDan'),
            width: '4.5em'
          },
          {
            sort: 'qualificationRfOrder',
            value: item?.qualificationRfShort,
            name: t('rfQual'),
            width: '4.8em'
          }
        ]
      };
    };

    const dojoInfo = [
      {
        name: 'first_name',
        label: t('firstname'),
        required: true
      },
      {
        name: 'last_name',
        label: t('lastname'),
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        country: true,
        isFilter: true
      },
      {
        kdan: true,
        isFilter: true
      },
      {
        qualificationRfRid: true,
        isFilter: true
      }
    ];

    let dialogTitle, dialogContent, onClick, buttonPurpose;

    if (pressedIcon === DELETE_ICON) {
      dialogTitle = t('deleteJudge');
      dialogContent = t('deleteJudgeMsg');
      onClick = () => {
        this.deleteJudge(modalData.id);
      };
      buttonPurpose = t('deleteRecord', { name: '' });
    } else {
      const rowOrder =
        modalData.currentRecordIdx !== undefined
          ? modalData.currentRecordIdx + 1
          : 0;
      const numOrder = (page - 1) * rowsPerPage + rowOrder;

      dialogTitle = modalData?.id
        ? `${numOrder} ${t('updateJudge')}`
        : t('addJudge');

      dialogContent = (
        <CoachForm
          inputs={dojoInfo}
          onBlur={this.onBlur}
          qdanList={qdanList}
          countries={countries}
          all_qual_rf={all_qual_rf}
          changeTxt={this.textChangeHandler}
          param={'modalData'}
          values={{ ...modalData }}
          selectOption={this.selectOption}
          {...{ errors }}
          saveCoachData={this.saveForm}
          changeImage={this.changeImage}
          changeDate={(date) =>
            this.changeDate(date, 'modalData', errors, true)
          }
          preventEvent={false}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
        />
      );
      onClick = this.saveForm;
      buttonPurpose = t('save');
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <Modal
              open={open}
              {...{ qdanList }}
              {...{ countries }}
              {...{ all_qual_rf }}
              close={this.hideModal}
              dialogTitle={dialogTitle}
              dialogContent={dialogContent}
              onClick={onClick}
              buttonPurpose={buttonPurpose}
              {...(pressedIcon === DELETE_ICON
                ? { buttonPurpose }
                : {
                    onSwitchBetween: modalData?.id && {
                      prev: (evt) => {
                        const nextItemIdx = modalData.currentRecordIdx - 1;

                        if (nextItemIdx >= 0) {
                          const item = filteredRegistrar[nextItemIdx];

                          this.showModalForm(evt, item, nextItemIdx);
                        }
                      },
                      next: (evt) => {
                        const nextItemIdx = modalData.currentRecordIdx + 1;
                        const len = filteredRegistrar?.length - 1;

                        if (len >= nextItemIdx) {
                          const item = filteredRegistrar[nextItemIdx];

                          this.showModalForm(evt, item, nextItemIdx);
                        }
                      }
                    }
                  })}
            />
            <span
              className={clsx(
                classes.gridButtons,
                classes.centerVertically,
                classes.marginBottom1,
                classes.viewAddButton
              )}>
              <SearchInput
                className={classes.search}
                onChange={this.searchHandler}
                clearSearch={this.clearSearch}
                value={filters.searchBar}
                isSearchInactive={!filters.searchBar}
                style={{ gridArea: 'searchInput' }}
              />

              <Button
                id="add_new_button"
                onClick={(evt) => this.showModalForm(evt)}
                isSaveBtn={true}
                label={
                  <>
                    <span className={classes.plus}>&#x002B;</span>
                    {t('addNew')}
                  </>
                }
                style={{ gridArea: 'add' }}
              />
            </span>
            {recordsCount != 0 ? (
              <>
                <Table
                  onSort={this.onSort}
                  {...{ sortDirection }}
                  {...{
                    recordDataParams
                  }}
                  data={filteredRegistrar.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )}
                  subTableDataKey="records"
                  id="id"
                  actionIcons={(item, idx, className) => (
                    <>
                      <Tooltip arrow title={t('updateJudge')}>
                        <EditIcon
                          className={clsx(className)}
                          onClick={(evt) => this.showModalForm(evt, item, idx)}
                        />
                      </Tooltip>
                      <Tooltip arrow title={t('remove')}>
                        <DeleteIcon
                          id={DELETE_ICON}
                          className={clsx(className, classes.marginLeft1)}
                          onClick={(evt) =>
                            this.showModalForm(evt, item, idx, DELETE_ICON)
                          }
                        />
                      </Tooltip>
                    </>
                  )}
                />

                <Pagination
                  count={recordsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(Styles)(withRouter(Judges)));
