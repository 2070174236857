import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    borderRadius: '4px'
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(44,52,57,0.5)',
      opacity: 0.6,
      borderRadius: '5px',
      '& $imgShadow': {
        opacity: 1,
        color: '#000'
      }
    }
  },

  wholeSize: {
    width: '100%',
    height: '100%'
  },

  clearButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    color: 'white',
    borderRadius: '5px',
    width: '2em',
    height: '2em',
    fontSize: '14px',
    zIndex: 2,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.8)'
    }
  },

  image: {
    cursor: 'pointer',
    transition: '.5s ease',
    borderRadius: '4px',
    border: '1px solid #8996AC'
  },
  cover: {
    objectFit: 'cover',
    color: '#000'
  },
  coverHeader: {
    '@media (max-width:1300px)': {
      objectFit: 'cover'
    }
  },

  fullSize: {
    height: '100%',
    width: '100%'
  },

  camera: {
    width: 'auto',
    color: '#000',
    fontSize: '52px',
    '@media (max-width: 767px)': {
      fontSize: '22px'
    }
  },
  text: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '32px',
    '@media (max-width: 767px)': {
      fontSize: '15px'
    }
  },

  imgShadow: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#000'
  },
  uploadedImgExist: {
    color: '#fff'
  },
  input: {
    display: 'none'
  }
});
