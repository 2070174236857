import { Component, Fragment, useState } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SignatureIMG from '../../assets/img/signature_img.svg';

import PortraitHeader from '../../assets/img/portrait_header.svg';
import LandscapeHeader from '../../assets/img/landscape_header.svg';

import PortraitHeaderMobile from '../../assets/img/portrait_header_mobile.svg';
import LandscapeHeaderMobile from '../../assets/img/landscape_header_mobile.svg';

import ReportIcon from '../../assets/img/report_icon.svg';

import { AuthContext } from 'AuthContext';

import {
  isDesktopView,
  isMobileView,
  isSchool,
  isTabletView
} from '../../helpers/selectors';
import {
  changeModal,
  fetchTournaments,
  fetchAllCategoryTypes,
  downloadFile,
  exportReportGeneral,
  changeHead,
  exportTournamentTatamiReport,
  changeImage,
  updateTournament,
  downloadUplaodedFile,
  fetchReference,
  exportAwardSequenceReport
} from '../../helpers/util';
import { serverDefaultPath, OK } from '../../helpers/constants';
import { categoryTypesPresentOnTournament } from '../../helpers/selectors';

import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import SideModal from '../../components/Snackbar/SideModal';
import LoadingState from '../../components/LoadingState/LoadingState';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';
import UploadImage from 'components/UploadImage/UploadImage';
import RadioBtn from 'components/RadioBtn/RadioBtn';
import Modal from '../../components/Modal/ModalNewDesign';
import Button from '../../components/Buttons/ActionButtons';
import SearchInput from '../../components/SearchInput/SearchInput';

import styles from './Styles';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      success: false,
      tournamentData: {},
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      selectedCheckboxesTournamentStatistic: [],
      selectedCheckboxesMedalBronze: [],
      selectedCheckboxesReportApplicants: [],
      selectedCheckboxesCategoryDistribution: [],
      selectedCheckboxesCoachClub: [],
      selectedCheckboxesParticipants: [],
      selectedCheckboxesCategories: [],
      selectedCheckboxesRegistration: [],
      selectedCheckboxesRunners: [],
      selectedCheckboxesRatting: [],
      selectedCheckboxesTournamentCategories: [],
      selectedCheckboxeskumite: [],
      selectedCheckboxeskata: [],
      selectedCheckboxesteam_kata: [],
      selectedCheckboxestameshiwari: [],
      selectedCheckboxeskata_by_flags: [],
      selectedCheckboxespoint_kumite: [],
      selectedCheckboxesfull_contact: [],
      selectedCheckboxeskumite_kudo: [],
      selectedCheckboxesWinners: [],
      exportAwardSequenceReport: [],
      selectedCheckboxesBudget: [],
      selectedRadioButtonMedalBronze: 0,
      selectedRadioButtonCoachClub: 0,
      selectedOrientation: {},
      visibility: false,
      open: false
    };
    this.tournamentIdParams = props.match.params?.id;

    this.changeModal = changeModal.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.changeHead = changeHead.bind(this);
    this.exportReportGeneral = exportReportGeneral.bind(this);
    this.exportTournamentTatamiReport = exportTournamentTatamiReport.bind(this);
    this.changeImage = changeImage.bind(this);
    this.updateTournament = updateTournament.bind(this);
    this.downloadUplaodedFile = downloadUplaodedFile.bind(this);
    this.fetchReference = fetchReference.bind(this);
    this.exportAwardSequenceReport = exportAwardSequenceReport.bind(this);
  }

  static contextType = AuthContext;

  selectCheckbox = (paramName, key) => {
    this.setState((prevState) => {
      const selectedCheckboxes = prevState[paramName] ?? [];
      const checkedValues = selectedCheckboxes.includes(key)
        ? selectedCheckboxes.filter((it) => it !== key)
        : [...selectedCheckboxes, key];

      return { [paramName]: checkedValues };
    });
  };

  onSelectCheckboxInHeader = (key) => {
    this.setState((prev) => ({
      tournamentData: {
        ...prev.tournamentData,
        [key]: !Boolean(
          typeof prev?.tournamentData?.[key] === 'string'
            ? +prev?.tournamentData?.[key] // Convert to number only if it's a string '1' / '0'
            : prev?.tournamentData?.[key]
        )
      }
    }));
  };

  componentDidUpdate() {
    const { tournamentData, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      this.fetchData();
      changeHead(tournamentData, t('reports'));

      this.setState({ langOnLoad: currentLang });
    }

    this.saveStateToLocalStorage();
  }

  componentDidMount() {
    const { langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(this.tournamentIdParams, null, null, () => {
      const { tournamentData } = this.state;

      this.fetchData(() => {
        const updateSignaturePreview = (signatureId, previewField) => {
          this.downloadUplaodedFile(signatureId, null, (file) => {
            this.setState({
              tournamentData: {
                ...this.state.tournamentData,
                [previewField]: URL.createObjectURL(file)
              }
            });
          });
        };

        tournamentData?.signature_1_id &&
          updateSignaturePreview(
            tournamentData?.signature_1_id,
            'signature_1_preview'
          );
        tournamentData?.signature_2_id &&
          updateSignaturePreview(
            tournamentData?.signature_2_id,
            'signature_2_preview'
          );
      });

      changeHead(tournamentData, t('reports'));
    });

    const savedState = localStorage.getItem('reportsSettings');
    if (savedState) {
      this.setState(JSON.parse(savedState));
    }

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'event_reports'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  fetchData = (cb) => {
    const { t } = this.props;
    const { tournamentData } = this.state;
    const isTraining = isSchool(tournamentData);
    const isParticipant = !!+tournamentData?.no_participant_number;
    const mainReportsSettings = [
      ...(!+tournamentData?.no_country
        ? [{ id: 'countries', name: 'show_country', label: t('countries') }]
        : []),
      ...(!!+tournamentData?.show_reg_region
        ? [{ id: 'regions', name: 'show_region', label: t('regions') }]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [{ id: 'cities', name: 'show_city', label: t('cities') }]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [{ id: 'clubs', name: 'show_club', label: t('club') }]
        : []),
      { id: 'coaches', name: 'show_coach', label: t('coach') },
      {
        id: 'put_signature',
        label: t('addRecord', { name: t('signature') })
      }
    ];
    const isRegisterJudges = !!+tournamentData?.reg_judge_form;

    this.fetchAllCategoryTypes((allSystemTypes) => {
      const tournCategories = categoryTypesPresentOnTournament(
        allSystemTypes || [],
        tournamentData?.category_types
      );

      const tableData = [
        {
          paramName: 'selectedCheckboxesTournamentStatistic',
          name: t('tournamentReference'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_tournament_statistic_report/${this.tournamentIdParams}`,
              activeCheckboxesParam: 'selectedCheckboxesTournamentStatistic',
              reportCheckboxes: mainReportsSettings,
              isApiGet: true
            }),
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          modal: true
        },
        {
          name: t('listOfWinnersByCategory'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_winners_report/${this.tournamentIdParams}`,
              activeCheckboxesParam: 'selectedCheckboxesWinners',
              reportCheckboxes: mainReportsSettings,
              isApiGet: true
            }),
          paramName: 'selectedCheckboxesWinners',
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          modal: true
        },
        {
          name: t('awardProcedure'),
          onClick: () =>
            this.exportAwardSequenceReport(this.tournamentIdParams),
          buttonName: t('download'),
          paramName: 'selectedCheckboxesAwardSequenceReport',
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          modal: true
        },
        {
          paramName: 'selectedCheckboxesMedalBronze',
          name: t('tournamentResults'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_results_report/${this.tournamentIdParams}`,
              activeCheckboxesParam: 'selectedCheckboxesMedalBronze',
              params: {
                ...(this.state.selectedCheckboxesMedalBronze.length > 0
                  ? {
                      category_types: JSON.stringify(
                        this.state.selectedCheckboxesMedalBronze
                      )
                    }
                  : {}),
                target: this.state.selectedRadioButtonMedalBronze
              }
            }),
          checkboxes: {
            additionalSettings: tournCategories?.map((it) => ({
              id: it?.id,
              label: it.name
            }))
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          paramNameRadio: 'selectedRadioButtonMedalBronze',
          radioButtons: mainReportsSettings,
          modal: true
        },
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesReportApplicants',
                name: t('ListOfAppliedTeamsforEvent'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/get_participation_candidates_report/${this.tournamentIdParams}`,
                    activeCheckboxesParam: 'selectedCheckboxesReportApplicants',
                    reportCheckboxes: mainReportsSettings,
                    isApiGet: true
                  }),
                checkboxes: {
                  additionalSettings: mainReportsSettings
                },
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              }
            ]
          : []),
        {
          paramName: `selectedCheckboxesBudget`,
          name: t('organizationalContributions'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_budget_participation_report/${this.tournamentIdParams}`,
              activeCheckboxesParam: `selectedCheckboxesBudget`,
              reportCheckboxes: mainReportsSettings
            }),
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          modal: true
        },
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesCategoryDistribution',
                name: t('reportCategoryDistribution'),
                onClick: () =>
                  this.exportTournamentTatamiReport(this.tournamentIdParams),
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              }
            ]
          : []),
        {
          paramName: 'selectedCheckboxesCoachClub',
          name: t('reportCoachClub'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_participants_report/${this.tournamentIdParams}`,
              params: { target: this.state.selectedRadioButtonCoachClub },
              activeCheckboxesParam: 'selectedCheckboxesCoachClub',
              reportCheckboxes: mainReportsSettings,
              isApiGet: true
            }),
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          paramNameRadio: 'selectedRadioButtonCoachClub',
          radioButtons: mainReportsSettings,
          modal: true
        },
        ...(!isParticipant
          ? [
              {
                paramName: 'selectedCheckboxesParticipants',
                name: t('reportParticipants'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/pdf_participant_numbers/${this.tournamentIdParams}`,
                    activeCheckboxesParam: 'selectedCheckboxesParticipants',
                    isApiGet: true
                  })
              }
            ]
          : []),
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesCategories',
                name: t('reportListOfCategoties'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/get_categories_report/${this.tournamentIdParams}`,
                    activeCheckboxesParam: 'selectedCheckboxesCategories',
                    reportCheckboxes: [
                      ...mainReportsSettings,
                      { id: 'show_history', label: t('achievements') }
                    ],
                    isApiGet: true
                  }),
                checkboxes: {
                  additionalSettings: mainReportsSettings,
                  achievements: [
                    { id: 'show_history', label: t('achievements') }
                  ]
                },
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              }
            ]
          : []),
        {
          paramName: 'selectedCheckboxesRegistration',
          name: t('reportRegistration'),
          onClick: () =>
            this.exportReportGeneral({
              api: `/get_registrations_report/${this.tournamentIdParams}`,
              activeCheckboxesParam: 'selectedCheckboxesRegistration',
              reportCheckboxes: mainReportsSettings,
              isApiGet: true
            }),
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          modal: true
        },
        {
          paramName: 'selectedCheckboxesRunners',
          name: t('reportRunners'),
          onClick: () =>
            this.exportReportGeneral({
              api: '/recorder/get_runner',
              activeCheckboxesParam: 'selectedCheckboxesRunners',
              params: { tournament_id: this.tournamentIdParams },
              isApiGet: true
            })
        },
        {
          paramName: 'selectedCheckboxesRatting',
          name: t('reportRatting'),
          onClick: () =>
            this.exportReportGeneral({
              api: '/skirmish/get_rating',
              params: {
                tournament_id: this.tournamentIdParams,
                as_excel: 1
              },
              activeCheckboxesParam: 'selectedCheckboxesRatting',
              reportCheckboxes: mainReportsSettings,
              isApiGet: true
            }),
          checkboxes: {
            additionalSettings: mainReportsSettings
          },
          orientation: [
            { id: 'portrait', label: t('portraitPageOrientation') },
            { id: 'landscape', label: t('landscapePageOrientation') }
          ],
          modal: true
        },
        ...(!isTraining
          ? [
              {
                paramName: 'selectedCheckboxesTournamentCategories',
                name: t('reportCategories'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/get_tournament_categories_report/${this.tournamentIdParams}`,
                    activeCheckboxesParam:
                      'selectedCheckboxesTournamentCategories',
                    isApiGet: true
                  }),
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              },
              ...tournCategories?.map((cType) => {
                const currentCheckboxes = [
                  ...mainReportsSettings,
                  ...(cType?.rule === 'kumite'
                    ? [
                        {
                          id: 'grid_modern_view',
                          label: t('modernGrid')
                        }
                      ]
                    : []),
                  {
                    id: 'put_finalist',
                    label: t('addRecord', { name: t('finalists') })
                  },
                  {
                    id: 'grid_order_category',
                    label: t('gridorderCategory')
                  },
                  {
                    id: 'grid_sk_num_off',
                    label: t('disableNumberingOfFights')
                  },
                  {
                    id: 'grid_scale',
                    label: t('scaleByHeight')
                  }
                ];

                return {
                  paramName: `selectedCheckboxes${cType.key}`,
                  name: t('reportCategoryType', { name: cType.name }),
                  onClick: () =>
                    this.exportReportGeneral({
                      api: `/get_tournament_grid_report/${this.tournamentIdParams}/${cType.id}`,
                      activeCheckboxesParam: `selectedCheckboxes${cType.key}`,
                      reportCheckboxes: currentCheckboxes,
                      isApiGet: true
                    }),
                  checkboxes: {
                    additionalSettings: currentCheckboxes
                  },
                  orientation: [
                    { id: 'portrait', label: t('portraitPageOrientation') },
                    { id: 'landscape', label: t('landscapePageOrientation') }
                  ],
                  modal: true
                };
              })
            ]
          : []),
        ...(isRegisterJudges
          ? [
              {
                paramName: `selectedCheckboxesJudges`,
                name: t('listOfJudges'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/get_judges_report/${this.tournamentIdParams}`,
                    activeCheckboxesParam: `selectedCheckboxesJudges`,
                    reportCheckboxes: mainReportsSettings,
                    isApiGet: true
                  }),
                checkboxes: {
                  additionalSettings: mainReportsSettings
                },
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              },
              {
                paramName: `selectedCheckboxesJudgesComposition`,
                name: t('compositionOfTheJury'),
                onClick: () =>
                  this.exportReportGeneral({
                    api: `/get_judges_composition_report/${this.tournamentIdParams}`,
                    activeCheckboxesParam: `selectedCheckboxesJudgesComposition`,
                    reportCheckboxes: mainReportsSettings,
                    isApiGet: true
                  }),
                checkboxes: {
                  additionalSettings: mainReportsSettings
                },
                orientation: [
                  { id: 'portrait', label: t('portraitPageOrientation') },
                  { id: 'landscape', label: t('landscapePageOrientation') }
                ],
                modal: true
              }
            ]
          : [])
      ];

      this.setState((prevState) => ({
        tableData,
        filteredTable: tableData,
        loading: false,
        ...(tournamentData?.category_types && {
          selectedCheckboxesMedalBronze: [...tournamentData.category_types]
        }),
        orientation: {
          selectedCheckboxesRatting: 'landscape',
          selectedCheckboxesTournamentCategories: 'landscape',
          selectedCheckboxesTournamentStatistic: 'landscape',
          selectedCheckboxesJudgesComposition: 'landscape',
          selectedCheckboxesJudges: 'landscape'
        },
        tournamentData: {
          ...prevState.tournamentData,
          ...(tournamentData?.rep_head_h
            ? {
                rep_head_h_preview:
                  serverDefaultPath + tournamentData.rep_head_h
              }
            : {}),
          ...(tournamentData?.rep_head_v
            ? {
                rep_head_v_preview:
                  serverDefaultPath + tournamentData.rep_head_v
              }
            : {})
        }
      }));

      cb && cb();
    });
  };

  saveStateToLocalStorage = () => {
    const {
      selectedCheckboxesTournamentStatistic,
      selectedCheckboxesMedalBronze,
      selectedCheckboxesReportApplicants,
      selectedCheckboxesCategoryDistribution,
      selectedCheckboxesCoachClub,
      selectedCheckboxesParticipants,
      selectedCheckboxesCategories,
      selectedCheckboxesRegistration,
      selectedCheckboxesRunners,
      selectedCheckboxesRatting,
      selectedCheckboxesTournamentCategories,
      selectedCheckboxeskumite,
      selectedCheckboxeskata,
      selectedCheckboxesteam_kata,
      selectedCheckboxestameshiwari,
      selectedCheckboxeskata_by_flags,
      selectedCheckboxespoint_kumite,
      selectedCheckboxesfull_contact,
      selectedCheckboxeskumite_kudo,
      selectedRadioButtonMedalBronze,
      selectedRadioButtonCoachClub,
      selectedOrientation,
      selectedCheckboxesBudget,
      selectedCheckboxesWinners
    } = this.state;

    const stateToSave = {
      selectedCheckboxesTournamentStatistic,
      selectedCheckboxesMedalBronze,
      selectedCheckboxesReportApplicants,
      selectedCheckboxesCategoryDistribution,
      selectedCheckboxesCoachClub,
      selectedCheckboxesParticipants,
      selectedCheckboxesCategories,
      selectedCheckboxesRegistration,
      selectedCheckboxesRunners,
      selectedCheckboxesRatting,
      selectedCheckboxesTournamentCategories,
      selectedCheckboxeskumite,
      selectedCheckboxeskata,
      selectedCheckboxesteam_kata,
      selectedCheckboxestameshiwari,
      selectedCheckboxeskata_by_flags,
      selectedCheckboxespoint_kumite,
      selectedCheckboxesfull_contact,
      selectedCheckboxeskumite_kudo,
      selectedRadioButtonMedalBronze,
      selectedRadioButtonCoachClub,
      selectedOrientation,
      selectedCheckboxesBudget,
      selectedCheckboxesWinners
    };

    localStorage.setItem('reportsSettings', JSON.stringify(stateToSave));
  };

  hideSnackbar = () => this.setState({ showModal: false });

  tableSearch = (evt) => {
    const { value } = evt.target;
    const { tableData } = this.state;
    const filteredTable = tableData.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase().trim())
    );

    this.setState({
      filteredTable,
      searchBar: value
    });
  };

  clearSearch = () => {
    const { tableData } = this.state;

    this.setState({
      searchBar: '',
      filteredTable: tableData
    });
  };

  goToNewPage = (link) => {
    const { navigate, location } = this.props;
    navigate(link, {
      state: { prevUrl: location.pathname }
    });
  };

  onUpdateTourn = (e) => {
    const { tournamentData } = this.state;
    const form = {
      ...tournamentData,
      ...(tournamentData.poster
        ? { imagePreview: serverDefaultPath + tournamentData.poster }
        : {}),
      is_finished: tournamentData?.finished,
      country_id: tournamentData.c_id,
      date: tournamentData.start_date,
      tour_time: tournamentData.start_time,
      tournament_id: tournamentData.id
    };

    this.updateTournament(e, form);
  };

  selectRadioButton = (paramName, newState) => {
    this.setState({ [paramName]: newState });
  };

  toggleVisibility = () => {
    this.setState({ visibility: !this.state.visibility });
  };

  showModalForm = (item) => {
    this.setState({
      activModal: item,
      open: true
    });
  };

  hideModal = () => this.setState({ open: false });

  selectOrientation = (paramName, id) => {
    this.setState((prevState) => ({
      selectedOrientation: {
        ...prevState.orientation,
        [paramName]: id
      }
    }));
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      tournamentData,
      loading,
      filteredTable,
      visibility,
      open,
      activModal,
      searchBar,
      selectedOrientation
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    const useReportHead = !+tournamentData?.report_head;
    const useSignature = !!+tournamentData?.use_rep_signature;

    const mobileScreen = isMobileView(viewportWidth);
    const tabletScreen = isTabletView(viewportWidth);
    const pcScreen = isDesktopView(viewportWidth);

    const titles = [
      {
        title: t('titleForReportsPortrait'),
        id: 'TEST_ID_UPLOAD_REPORTS_PORT',
        description: t('titleDescriptionPortrait'),
        src: PortraitHeader,
        mobileSrc: PortraitHeaderMobile,
        param: 'rep_head_v',
        preview: 'rep_head_v_preview',
        recomendSize: `${t('recommendSize', { size: '1920 x 200' })} `,
        className: classes.cardImgPortrait,
        classNameforBLock: [useReportHead && classes.opacity, classes.column],
        checkboxName: 'report_head'
      },
      {
        title: t('titleForReportsLandscape'),
        id: 'TEST_ID_UPLOAD_REPORTS_LAND',
        description: t('titleDescriptionLandscape'),
        src: LandscapeHeader,
        mobileSrc: LandscapeHeaderMobile,
        param: 'rep_head_h',
        preview: 'rep_head_h_preview',
        recomendSize: `${t('recommendSize', { size: '2880 x 200' })} `,
        className: classes.cardImgLandscape,
        classNameforBLock: [
          classes.marginTop15,
          classes.column,
          useReportHead && classes.opacity
        ],
        checkboxName: 'report_head'
      },
      {
        title: t('judgeSignature'),
        id: 'TEST_ID_JUDGE_SIGNATURE',
        src: SignatureIMG,
        mobileSrc: SignatureIMG,
        param: 'signature_1',
        preview: 'signature_1_preview',
        description: tournamentData?.main_judge,
        className: classes.sizeForSignature,
        classNameforBLock: clsx(
          classes.inlineContainer,
          !mobileScreen ? classes.spaceBetween : classes.column,
          !useSignature && classes.opacity,
          !mobileScreen && classes.marginRight
        ),
        checkboxName: 'use_rep_signature'
      },
      {
        title: t('secretarySignature'),
        id: 'TEST_ID_SECRETARY_SIGNATURE',
        src: SignatureIMG,
        mobileSrc: SignatureIMG,
        param: 'signature_2',
        preview: 'signature_2_preview',
        description: tournamentData?.secretary,
        className: classes.sizeForSignature,
        classNameforBLock: clsx(
          classes.inlineContainer,
          !mobileScreen ? classes.spaceBetween : classes.column,
          !useSignature && classes.opacity
        ),
        checkboxName: 'use_rep_signature'
      }
    ];

    const switchingSignatureAndTitles = [
      {
        label: t('toggleReportHead'),
        value: !+tournamentData?.report_head,
        id: 'report_head'
      },
      {
        label: t('stampOnReports'),
        value: !!+tournamentData?.use_rep_stamp,

        id: 'use_rep_stamp'
      },
      {
        label: t('signatureOnReports'),
        value: !!+tournamentData?.use_rep_signature,
        id: 'use_rep_signature'
      }
    ];

    const checkSettings = activModal?.checkboxes?.additionalSettings;
    const radioSettings = activModal?.radioButtons;
    const orient = activModal?.orientation;

    const dialogContent = (
      <Fragment>
        {checkSettings && (
          <div
            className={clsx(
              classes.flex,
              classes.column,
              classes.marginBottom1_5
            )}>
            <span className={classes.marginBottom1}>{t('turnOn')}</span>
            <div
              className={clsx(
                classes.grid,
                classes.marginLeft025,
                classes.gridTemplateColumns3_2
              )}>
              {checkSettings?.map((item, index) => (
                <Fragment key={index}>
                  <CheckboxBtn
                    checked={Boolean(
                      this.state[activModal.paramName]?.includes(item.id)
                    )}
                    onChange={() =>
                      this.selectCheckbox(activModal.paramName, item.id)
                    }
                    label={item.label}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {radioSettings && (
          <div
            className={clsx(
              classes.flex,
              classes.column,
              classes.marginBottom1_5
            )}>
            <span className={classes.marginBottom1}>{t('groupBy')}</span>
            <div className={clsx(classes.grid, classes.gridTemplateColumns3_2)}>
              {radioSettings?.map((item, index) => (
                <Fragment key={index}>
                  <RadioBtn
                    item={item.id}
                    options={item.id}
                    value={
                      this.state[activModal.paramNameRadio] === item.id &&
                      this.state[activModal.paramNameRadio]
                    }
                    onClick={() =>
                      this.selectRadioButton(activModal.paramNameRadio, item.id)
                    }
                    label={item.label}
                    specificDetails={{
                      className: {
                        radioIcon: classes.radio
                      }
                    }}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {orient && (
          <div
            className={clsx(
              classes.flex,
              classes.column,
              classes.marginBottom1_5
            )}>
            <span className={classes.marginBottom1}>{t('orientation')}</span>
            <div className={clsx(classes.grid, classes.gridTemplateColumns3_2)}>
              {orient?.map((item, index) => (
                <Fragment key={index}>
                  <RadioBtn
                    item={item.id}
                    options={item.id}
                    value={
                      selectedOrientation[activModal.paramName] || 'portrait'
                    }
                    onClick={() =>
                      this.selectOrientation(activModal.paramName, item.id)
                    }
                    label={item.label}
                    className={classes.marginBottom1}
                    specificDetails={{
                      className: {
                        radioIcon: classes.radio
                      }
                    }}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </Fragment>
    );

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{
            open,
            dialogContent
          }}
          subHeader={activModal?.name}
          dialogTitle={t('reportSettings')}
          onClick={() => this.setState({ open: false })}
          buttonPurpose={OK}
          close={this.hideModal}
          classNameBtn={classes.marginLeftAuto}
        />
        {loading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <HeaderTournInfo
              {...{ tournamentData, viewportWidth }}
              shouldShowBtn={true}
            />
            <div
              onClick={this.toggleVisibility}
              className={clsx(
                !visibility ? classes.marginBottom2 : classes.marginBottom1,
                classes.generalSettings
              )}>
              <span>{t('generalReportSettings')}</span>
              <ArrowDropDownIcon className={visibility && classes.rotate180} />
            </div>
            {visibility && (
              <>
                <div className={classes.marginBottom1}>
                  {switchingSignatureAndTitles.map((check, checkIdx) => (
                    <Fragment key={checkIdx}>
                      <CheckboxBtn
                        formControlClassName={classes.marginRight6}
                        label={check.label}
                        onChange={() => this.onSelectCheckboxInHeader(check.id)}
                        checked={check?.value}
                      />
                    </Fragment>
                  ))}
                </div>
                <div className={classes.marginBottom20}>
                  <div
                    className={clsx(
                      tabletScreen && (classes.flex, classes.column)
                    )}>
                    {titles.map((card, cardIdx) => (
                      <div
                        key={cardIdx}
                        className={clsx(
                          classes.flex,
                          classes.paper,
                          card.classNameforBLock
                        )}>
                        <span
                          className={clsx(
                            classes.flex,
                            classes.column,
                            classes.cardTxt
                          )}>
                          <span className={clsx(classes.flex, classes.column)}>
                            <span
                              className={clsx(
                                classes.h1,
                                classes.paddingBottom1
                              )}>
                              {card.title}
                            </span>
                            <span className={clsx(classes.paddingBottom1)}>
                              {card.description}
                            </span>
                          </span>
                        </span>
                        <UploadImage
                          id={card.id}
                          className={card.className}
                          onChange={(evt) =>
                            this.changeImage(
                              evt,
                              'tournamentData',
                              card.param,
                              card.preview
                            )
                          }
                          header={!!tournamentData[card.preview]}
                          img={
                            tournamentData[card.preview] ||
                            (mobileScreen ? card.mobileSrc : card.src)
                          }
                          disabled={
                            card.checkboxName === 'report_head'
                              ? useReportHead
                              : !useSignature
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className={clsx(classes.flex, classes.justifyEnd)}>
                    <Button
                      label={t('save')}
                      isSaveBtn={true}
                      onClick={this.onUpdateTourn}
                      className={classes.btn}
                    />
                  </div>
                </div>
              </>
            )}

            {/* SEARCH INPUT */}
            <SearchInput
              className={classes.marginBottom1}
              onChange={this.tableSearch}
              clearSearch={this.clearSearch}
              value={searchBar}
              isSearchInactive={!searchBar}
            />

            {filteredTable.map((item, index) => (
              <div
                key={item.name}
                className={clsx(
                  classes.flex,
                  classes.wrapper,
                  pcScreen
                    ? [classes.spaceBetween, classes.center]
                    : [classes.column]
                )}>
                <div className={clsx(classes.flex, classes.center)}>
                  <span className={classes.width15}>{index + 1}</span>
                  <img src={ReportIcon} alt="Report Icon" />
                  <span className={classes.reportsLabel}>{item.name}</span>
                </div>
                {item.onClick ? (
                  <div
                    className={clsx(
                      classes.flex,
                      tabletScreen && classes.marginLeft5,
                      mobileScreen && classes.column
                    )}>
                    {item.modal && (
                      <Button
                        label={t('reportSettings')}
                        className={clsx(
                          classes.btnColors,
                          mobileScreen
                            ? [classes.fullWidth, classes.marginBottom1]
                            : classes.maxWidth220
                        )}
                        onClick={() => this.showModalForm(item)}
                      />
                    )}
                    <Button
                      label={t('download')}
                      isSaveBtn={true}
                      className={clsx(
                        mobileScreen
                          ? classes.fullWidth
                          : [classes.maxWidth150, classes.marginLeft2]
                      )}
                      onClick={item.onClick}
                    />
                  </div>
                ) : (
                  <ArrowForwardIcon
                    className={clsx(
                      classes.goBackArrow,
                      !pcScreen && classes.marginLeftAuto
                    )}
                    onClick={() =>
                      this.goToNewPage(item.href + this.tournamentIdParams)
                    }
                  />
                )}
              </div>
            ))}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Reports)));
