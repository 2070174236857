import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'components/withRouter';

import {
  Divider,
  IconButton,
  TextField,
  Box,
  Button,
  ClickAwayListener,
  Popper
} from '@material-ui/core';

import { ReactComponent as CloseWhite } from '../../assets/img/close_white_bg&grey_border.svg';
import { ReactComponent as Exit } from 'assets/img/close_white_bg&grey_border.svg';
import { ReactComponent as PlusGrey } from '../../assets/img/plus_grey_bg&border.svg';
import { ReactComponent as CircleRound } from '../../assets/img/circle_round.svg';
import { ReactComponent as Bronze } from '../../assets/img/thirdPlaceMedal.svg';

import {
  EXTRA_TIME,
  KEEP_EDITING,
  CLOSE_DISCARD,
  MODAL_EDIT_IC,
  RULE_KUMITE,
  TEST_ID_INFO_BTN
} from '../../helpers/constants';
import {
  convertMs,
  setCategoryName,
  selectedValue,
  isDesktopView
} from '../../helpers/selectors';
import {
  fetchCategoriesPerTourn,
  fetchParticipantsByTournamentCategories,
  selectOption,
  distributeParticipantByCategory,
  toggleTatamiCategoryV2,
  updateTournamentCategorySettings,
  fetchKataNames,
  exportReportGeneral,
  downloadFile,
  textChangeHandler,
  updateCategoryGroup,
  updateCategory,
  changeModal,
  fetchParticipantsByTournamentV2
} from '../../helpers/util';

import Filter from '../../components/Filter/Filter';
import CategoriesBlock from '../../components/AddTournamentCategories/AddTournamentCategories';
import Modal from '../../components/Modal/ModalNewDesign';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';

import styles from './style';

const STATISTICS_DETAILS = (t, statistics, types) => ({
  title: t('statisticsByCategory'),
  state: 'statisticsByCategory',
  styles: { list: { display: 'grid', gridTemplateColumns: '1fr 1fr' } },
  info: [
    {
      label: t('categoriesTotal', { name: '' }),
      name: statistics.assignedCount
    },
    {
      label: t('categoriesUsed'),
      name: statistics.filledCount
    },
    ...[].concat(
      ...types.map((it) => [
        ...(statistics.types[`assignedCount_${it.id}`] > 0
          ? [
              {
                label: t('categoriesTotal', { name: it.name.toUpperCase() }),
                name: statistics.types[`assignedCount_${it.id}`]
              },
              {
                label: t('categoriesUsed'),
                name: statistics.types[`filledCount_${it.id}`]
              }
            ]
          : [])
      ])
    ),
    {
      label: t('redCategories'),
      name: statistics.issueCount
    }
  ]
});

const TOGGLE_CATEGORY_STATUS = 'TOGGLE_CATEGORY_STATUS';
const MODAL_EDIT_CATEGORY_IC = 'MODAL_EDIT_CATEGORY_IC';

class TournamentAddCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tournamentData: props.tournamentData || {},
      categoryTypes: props?.categoryTypes ?? [],
      filteredCategories: [],
      kata_names: [],
      selectedCheckboxes: [],
      errors: {},
      role: localStorage.getItem('role'),
      langOnLoad: localStorage.getItem('i18nextLng'),
      loggedUserId: localStorage.getItem('user_id'),
      open: false,
      modalData: {},
      searchBar: '',
      isSending: false,
      loading: true,
      anchorEl: null
    };

    this.fetchCategoriesPerTourn = fetchCategoriesPerTourn.bind(this);
    this.distributeParticipantByCategory =
      distributeParticipantByCategory.bind(this);
    this.fetchParticipantsByTournamentCategories =
      fetchParticipantsByTournamentCategories.bind(this);
    this.toggleTatamiCategoryV2 = toggleTatamiCategoryV2.bind(this);
    this.updateTournamentCategorySettings =
      updateTournamentCategorySettings.bind(this);
    this.fetchKataNames = fetchKataNames.bind(this);
    this.selectOption = selectOption.bind(this);
    this.exportReportGeneral = exportReportGeneral.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.updateCategoryGroup = updateCategoryGroup.bind(this);
    this.updateCategory = updateCategory.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchParticipantsByTournamentV2 =
      fetchParticipantsByTournamentV2.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad, statistics } = this.state;
    const { categoryTypes, t, onGetPageHeaderStatistics } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      onGetPageHeaderStatistics(
        STATISTICS_DETAILS(t, statistics, categoryTypes)
      );

      this.setState({ langOnLoad: currentLang });
    }

    if (prevProps?.categoryTypes !== categoryTypes) {
      this.setState({ categoryTypes: categoryTypes });
    }
  }

  componentDidMount() {
    this.fetchKataNames();
    this.fetchMainDataTournCategories(true);
  }

  fetchMainDataTournCategories = (isFilteringUnneeded, successMsg) => {
    const { tournamentId, onGetPageHeaderStatistics, t } = this.props;

    this.fetchCategoriesPerTourn(
      tournamentId,
      () => {
        const { categories, categoryTypes } = this.state;

        const len = categories.length;
        let newCategories = [];
        let filteredCategories = [];

        const countForStatistics = this.initializeStatistics(categoryTypes);

        for (let i = 0; i < len; i++) {
          // Initialize allBronzeEnabled/allCircleEnabled to true, to check later if it needs to be false
          let generalStettings = {
            allBronzeEnabled: true,
            allCircleEnabled: true
          };

          const category = categories[i];
          let presentCategory = {};

          if (category.weight_f) {
            const weightFLen = category.weight_f.length;

            for (let j = 0; j < weightFLen; j++) {
              const weight_f = category.weight_f[j];

              if (!!+weight_f.tournament_presence) {
                generalStettings.hasSelectedCategory = true;

                if (!+weight_f?.bronze_fight_enabled) {
                  generalStettings.allBronzeEnabled = false;
                }

                if (!+weight_f?.circle_round) {
                  generalStettings.allCircleEnabled = false;
                }

                // Absolute Category
                const hasSameCategory = category.weight_t?.some(
                  (t) => +weight_f.category_id === +t.category_id
                );

                if (!hasSameCategory) {
                  presentCategory = this.updateNewCateoryTime(weight_f);
                  countForStatistics.assignedCount += 1;
                  countForStatistics.types[
                    `assignedCount_${category.type}`
                  ] += 1;

                  if (!!+weight_f.participants_count) {
                    countForStatistics.filledCount += 1;
                    countForStatistics.types[
                      `filledCount_${category.type}`
                    ] += 1;
                  }

                  if (+weight_f.participants_count === 1) {
                    countForStatistics.issueCount += 1;
                  }
                }
              }
            }
          }

          if (category.weight_t) {
            const weightTLen = category.weight_t.length;

            for (let j = 0; j < weightTLen; j++) {
              const weight_t = category.weight_t[j];

              if (!!+weight_t.tournament_presence) {
                generalStettings.hasSelectedCategory = true;

                if (!+weight_t?.bronze_fight_enabled) {
                  generalStettings.allBronzeEnabled = false;
                }

                if (!+weight_t?.circle_round) {
                  generalStettings.allCircleEnabled = false;
                }

                presentCategory = this.updateNewCateoryTime(weight_t);
                countForStatistics.assignedCount += 1;
                countForStatistics.types[`assignedCount_${category.type}`] += 1;

                if (!!+weight_t.participants_count) {
                  countForStatistics.filledCount += 1;
                  countForStatistics.types[`filledCount_${category.type}`] += 1;
                }

                if (+weight_t.participants_count === 1) {
                  countForStatistics.issueCount += 1;
                }
              }
            }
          }

          if (category.weight_f || category.weight_t) {
            // If no categories are selected (no `tournament_presence: 1`), set allBronzeEnabled/allCircleEnabled to false
            if (!generalStettings.hasSelectedCategory) {
              generalStettings = {
                allBronzeEnabled: false,
                allCircleEnabled: false
              };
            }

            // Set the final value for bronze_fight_enabled/circle_round
            presentCategory = {
              ...presentCategory,
              bronze_fight_enabled: generalStettings.allBronzeEnabled
                ? '1'
                : '0',
              circle_round: generalStettings.allCircleEnabled ? '1' : '0'
            };
          }

          if (!!+category?.kata?.tournament_presence) {
            this.updateKataCounts(category.kata, category, countForStatistics);

            const findCurrentType = categoryTypes?.find(
              (type) => +type?.id === +category?.type
            );

            if (findCurrentType?.rule === RULE_KUMITE) {
              presentCategory = {
                bronze_fight_enabled: category?.kata?.bronze_fight_enabled,
                circle_round: category?.kata?.circle_round
              };
            }
          }

          newCategories = [
            ...newCategories,
            Object.keys(presentCategory).length !== 0
              ? { ...category, ...presentCategory }
              : category
          ];
        }

        this.setState({ statistics: countForStatistics }, () => {
          const { statistics } = this.state;
          onGetPageHeaderStatistics(
            STATISTICS_DETAILS(t, statistics, categoryTypes)
          );
        });

        if (isFilteringUnneeded) {
          filteredCategories = [...newCategories];

          this.setState({
            loading: false,
            filteredCategories,
            categories: newCategories
          });
        } else {
          //using setState here when adding new KUMITE will show input to set time
          this.setState({ loading: false, categories: newCategories }, () =>
            this.onSelectCheckbox({})
          );
        }
      },
      successMsg
    );
  };

  initializeStatistics = (categoryTypes) => {
    return {
      assignedCount: 0,
      filledCount: 0,
      issueCount: 0,
      types: categoryTypes.reduce((acc, it) => {
        acc[`assignedCount_${it.id}`] = 0;
        acc[`filledCount_${it.id}`] = 0;
        return acc;
      }, {})
    };
  };

  updateNewCateoryTime = (category) => {
    const handleEmptyArray = (array) => {
      // If it's an empty array or a string representing an empty array, return [0]
      if (Array.isArray(array) && array.length === 0) {
        return [];
      }

      // If it's a string representing an array (e.g., "[0]"), parse it into an array
      if (typeof array === 'string') {
        try {
          return JSON.parse(array);
        } catch (e) {
          return [];
        }
      }

      return array || [];
    };

    return {
      //  main_1_times_ms array
      main_1_times_ms: handleEmptyArray(category.main_1_times_ms),
      main_1_times_ms_in_sec: handleEmptyArray(category.main_1_times_ms).map(
        (ms) => (ms < 0 ? ms : ms / 1000)
      ),

      // main_2_times_ms array
      main_2_times_ms: handleEmptyArray(category.main_2_times_ms),
      main_2_times_ms_in_sec: handleEmptyArray(category.main_2_times_ms).map(
        (ms) => (ms < 0 ? ms : ms / 1000)
      ),

      //  final_times_ms array
      final_times_ms: handleEmptyArray(category.final_times_ms),
      final_times_ms_in_sec: handleEmptyArray(category.final_times_ms).map(
        (ms) => (ms < 0 ? ms : ms / 1000)
      )
    };
  };

  updateKataCounts = (kata, category, countForStatistics) => {
    countForStatistics.assignedCount += 1;
    countForStatistics.types[`assignedCount_${category.type}`] += 1;

    if (+kata.participants_count !== 0 && kata.participants_count !== '0/0') {
      countForStatistics.filledCount += 1;
      countForStatistics.types[`filledCount_${category.type}`] += 1;
    }

    if (+kata.participants_count === 1 || kata.participants_count === '1/1') {
      countForStatistics.issueCount += 1;
    }
  };

  normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, ' ').trim();
  };

  //will perform both the filter and search action at the same time, when selecting a filter or when searching
  onTxtSearch = (value, categories) => {
    const { tournamentData } = this.state;

    let newCategories = [];
    const len = categories && categories.length;

    for (let i = 0; i < len; i++) {
      const item = categories[i];
      const baseName = setCategoryName(item, tournamentData);
      const blockName = `${baseName?.name} (${baseName?.gName})`;

      if (
        [blockName, item?.g_alt_name, item.g_name]
          .filter(Boolean)
          .some((it) =>
            this.normalizeString(it).includes(this.normalizeString(value))
          )
      ) {
        newCategories = [...newCategories, item];
      }
    }

    return newCategories;
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox({}));
  };

  onToggleSave = (currentGroup, elIdx, cb) => {
    let categoriesIds = [];
    let categoryGroup = { ...currentGroup };

    //take all our needed categories (from weight from, weight to and kata)
    if (!!categoryGroup?.kata) {
      const toggledStatus = !categoryGroup.kata.tournament_presence;
      categoryGroup.kata.tournament_presence = +toggledStatus;
      categoriesIds = [
        ...categoriesIds,
        {
          category_id: categoryGroup.kata.category_id,
          category_n: categoryGroup.kata.name,
          presence: categoryGroup.kata.tournament_presence
        }
      ];
    } else if (!!categoryGroup.weight_t) {
      //check if weight from and weight to are not null
      const toggledStatus = !categoryGroup.weight_t[elIdx].tournament_presence;
      categoryGroup.weight_t[elIdx].tournament_presence = +toggledStatus;
      categoriesIds = [
        ...categoriesIds,
        {
          category_id: categoryGroup.weight_t[elIdx].category_id,
          category_n: categoryGroup.weight_t[elIdx].name,
          presence: categoryGroup.weight_t[elIdx].tournament_presence
        }
      ];

      if (categoryGroup.weight_f) {
        let num = categoryGroup.weight_t?.length;
        let greatestMinusCategoryPresenceIdx;
        // weight_to length is equal t weight_f, since the second is mirror of the first

        while (num--) {
          if (
            !greatestMinusCategoryPresenceIdx &&
            !!+categoryGroup?.weight_t?.[num]?.tournament_presence
          ) {
            greatestMinusCategoryPresenceIdx = num;
            categoryGroup.weight_f[num].tournament_presence = 1;
            categoriesIds = [
              ...categoriesIds,
              {
                category_id: categoryGroup.weight_f[num].category_id,
                category_n: categoryGroup.weight_f[num].name,
                presence: categoryGroup.weight_f[num].tournament_presence
              }
            ];
          } else if (!!+categoryGroup?.weight_f?.[num]?.tournament_presence) {
            categoryGroup.weight_f[num].tournament_presence = 0;
            categoriesIds = [
              ...categoriesIds,
              {
                category_id: categoryGroup.weight_f[num].category_id,
                category_n: categoryGroup.weight_f[num].name,
                presence: categoryGroup.weight_f[num].tournament_presence
              }
            ];
          }
        }
      }
    }

    cb && cb(categoryGroup, categoriesIds);
  };

  addCategorieshandler = (e, currentGroup, el, elIdx) => {
    const { categories, filteredCategories } = this.state;
    const { tournamentId } = this.props;
    let cpyCategories = [...categories];
    let cpyFilteredCategories = [...filteredCategories];

    if (!+el.participants_count) {
      this.onToggleSave(currentGroup, elIdx, (categoryGroup, categoriesIds) => {
        this.toggleTatamiCategoryV2(
          {
            tournament_id: tournamentId,
            category_presence_list: categoriesIds
          },
          () => {
            let findCategoryIdx, findFilteredCategoryIdx;

            if (!!categoryGroup?.kata) {
              // KATA
              findCategoryIdx = categories?.findIndex((it) => {
                return +it?.kata?.category_id === +el?.category_id;
              });
              findFilteredCategoryIdx = cpyFilteredCategories?.findIndex(
                (it) => {
                  return +it?.kata?.category_id === +el?.category_id;
                }
              );

              if (findCategoryIdx !== -1) {
                cpyCategories[findCategoryIdx] = categoryGroup;
              }

              if (findFilteredCategoryIdx !== -1) {
                cpyFilteredCategories[findFilteredCategoryIdx] = categoryGroup;
              }
            } else if (!!categoryGroup?.weight_t) {
              // KUMITE
              findCategoryIdx = categories?.findIndex((it) => {
                return +it?.weight_t?.[elIdx]?.category_id === +el?.category_id;
              });
              findFilteredCategoryIdx = cpyFilteredCategories?.findIndex(
                (it) => {
                  return (
                    +it?.weight_t?.[elIdx]?.category_id === +el?.category_id
                  );
                }
              );

              if (findCategoryIdx !== -1) {
                cpyCategories[findCategoryIdx] = categoryGroup;
              }

              if (findFilteredCategoryIdx !== -1) {
                cpyFilteredCategories[findFilteredCategoryIdx] = categoryGroup;
              }

              this.setState(
                {
                  categories: cpyCategories,
                  filteredCategories: cpyFilteredCategories
                },
                () => {
                  const { categories } = this.state;
                  const { onGetPageHeaderStatistics, categoryTypes, t } =
                    this.props;

                  const len = categories.length;
                  let newCategories = [];

                  const countForStatistics =
                    this.initializeStatistics(categoryTypes);

                  for (let i = 0; i < len; i++) {
                    const category = categories[i];
                    let presentCategory = {};

                    if (category.weight_f) {
                      const weightFLen = category.weight_f.length;

                      for (let j = 0; j < weightFLen; j++) {
                        const weight_f = category.weight_f[j];

                        if (!!+weight_f.tournament_presence) {
                          // Absolute Category
                          const hasSameCategory = category.weight_t?.some(
                            (t) => +weight_f.category_id === +t.category_id
                          );

                          if (!hasSameCategory) {
                            // Update the category's time and counts
                            presentCategory =
                              this.updateNewCateoryTime(weight_f);

                            countForStatistics.assignedCount += 1;
                            countForStatistics.types[
                              `assignedCount_${category.type}`
                            ] += 1;

                            if (!!+weight_f.participants_count) {
                              countForStatistics.filledCount += 1;
                              countForStatistics.types[
                                `filledCount_${category.type}`
                              ] += 1;
                            }

                            if (+weight_f.participants_count === 1) {
                              countForStatistics.issueCount += 1;
                            }
                          }
                        }
                      }
                    }

                    // Handle weight_t categories
                    if (category.weight_t) {
                      const weightTLen = category.weight_t.length;

                      for (let j = 0; j < weightTLen; j++) {
                        const weight_t = category.weight_t[j];

                        if (!!+weight_t.tournament_presence) {
                          // Update the category's time and counts
                          presentCategory = this.updateNewCateoryTime(weight_t);

                          countForStatistics.assignedCount += 1;
                          countForStatistics.types[
                            `assignedCount_${category.type}`
                          ] += 1;

                          if (!!+weight_t.participants_count) {
                            countForStatistics.filledCount += 1;
                            countForStatistics.types[
                              `filledCount_${category.type}`
                            ] += 1;
                          }

                          if (+weight_t.participants_count === 1) {
                            countForStatistics.issueCount += 1;
                          }
                        }
                      }
                    }

                    // Handle kata categories
                    if (!!+category?.kata?.tournament_presence) {
                      this.updateKataCounts(
                        category.kata,
                        category,
                        countForStatistics
                      );

                      const findCurrentType = categoryTypes?.find(
                        (type) => +type?.id === +category?.type
                      );

                      if (findCurrentType?.rule === RULE_KUMITE) {
                        presentCategory = {
                          bronze_fight_enabled:
                            category?.kata?.bronze_fight_enabled,
                          circle_round: category?.kata?.circle_round
                        };
                      }
                    }

                    newCategories = [
                      ...newCategories,
                      Object.keys(presentCategory).length !== 0
                        ? { ...category, ...presentCategory }
                        : category
                    ];
                  }

                  this.setState(
                    {
                      statistics: countForStatistics,
                      categories: newCategories
                    },
                    () => {
                      this.onSelectCheckbox({});

                      const { statistics } = this.state;
                      onGetPageHeaderStatistics(
                        STATISTICS_DETAILS(t, statistics, categoryTypes)
                      );
                    }
                  );
                }
              );
            }
          }
        );
      });
    } else {
      this.setState(
        {
          open: true,
          pressedIcon: TOGGLE_CATEGORY_STATUS,
          modalData: { form_title_name: el.category_name }
        },
        () => {
          this.fetchParticipantsByTournamentCategories(
            tournamentId,
            { empty_categories: 1 },
            (distributeCategories) => {
              const findCurrentCategory = distributeCategories?.find(
                (it) => +it.id === +el.category_id
              );
              this.setState((prev) => ({
                modalData: {
                  ...prev.modalData,
                  allCategoriesForDistribution: distributeCategories,
                  categoryGroup: currentGroup,
                  currentCategory: {
                    ...el,
                    elIdx,
                    participants: findCurrentCategory?.participants
                  }
                }
              }));
            }
          );
        }
      );
    }
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const { tournamentData } = this.props;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      let filteredCategories = [];

      if (checkedValues.length > 0) {
        let genderArray = [];
        let ageArray = [];
        let typeArray = [];
        let ownerList = [];
        let assignList = [];

        checkedValues.map((it) => {
          if (it.gender) genderArray = [...genderArray, it.gender];
          if (it.age) ageArray = [...ageArray, it.age];
          if (it.type) typeArray = [...typeArray, it.id];
          if (it.owner) ownerList = [...ownerList, it.owner];
          if (it.tournament_presence)
            assignList = [...assignList, it.tournament_presence];
          return true;
        });

        const categoriesLen =
          this.state.categories && this.state.categories.length;

        for (let i = 0; i < categoriesLen; i++) {
          const category = this.state.categories[i];

          const filterByAssignList =
            assignList.length > 0
              ? assignList.some((el) => {
                  if (category.kata) {
                    return +category.kata.tournament_presence === +el;
                  } else if (category.weight_t) {
                    if (+el === 0) {
                      return category.weight_t.every(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    } else if (+el === 1) {
                      return category.weight_t.some(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    }
                  } else {
                    return false;
                  }
                })
              : category;

          const filterByGenderList =
            genderArray.length > 0
              ? genderArray.some((el) => category.gender === el)
              : category;

          const filterByAgeList =
            ageArray.length > 0
              ? ageArray.some((el) =>
                  category.age_to
                    ? +category.age_from >= el[0] && +category.age_to <= el[1]
                    : +category.age_from >= el[0] && +category.age_from <= el[1]
                )
              : category;

          const filterByTypeList =
            typeArray.length > 0
              ? typeArray.some((el) => +category?.type === +el)
              : category;

          const filterByOwnerList =
            ownerList.length > 0
              ? ownerList.some(
                  (el) =>
                    // master_id === 1 (is Admin)
                    (+el === 0 &&
                      (+category.master_id === 1 || !category.master_id)) ||
                    (+el === 1 &&
                      +category.master_id === +tournamentData?.organizer_id &&
                      +category.master_id !== 1)
                )
              : category;

          if (
            filterByGenderList &&
            filterByAgeList &&
            filterByTypeList &&
            filterByOwnerList &&
            filterByAssignList
          ) {
            filteredCategories = [...filteredCategories, category];
          }
        }
      } else filteredCategories = [...this.state.categories];

      filteredCategories = this.onTxtSearch(
        this.state.searchBar,
        filteredCategories
      );

      this.setState({
        filteredCategories,
        errors:
          {} /*discard errors when filtering or searching because we show the default value of the time input*/
      });
    });
  };

  cancelFilter = () => {
    this.setState({
      filteredCategories: [...this.state.categories],
      selectedCheckboxes: []
    });
  };

  onTimeEdit = (time, val, param, index) => {
    const { errors, modalData } = this.state;
    const { t } = this.props;
    let copyErrors = { ...errors };
    let copyModalData = { ...modalData };
    let newTime;
    const updatedTimesArray = [...copyModalData[param]];

    if (time === null) {
      copyErrors[param] = t('required');
    } else if (isNaN(time)) {
      copyErrors[param] = t('invalidTime');
    } else {
      delete copyErrors[param];

      //use Hrs as min, min as sec
      newTime = (time.getHours() * 60 + time.getMinutes()) * 1000;
    }

    updatedTimesArray[index] = newTime;
    copyModalData = {
      ...copyModalData,
      [param]: updatedTimesArray,
      [`${param}_in_sec`]: updatedTimesArray.map((ms) =>
        ms < 0 ? ms : ms / 1000
      )
    };

    this.setState({
      errors: copyErrors,
      modalData: copyModalData
    });
  };

  onChangeTxt = (event, param, index) => {
    const { modalData } = this.state;
    let copyModalData = { ...modalData };
    const newValue = event.target.value;

    const updatedTimesArray = [...copyModalData[param]];
    updatedTimesArray[index] = '-' + newValue || '';

    copyModalData = {
      ...copyModalData,
      [param]: updatedTimesArray,
      [`${param}_in_sec`]: updatedTimesArray
    };

    this.setState({
      modalData: copyModalData
    });
  };

  removeTime = (param, index) => {
    const { modalData } = this.state;
    let copyModalData = { ...modalData };

    const updatedTimesArray = [...copyModalData[param]];

    updatedTimesArray.splice(index, 1);

    copyModalData = {
      ...copyModalData,
      [param]: updatedTimesArray,
      [`${param}_in_sec`]: updatedTimesArray
    };

    this.setState({
      modalData: copyModalData
    });
  };

  onTogglePopperCheckbox = (elem) => {
    const { name, value } = elem;
    const { modalData } = this.state;
    let copyModalData = { ...modalData };

    const currentArray = copyModalData[name];

    copyModalData[name] = [
      ...currentArray,
      ...(currentArray?.length === 0 && `${value}`.includes('-')
        ? [0, value]
        : [value])
    ];
    copyModalData[`${name}_in_sec`] = copyModalData[name].map((ms) =>
      ms < 0 ? ms : ms / 1000
    );

    this.setState({
      modalData: copyModalData
    });

    this.onClosePopper();
  };

  txtChange = (evt) => {
    const { modalData } = this.state;
    const { name, value } = evt.target;
    let copyModalData = { ...modalData };

    copyModalData.kata = {
      ...copyModalData.kata,
      [name]: value
    };

    this.setState({
      modalData: copyModalData
    });
  };

  onToggleCheckbox = (evt, name) => {
    const { checked } = evt.target;

    this.setState((prev) => ({
      modalData: {
        ...prev.modalData,
        [name]: checked
      }
    }));
  };

  showModal = (evt, item, currentPressedIcon) => {
    evt.preventDefault();
    const { categoryTypes } = this.state;

    const findCurrentType = categoryTypes?.find(
      (type) => +type?.id === +item?.type
    );
    let newData = {
      pressedIcon: currentPressedIcon,
      open: true
    };

    if (currentPressedIcon === MODAL_EDIT_CATEGORY_IC) {
      this.setState((prevState) => ({
        ...newData,
        modalData: {
          ...prevState.modalData,
          ...item,
          rule: findCurrentType?.rule,
          form_title_name: item?.category_name ?? item.name
        }
      }));
    } else if (currentPressedIcon !== TEST_ID_INFO_BTN) {
      let groupedRounds = {};
      let kataRounds = [];

      if (item?.kata) {
        // group by round
        [...(item?.kata.kata_round_names ?? [])].forEach((el) => {
          if (!groupedRounds[el.round]) {
            groupedRounds[el.round] = {
              round: el.round,
              name_ids: []
            };
          }

          groupedRounds[el.round].name_ids.push(el.kata_id);
        });

        const getRecordsValues = Object.values(groupedRounds);

        // Map over kata_rounds and merge with groupedRounds
        kataRounds =
          [...(item?.kata.kata_rounds ?? [])]?.map((el, elIdx) => ({
            count: el,
            ...(getRecordsValues[elIdx] ?? { round: elIdx + 1, name_ids: [] }) // Use default if exists
          })) ?? [];
      }

      this.setState((prevState) => ({
        ...newData,
        modalData: {
          ...prevState.modalData,
          ...item,
          kataRounds,
          group_category_name: item?.g_name,
          group_category_alt_name: item?.g_alt_name,
          group_category_age_from: item?.age_from,
          group_category_age_to: item?.age_to,
          group_category_gender: item?.gender,
          group_category_type: +item?.type,
          ...(!+item.group_id
            ? {
                category_id: findCurrentType.weighted
                  ? item?.weight_f?.[0]?.category_id
                  : item?.kata?.category_id
              }
            : {}),
          category_type_name: findCurrentType?.name,
          rule: findCurrentType?.rule,
          form_title_name: item?.g_name
        }
      }));
    } else {
      this.onFilterParticipantsBySelectedCategory({
        ...item,
        rule: findCurrentType?.rule
      });
    }
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        shouldShowDiscardChanges: false,
        modalData: {},
        errors: {},
        isEditing: false
      });
    }
  };

  onFilterParticipantsBySelectedCategory = (item) => {
    const { tournamentId } = this.props;

    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      tournamentId,
      null,
      (data) => {
        this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            ...item,
            participants: data.data
          },
          open: true,
          pressedIcon: TEST_ID_INFO_BTN
        }));
      },
      item.category_id,
      1,
      13
    );
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox({}));
  };

  hideSnackbar = () => {
    this.setState({ showModal: false });
  };

  onSaveModalForm = (evt) => {
    const { modalData, loggedUserId } = this.state;
    const { tournamentId, tournamentData } = this.props;
    let categoriesList = [];
    let promises = [];

    if (modalData?.weight_t && modalData?.weight_f) {
      const weightFLen = modalData.weight_f?.length;
      const weightTLen = modalData.weight_t?.length;

      for (let i = 0; i < weightFLen; i++) {
        if (modalData.weight_f[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_f[i].category_id
          ];
        }
      }

      for (let i = 0; i < weightTLen; i++) {
        if (modalData.weight_t[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_t[i].category_id
          ];
        }
      }
    } else if (
      modalData?.rule === RULE_KUMITE &&
      !!+modalData?.kata?.tournament_presence
    ) {
      categoriesList = [modalData?.kata?.category_id];
    }

    if (modalData?.weight_t && modalData?.weight_f) {
      promises.push(
        new Promise((resolve) =>
          this.updateTournamentCategorySettings(
            evt,
            {
              tournamentId,
              categoryIds: categoriesList,
              main_1_times: modalData?.main_1_times_ms,
              main_2_times: modalData?.main_2_times_ms,
              final_times: modalData?.final_times_ms,
              bronze: modalData?.bronze_fight_enabled,
              circle: modalData?.circle_round
            },
            () => resolve(true)
          )
        )
      );
    }

    if (modalData?.kata) {
      if (!!+modalData?.kata?.tournament_presence && +modalData.type !== 4) {
        promises.push(
          new Promise((resolve) =>
            this.updateTournamentCategorySettings(
              evt,
              {
                tournamentId,
                categoryIds: [modalData.category_id],
                kataRounds: modalData?.kataRounds,
                ...(modalData?.rule === RULE_KUMITE
                  ? {
                      bronze: modalData?.bronze_fight_enabled,
                      circle: modalData?.circle_round
                    }
                  : {})
              },
              () => resolve(true)
            )
          )
        );
      }
    }

    if (
      +modalData?.master_id === +loggedUserId &&
      !!+tournamentData.use_alt_category_name
    ) {
      if (!!+modalData?.group_id) {
        promises.push(
          new Promise((resolve) =>
            this.updateCategoryGroup(
              evt,
              {
                ...modalData,
                id: modalData.group_id
              },
              () => resolve(true)
            )
          )
        );
      } else {
        promises.push(
          new Promise((resolve) =>
            this.updateCategory(
              evt,
              { ...modalData, id: modalData?.category_id },
              () => resolve(true)
            )
          )
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        this.triggerUpdate();
      })
      .catch((err) => console.error(err));
  };

  triggerUpdate = () => {
    this.fetchMainDataTournCategories();
    this.hideModal();
  };

  onSaveDistribution = () => {
    const { tournamentId } = this.props;
    const { modalData } = this.state;

    const participantsIds = modalData?.currentCategory?.participants?.map(
      (el) => el.id
    );

    this.distributeParticipantByCategory(
      tournamentId,
      participantsIds,
      modalData.transfer2Category,
      null,
      modalData?.currentCategory?.category_id,
      (result) => {
        this.onToggleSave(
          modalData?.categoryGroup,
          modalData?.currentCategory?.elIdx,
          (categoryGroup, categoriesIds) => {
            this.toggleTatamiCategoryV2(
              {
                tournament_id: tournamentId,
                category_presence_list: categoriesIds
              },
              () => {
                this.fetchMainDataTournCategories(null, true);
                this.hideModal();
              }
            );
          }
        );
      },
      true
    );
  };

  onOpenPopper = (evt) => {
    const { anchorEl } = this.state;

    this.setState({ anchorEl: anchorEl ? null : evt.currentTarget });
  };

  onClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  addNewKataRound = () => {
    const { modalData } = this.state;

    const kataRounds = modalData.kataRounds || [];
    const newRoundNumber = kataRounds.length + 1;

    const newRound = {
      round: newRoundNumber,
      count: '0',
      name_ids: []
    };

    const updatedKataRounds = [...kataRounds, newRound];

    this.setState({
      modalData: {
        ...modalData,
        kataRounds: updatedKataRounds
      }
    });
  };

  undoLastKataRound = () => {
    const { modalData } = this.state;
    const kataRounds = modalData.kataRounds || [];

    if (kataRounds.length === 0) return;

    // remove the last element
    const updatedKataRounds = kataRounds.slice(0, kataRounds.length - 1);

    this.setState({
      modalData: {
        ...modalData,
        kataRounds: updatedKataRounds
      }
    });
  };

  onChangeRoundTxt = (e, roundNumber) => {
    const { value } = e.target;
    const { modalData } = this.state;

    let copyModalData = { ...modalData };
    const updatedKataRounds = copyModalData.kataRounds.map((round) => {
      if (round.round === roundNumber) {
        return {
          ...round,
          count: value
        };
      }
      return round;
    });

    this.setState({
      modalData: {
        ...copyModalData,
        kataRounds: updatedKataRounds
      }
    });
  };

  onSelectKataNames = (kataNameId, round) => {
    const { modalData } = this.state;
    let copyModalData = { ...modalData };

    const updatedKataRounds = copyModalData.kataRounds.map((item) => {
      if (+item.round === +round) {
        const updatedNameIds = item.name_ids.some(
          (kataId) => +kataId === +kataNameId
        )
          ? item.name_ids.filter((id) => +id !== +kataNameId)
          : [...item.name_ids, kataNameId];

        return {
          ...item,
          name_ids: updatedNameIds
        };
      }

      return item;
    });

    this.setState({
      modalData: {
        ...copyModalData,
        kataRounds: updatedKataRounds
      }
    });
  };

  render() {
    const {
      filteredCategories,
      categoryTypes,
      selectedCheckboxes,
      errors,
      kata_names,
      open,
      shouldShowDiscardChanges,
      pressedIcon,
      searchBar,
      loading,
      isSending,
      success,
      showModal,
      loggedUserId,
      modalData,
      modalInfo,
      role,
      anchorEl
    } = this.state;

    const {
      t,
      classes,
      shouldDisableEditing,
      tournamentId,
      viewportWidth,
      tournamentData,
      navigate,
      location
    } = this.props;
    const hasDesktopSize = isDesktopView(viewportWidth);

    let onClick,
      dialogTitle,
      shouldShowTopBottomDivider,
      buttonPurpose,
      classNameBtn,
      dialogContent,
      subHeader;

    const BronzeCircleRound = () => (
      <div
        style={{
          gap: '1rem',
          display: 'flex',
          flexWrap: 'wrap'
        }}>
        <CheckboxBtn //set bronze round (Kumite)
          classControlLabel={classes.marginRight0}
          checked={!!+modalData.bronze_fight_enabled}
          onChange={(e) => this.onToggleCheckbox(e, 'bronze_fight_enabled')}
          label={
            <span className={clsx(classes.flex, classes.centerVertically)}>
              {t('enableBronzeFight')}
              <Bronze className={classes.marginLeft08} />
            </span>
          }
        />
        <CheckboxBtn //set circle round (Kumite)
          classControlLabel={classes.marginRight0}
          checked={!!+modalData.circle_round}
          onChange={(e) => this.onToggleCheckbox(e, 'circle_round')}
          label={
            <span className={clsx(classes.flex, classes.centerVertically)}>
              {t('roundedRound')}
              <CircleRound className={classes.marginLeft08} />
            </span>
          }
        />
      </div>
    );

    if (pressedIcon === MODAL_EDIT_IC) {
      dialogTitle = t('generalCategorySettings');
      dialogContent = (
        <div style={{ display: 'grid', gridRowGap: '1rem' }}>
          {(+modalData?.master_id === +loggedUserId ||
            role === 'administrator') &&
            !!+tournamentData.use_alt_category_name &&
            modalData?.group_category_alt_name && (
              <>
                <div>
                  <h5>
                    {[t('alternative'), `${t('nameCategoriesForm')}`].join(' ')}
                  </h5>
                  <Divider />
                </div>
                <TextField
                  value={modalData.group_category_alt_name || ''}
                  name={'group_category_alt_name'}
                  label={[
                    t('alternative'),
                    t('nameCategoriesForm').toLowerCase()
                  ].join(' ')}
                  onChange={(evt) => this.textChangeHandler(evt, 'modalData')}
                  InputLabelProps={{
                    classes: {
                      shrink: classes.fullWidth
                    }
                  }}
                  variant="outlined"
                />
              </>
            )}
          {modalData?.weight_t &&
            modalData?.weight_f &&
            EXTRA_TIME(t).map((timeElem, timeElemIdx) => {
              return (
                <div key={timeElemIdx}>
                  <span>
                    <h5 style={{ paddingRight: '0.5rem', display: 'inline' }}>
                      {' '}
                      {t('time')}:
                    </h5>
                    {timeElem.title}
                  </span>
                  <div
                    style={{
                      margin: '0 -0.5rem',
                      display: 'flex',
                      flexWrap: 'wrap'
                    }}>
                    {(modalData[timeElem.param]?.length > 0
                      ? modalData[timeElem.param]
                      : [0]
                    ).map((timeVal, timeIdx) => {
                      const isNegative = `${timeVal}`.includes('-'); // there can be cases like '-' or '-number'

                      const formattedTime = isNegative
                        ? Math.abs(timeVal) || ''
                        : timeVal;
                      const convertedTime = convertMs(formattedTime);
                      const resultTime = [
                        convertedTime.minutes,
                        convertedTime.seconds
                      ].join(':');

                      return (
                        <div key={timeIdx} className={classes.timeWrapper}>
                          {isNegative ? (
                            // negative value
                            <TextField
                              value={formattedTime}
                              onChange={(e) =>
                                this.onChangeTxt(e, timeElem.param, timeIdx)
                              }
                              type="number"
                              inputProps={{
                                min: 0
                              }}
                              InputProps={{
                                disableUnderline: true,
                                className: classes.time,
                                startAdornment: (
                                  <span className={classes.marginRight0dot5}>
                                    {t('grams')}:
                                  </span>
                                )
                              }}
                            />
                          ) : (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                ampm={false}
                                onChange={(date, time) =>
                                  this.onTimeEdit(
                                    date,
                                    time,
                                    timeElem.param,
                                    timeIdx
                                  )
                                }
                                value={
                                  (resultTime &&
                                    new Date(
                                      null,
                                      null,
                                      null,
                                      resultTime.split(':')[0],
                                      resultTime.split(':')[1]
                                    )) ||
                                  ''
                                }
                                views="seconds"
                                classes={{ root: classes.timeInputWrapper }}
                                InputProps={{
                                  disableUnderline: true,
                                  className: classes.time,
                                  startAdornment: (
                                    <span className={classes.marginRight0dot5}>
                                      {timeIdx === 0
                                        ? timeElem.main
                                        : timeElem.extra}
                                      :
                                    </span>
                                  )
                                }}
                                KeyboardButtonProps={{
                                  style: { display: 'none' }
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          )}

                          {timeIdx !== 0 && (
                            <IconButton
                              style={{
                                padding: 0,
                                position: 'absolute',
                                right: '0.2rem'
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.removeTime(timeElem.param, timeIdx);
                              }}>
                              <CloseWhite
                                style={{ width: '1em', height: '1em' }}
                              />
                            </IconButton>
                          )}
                        </div>
                      );
                    })}

                    <PlusGrey
                      className={clsx(classes.onHover)}
                      style={{ margin: '0.5rem', height: '32px' }}
                      id={timeElem.param}
                      onClick={this.onOpenPopper}
                    />
                  </div>
                </div>
              );
            })}
          {modalData?.rule === RULE_KUMITE && <BronzeCircleRound />}
          {!!+modalData?.kata?.tournament_presence && +modalData.type !== 4 && (
            <>
              <div>
                <h5>{t('indicateKataExercises')}</h5>
                <Divider />
              </div>

              {modalData?.kataRounds?.length > 0 && (
                <Box
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3.5em 1fr 1fr 2em',
                    gap: '0.5rem',
                    padding: '0 0.5rem',
                    fontSize: '13px',
                    opacity: 0.8
                  }}>
                  {[t('round'), t('finalistsNum'), t('kataExercises')].map(
                    (it) => (
                      <span key={it}>{it}</span>
                    )
                  )}
                </Box>
              )}
              {modalData?.kataRounds?.map((round, roundIdx) => (
                <Box
                  key={roundIdx}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3.5em 1fr 1fr 2em',
                    gap: '0.5rem',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    borderRadius: '8px',
                    padding: '0.5rem',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }}>
                  {/* Round */}
                  <span>{round.round}</span>

                  {/* Count */}
                  <TextField
                    value={round?.count || ''}
                    onChange={(e) => this.onChangeRoundTxt(e, round.round)}
                    type="number"
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'center'
                      }
                    }}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.time
                    }}
                    style={{
                      '& input': {
                        textAlign: 'center',
                        fontSize: '1rem',
                        padding: '0.5rem'
                      }
                    }}
                  />
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '0.5rem',
                      fontSize: '14px'
                    }}>
                    {round.name_ids.map((kataId, kataIdx) => {
                      const disciplineName = kata_names.find(
                        (it) => +it.id === +kataId
                      )?.name;

                      return <span key={kataIdx}>{disciplineName}</span>;
                    })}
                  </span>

                  {/* Button */}
                  <IconButton
                    id={`kata_round_${round.round}`}
                    onClick={this.onOpenPopper}
                    style={{
                      height: '32px',
                      width: '32px',
                      padding: 0
                    }}>
                    <PlusGrey className={clsx(classes.onHover)} />
                  </IconButton>
                </Box>
              ))}

              <Box style={{ display: 'flex', gap: '1rem', marginLeft: 'auto' }}>
                {modalData?.kataRounds?.length > 0 && (
                  <Button
                    className={classes.addnewRremoveBtn}
                    style={{
                      borderColor: 'rgba(0, 0, 0, 0.4)',
                      color: 'rgba(0, 0, 0, 0.4)'
                    }}
                    variant="outlined"
                    onClick={this.undoLastKataRound}>
                    {t('undoLast')}
                  </Button>
                )}
                <Button
                  className={classes.addnewRremoveBtn}
                  style={{ borderColor: '#2D63EE', color: '#2D63EE' }}
                  variant="outlined"
                  onClick={this.addNewKataRound}>
                  {t('addNew')}
                </Button>
              </Box>
            </>
          )}
        </div>
      );
      buttonPurpose = t('save');
      classNameBtn = Object.keys(errors)?.length > 0 && [
        classes.noEvent,
        classes.disabled
      ];
      shouldShowTopBottomDivider = true;
      onClick = (evt) => this.onSaveModalForm(evt);
    } else if (pressedIcon === MODAL_EDIT_CATEGORY_IC) {
      onClick = (evt) =>
        this.updateTournamentCategorySettings(
          evt,
          {
            tournamentId,
            categoryId: modalData?.category_id,
            bronze: modalData?.bronze_fight_enabled,
            circle: modalData?.circle_round
          },
          this.triggerUpdate
        );
      dialogTitle = t('generalCategorySettings');
      buttonPurpose = t('save');
      subHeader = modalData?.form_title_name;
      dialogContent = <BronzeCircleRound />;
    } else if (pressedIcon === TOGGLE_CATEGORY_STATUS) {
      onClick = this.onSaveDistribution;
      dialogTitle = t('distributeParticipantsByCategories');
      buttonPurpose = t('transfer');
      dialogContent = (
        <span>
          {t('reassignParticipantBeforeCategoryUnassingment')}
          <Filter
            className={clsx(classes.label, classes.marginTop1)}
            classes={{
              inputRoot: classes.outlined
            }}
            value={
              selectedValue(
                modalData?.allCategoriesForDistribution,
                'id',
                modalData.transfer2Category,
                true
              ) || ''
            }
            options={modalData?.allCategoriesForDistribution ?? []}
            label={t('categories')}
            onChange={(evt, val) => {
              this.selectOption(
                evt,
                val,
                'modalData',
                'transfer2Category',
                'id'
              );
            }}
            item={'name'}
            variant="outlined"
          />
        </span>
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      onClick = () =>
        navigate(
          `/event/${tournamentId}/participants?tab=2&category=${modalData.category_id}`,
          {
            state: { prevUrl: location.pathname }
          }
        );
      dialogContent = modalData?.participants
        ?.sort((a, b) => {
          const teamIdA = a?.categories_info?.find(
            (category) => +category.category_id === +modalData?.category_id
          )?.team_id;
          const teamIdB = b?.categories_info?.find(
            (category) => +category.category_id === +modalData?.category_id
          )?.team_id;

          const adjustedTeamIdA = !teamIdA ? Infinity : teamIdA;
          const adjustedTeamIdB = !teamIdB ? Infinity : teamIdB;

          return adjustedTeamIdA - adjustedTeamIdB;
        })
        .map((it, idx) => {
          const findCurrentCategory = it?.categories_info?.find(
            (category) => +category.category_id === +modalData?.category_id
          );

          return (
            <span
              style={{
                ...(!!findCurrentCategory?.issues ? { color: '#ff0000' } : {})
              }}
              className={clsx(classes.flex, classes.marginBottom05)}
              key={it.participant_id}>
              {idx + 1}.{' '}
              {!!findCurrentCategory?.team_id &&
                `(${findCurrentCategory.team_id}) `}
              {it.participant_name}
              {'   '}(
              {[
                it.gender,
                it.age,
                it.weight && `${it.weight}kg`,
                it.qdan_name
              ].join(', ')}
              )
            </span>
          );
        });
      dialogTitle = t('categoryOpponents');
      buttonPurpose = t('proceed');
      subHeader = modalData && (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>
            {[
              t('block', {
                count:
                  modalData.participants?.[0]?.categories_info?.[0]
                    ?.category_tatami_block_id
              }),
              modalData.participants?.[0]?.categories_info?.[0]
                ?.category_tatami_block
                ? `: ${modalData.participants?.[0]?.categories_info?.[0]?.category_tatami_block}`
                : ''
            ].join('')}
          </span>
          <span>
            {[
              t('tatami'),
              modalData.participants?.[0]?.categories_info?.[0]
                ?.category_tatami_name
            ].join(' ')}
          </span>
          <span>{modalData.category_name}</span>
        </span>
      );
    } else {
      onClick = (evt) =>
        this.updateTournamentCategorySettings(
          evt,
          { tournamentId, categoryId: modalData?.category_id, kataRounds: [] },
          this.triggerUpdate
        );
      dialogTitle = t('deleteRecord', { name: t('kataDiscipline') });
      buttonPurpose = t('remove');
      dialogContent = t('deleteMsg');
    }

    const poperData = [
      //Kuite Time values
      { label: `1 ${t('minute')}`, id: '60000' },
      { label: `1.5  ${t('minutes')}`, id: '90000' },
      { label: `2  ${t('minutes')}`, id: '120000' },
      { label: `3  ${t('minutes')}`, id: '180000' },
      { label: t('admisWeighs'), id: '-100' }
    ];

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            onClick,
            dialogTitle,
            shouldShowTopBottomDivider,
            buttonPurpose,
            dialogContent,
            classNameBtn
          }}
          subHeader={
            subHeader ? (
              subHeader
            ) : (
              <>
                {setCategoryName(modalData)?.name}
                {modalData?.form_title_name && (
                  <>
                    {'  '}({modalData?.form_title_name})
                  </>
                )}
              </>
            )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          specificDetails={{
            className: {
              ...(hasDesktopSize ? { paper: classes.modalWidth } : {})
            }
          }}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <CategoriesBlock
            {...{ tournamentData }}
            {...{ categoryTypes }}
            showModal={this.showModal}
            {...{ selectedCheckboxes }}
            onSelectCheckbox={this.onSelectCheckbox}
            categories={filteredCategories}
            {...{ tournamentId }}
            exportTournamentCategories={this.exportReportGeneral}
            toggleCategoryStatus={this.addCategorieshandler}
            {...{ isSending }}
            {...{ shouldDisableEditing }}
            {...{ viewportWidth }}
            {...{ kata_names }}
            onSearch={this.onSearch}
            onClearSearch={this.onClearSearch}
            {...{ searchBar }}
            cancelFilter={this.cancelFilter}
          />
        )}

        <Popper
          style={{ zIndex: 20000 }}
          id={Boolean(anchorEl) ? 'POPPER' : undefined}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.onClosePopper}
          disablePortal={true}>
          <ClickAwayListener onClickAway={this.onClosePopper}>
            {anchorEl && (
              <div className={classes.popperContentWrapper}>
                <span
                  style={{
                    background: '#E9ECF6',
                    padding: '1rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  {t('addRecord', { name: '' })}

                  <Exit
                    style={{ marginLeft: 'auto' }}
                    className={clsx(classes.onHover)}
                    onClick={this.onClosePopper}
                  />
                </span>

                <div
                  style={{
                    display: 'grid',
                    maxHeight: '300px',
                    overflowY: 'auto',
                    padding: '1rem'
                  }}>
                  {!anchorEl.id.includes('kata_round_')
                    ? poperData?.map((it, itIdx) => (
                        <CheckboxBtn
                          key={itIdx}
                          onChange={() => {
                            this.onTogglePopperCheckbox({
                              name: anchorEl?.id,
                              value: it.id
                            });
                          }}
                          label={it.label}
                        />
                      ))
                    : kata_names.map((kata_name) => (
                        <CheckboxBtn
                          key={kata_name.id}
                          checked={modalData?.kataRounds?.some((round) => {
                            if (round.round === +anchorEl.id.slice(-1)) {
                              return round.name_ids.some(
                                (id) => +id === +kata_name.id
                              );
                            }
                            return false;
                          })}
                          onChange={() =>
                            this.onSelectKataNames(
                              kata_name.id,
                              anchorEl.id.slice(-1)
                            )
                          }
                          label={kata_name.name}
                        />
                      ))}
                </div>
              </div>
            )}
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(TournamentAddCategories))
);
