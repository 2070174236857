export const styles = () => ({
  flex: { display: 'flex' },

  interactiveHeaderWrapper: {
    display: 'grid',
    gridColumnGap: '1rem',
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'search search search generate saveList filter'"
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridRowGap: '1rem',
      gridTemplateAreas: `
        'search search search'
        'generate saveList filter'
      `
    },
    '@media (max-width:767px)': {
      gridRowGap: '1rem',
      gridTemplateAreas: `
        'search search'
        'generate generate'
        'saveList filter'
      `
    }
  },

  radio: {
    fontSize: '1rem',
    width: '1em',
    height: '1em'
  },

  radioLabel: {
    marginLeft: 0,
    marginRight: '1rem',
    '& .MuiTypography-body1': {
      marginLeft: '0.5rem'
    }
  },

  search: {
    gridArea: 'search'
  },

  activeState: {
    backgroundColor: '#8996AC',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },

  generate: {
    gridArea: 'generate'
  },

  marginTopBottom1: {
    margin: '1rem 0'
  },

  margin0: {
    margin: 0
  },

  saveList: {
    gridArea: 'saveList'
  },

  save: {
    gridArea: 'save'
  },

  filter: {
    gridArea: 'filter'
  },
  emptyState: {
    marginTop: '40px'
  },
  classNameBtn: {
    order: 2
  }
});
