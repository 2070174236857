import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  padding0: {
    padding: 0
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  bgWhite: { background: '#fff', borderRadius: '7px' },

  gridRecords: {
    gridTemplateColumns: '1fr',
    borderRadius: '7px',
    border: 'none'
  },

  nameWithoutAvatar: {
    margin: 0,
    width: '100%',
    justifyContent: 'center !important'
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  fullWidth: {
    width: '100%'
  },

  fullHeight: {
    height: '100%'
  },

  dropdownIc: {
    border: '2px solid rgb(77, 77, 77)',
    borderLeft: 'none',
    height: '100%'
  },

  alignLeft: {
    marginLeft: 'auto',
    marginRight: '4.5rem',
    alignItems: 'center'
  },

  alignLeft2: {
    marginLeft: 'auto',
    marginRight: '5rem',
    alignItems: 'center'
  },

  selectAllCheckbox: {
    justifyContent: 'end',
    marginRight: '1.1rem'
  },

  marginLeft02: {
    marginLeft: '0.2rem'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeftCollapse1: {
    '& .MuiCollapse-root': {
      marginLeft: '1rem'
    }
  },

  marginLeftCollapse15: {
    '& .MuiCollapse-root': {
      marginLeft: '1.5rem'
    }
  },

  marginLeft15: {
    marginLeft: '1.5rem'
  },

  paddingLeft05: {
    paddingLeft: '0.5rem'
  },

  categoryFont: {
    fontSize: '14px'
  },

  backGroundGrey: {
    backgroundColor: '#8996AC',
    color: '#FFFFFF',
    borderRadius: '0 7px 7px 0',
    padding: '0.5rem'
  },

  recordName: {
    minHeight: 0,
    margin: 0
  },

  margin0: {
    margin: 0
  },

  padding065: {
    padding: '0.65rem'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  gridRecordsValueCollapse: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gap: '0.5rem',
    padding: '0',
    backgroundColor: '#E9ECF6'
  },

  sortCollapseLabels: {
    '@media (max-width:1023px)': {
      gridTemplateColumns: '1fr !important'
    }
  },

  gridRecordsCollapsed: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr !important'
    },
    '@media (max-width:1023px)': {
      gridTemplateColumns: '1fr !important'
    }
  },
  grey: {
    display: 'flex',
    marginRight: '0.2rem'
  }
});
