import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as GroupIcon } from 'assets/img/participantMode.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { isDesktopView } from 'helpers/selectors';

import Table from 'components/Table/CardsList';
import Radio from '../RadioBtn/RadioBtn';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';

import { useStyles } from './TableListStyles';

const TableList = (props) => {
  const {
    tournamentData,
    expandTableRow,
    filteredData,
    collapsed,
    onSortCollapsible,
    onRadioBtnSelection,
    onSelectPartCheckbox,
    onSelectAllPartCheckboxes,
    selectedParticiantsCategory,
    shouldDisableEditing,
    param_agr,
    panelIdx,
    viewportWidth
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isDesktop = isDesktopView(viewportWidth);
  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'participant_first_name'
    : 'participant_last_name';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'participant_last_name'
    : 'participant_first_name';

  const recordDataParams = (item, idx) => {
    const participantsData = collapsed?.[param_agr]?.find(
      (it) => +it.id === +item.id
    )?.participants;

    return {
      class: {
        gridRecords: classes.gridRecords,
        nameWithoutAvatar: classes.nameWithoutAvatar,
        collapse:
          panelIdx === 0
            ? classes.marginLeftCollapse1
            : classes.marginLeftCollapse15,
        sortCollapseLabels: classes.sortCollapseLabels
      },
      main: {
        style: {
          cardMainPart: classes.bgWhite,
          recordName: classes.recordName
        },
        name: (
          <span
            className={clsx(
              classes.flex,
              classes.centerVertically,
              panelIdx === 1 && classes.paddingLeft05,
              classes.fullHeight
            )}>
            {panelIdx === 1 && (
              <Radio
                value={selectedParticiantsCategory.newCategory || ''}
                item={`${item.id}`}
                onClick={(evt) => onRadioBtnSelection(evt, item)}
              />
            )}
            <span
              className={clsx(
                classes.flex,
                classes.onHover,
                classes.fullHeight,
                classes.fullWidth
              )}
              onClick={(evt) => expandTableRow(evt, panelIdx, item)}>
              <span
                className={clsx(
                  classes.flex,
                  classes.fullWidth,
                  classes.centerVertically,
                  classes.padding065,
                  classes.fullHeight,
                  classes.categoryFont
                )}>
                <span
                  style={{
                    ...(+item?.participants_count === 1 ||
                    (+item.type === 3 &&
                      item?.participants?.every(
                        (it) => +item?.participants[0]?.team_id === +it.team_id
                      ))
                      ? { color: '#ff0000' }
                      : {})
                  }}>
                  {item.name}
                  {!!+tournamentData?.use_alt_category_name &&
                    item.alt_name && (
                      <span
                        style={{
                          ...(item.name !== item.alt_name
                            ? { color: '#005FEE' }
                            : {})
                        }}>{` (${item.alt_name})`}</span>
                    )}
                </span>

                <span
                  className={clsx(
                    classes.flex,
                    item.participants_count > '9'
                      ? classes.alignLeft
                      : classes.alignLeft2
                  )}>
                  <GroupIcon
                    className={clsx(classes.marginLeft02, classes.grey)}
                  />
                  <span
                    className={clsx(
                      classes.flex,
                      classes.marginLeft02,
                      classes.categoryFont
                    )}>
                    {item.participants_count}
                  </span>
                </span>
              </span>
              <span
                className={clsx(
                  classes.flex,
                  classes.centerVertically,
                  classes.backGroundGrey,
                  classes.fullHeight
                )}>
                <ArrowDropDownIcon
                  className={clsx(
                    participantsData?.length > 0 ? classes.rotate180 : undefined
                  )}
                />
              </span>
            </span>
          </span>
        )
      }
    };
  };

  const collapsedRecordDataParams = (item, idx, parent) => {
    const findCategoryIdx = selectedParticiantsCategory?.oldData?.findIndex(
      (it) => +it?.category_id === +parent?.id
    );
    const findCategoryParts =
      selectedParticiantsCategory?.oldData?.[findCategoryIdx]?.participants;

    return {
      class: {
        gridRecordsValueCollapse: classes.gridRecordsValueCollapse,
        gridRecords: [classes.gridRecordsCollapsed, classes.categoryFont],
        nameWithoutAvatar: classes.nameWithoutAvatar
      },
      main: {
        label: isDesktop && t('name'),
        hasSort: true,
        sort: FIRST_NAME,
        style: {
          recordName: classes.recordName
        },
        name: (
          <span className={clsx(classes.categoryFont, classes.paddingLeft05)}>
            {[
              item?.[FIRST_NAME],
              item?.[LAST_NAME],
              !!+tournamentData?.show_reg_patronymic
                ? [item?.participant_patronymic]
                : []
            ].join(' ')}
          </span>
        ),
        hideValueName: true
      },
      info: [
        { sort: 'age', value: item?.age, name: isDesktop && t('age') },
        { sort: 'gender', value: item?.gender, name: isDesktop && t('gender') },
        { sort: 'weight', value: item.weight, name: isDesktop && t('weight') },

        ...(!!+tournamentData?.show_reg_qdan
          ? [
              {
                sort: 'qdan_name',
                value: item.qdan_name,
                name: isDesktop && t('kyuDan')
              }
            ]
          : []),

        ...(panelIdx === 0 && !shouldDisableEditing
          ? [
              {
                value: (
                  <CheckboxBtn
                    classControlLabel={clsx(classes.margin0, classes.leftAuto)}
                    className={clsx(classes.padding0)}
                    formControlClassName={classes.paper}
                    checked={
                      Array.isArray(findCategoryParts)
                        ? findCategoryParts.some(
                            (it) => it.participant_id === item.participant_id
                          )
                        : false
                    }
                    onChange={() => onSelectPartCheckbox(item, parent)}
                  />
                ),
                name: (
                  <CheckboxBtn
                    classControlLabel={clsx(classes.margin0, classes.leftAuto)}
                    className={clsx(classes.padding0)}
                    indeterminate={
                      findCategoryParts?.length > 0 &&
                      findCategoryParts?.length < parent?.participants?.length
                    }
                    formControlClassName={classes.paper}
                    checked={
                      findCategoryParts?.length === parent?.participants?.length
                    }
                    onChange={(e) => {
                      e.stopPropagation();
                      onSelectAllPartCheckboxes(parent);
                    }}
                    label={t('showAll')}
                    labelPlacement="end"
                  />
                )
              }
            ]
          : [])
      ]
    };
  };

  return (
    <Table
      data={filteredData}
      collapsed={collapsed?.[param_agr]}
      onSortCollapsible={(sortField, idxCell, parent) =>
        onSortCollapsible(sortField, idxCell, parent, param_agr)
      }
      {...{
        recordDataParams,
        collapsedRecordDataParams
      }}
      id="id"
      subTableDataKey="participants"
    />
  );
};

export default TableList;
