const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxHeight: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  marginLeft1: {
    marginBottom: '-1rem'
  },

  column: {
    flexDirection: 'column'
  },

  formSave: {
    height: '3.35em',
    width: '100%'
  },

  checkbox: {
    marginRight: 0
  },

  // MODAL ASSIGN
  modalWidthAssign: {
    '@media (min-width:768px)': {
      minWidth: '738px'
    }
  },

  thumbnailModal: {
    border: '1px solid #8996AC',
    width: '48px',
    height: '64px',
    objectFit: 'cover',
    borderRadius: '0.5rem'
  },

  participantDetailsModal: {
    justifyContent: 'space-between'
  },

  closeBtnModal: {
    color: '#fff',
    background: '#8996AC',
    '@media (min-width: 768px)': {
      marginRight: 0
    },
    '&:hover': {
      background: '#AEBBD0',
      opacity: 0.8
    },
    '@media (max-width: 767px)': {
      margin: 0,
      width: '100%'
    }
  },

  closeBtnModalInfo: {
    '@media (max-width:1023px) and (min-width:768px)': {
      marginLeft: '1.5rem'
    },
    '@media (min-width: 768px)': {
      marginRight: 0
    },
    '@media (max-width: 767px)': {
      margin: 0
    }
  },

  saveBtnModalInfo: {
    order: 2,
    '@media (max-width: 767px)': {
      margin: 0
    },
    marginLeft: '1rem !important'
  },

  wrapperFooterModalInfo: {
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '1rem'
    }
  },

  saveBtnModal: {
    order: 2,
    '@media (min-width: 768px)': {
      marginLeft: '1rem'
    },
    '@media (max-width: 767px)': {
      margin: 0,
      marginTop: '1rem',
      width: '100%'
    }
  },

  participantDetailsModal: {
    marginLeft: '0.5rem',
    justifyContent: 'space-between'
  },

  wrapperFooterModal: {
    '@media (max-width: 767px)': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '1rem'
    }
  }
});

export default styles;
