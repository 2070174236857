const styles = () => ({
  flex: {
    display: 'flex'
  },

  fullWidth: {
    width: '100%'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },
  break: {
    wordBreak: 'break-all'
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      color: '#005FEE',
      cursor: 'pointer'
    }
  },

  txtWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    wordSpacing: '100vw',
    '@media (max-width: 767px)': {
      fontWeight: 600,
      wordSpacing: 'normal'
    }
  },

  gridRecords: {
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr'
    }
  },

  gridRecordsImport: {
    '@media (max-width:1000px)': {
      gridTemplateColumns: '1fr'
    }
  },

  gridRecordsValue: {
    '@media (max-width:1023px) ': {
      gridTemplateAreas: "'. .' '. .''. .'",
      gridTemplateColumns: '1fr 1fr',
      marginLeft: '5.5rem',
      marginTop: '-1.5rem',
      padding: 0
    }
  },

  gridRecordsValueImport: {
    '@media (max-width:1000px) and (min-width:480px)': {
      gridTemplateColumns: '1fr 1fr'
    }
  },

  cardValuePart: {
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 400
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: 'fit-content'
    }
  },

  margin0: { margin: 0 },

  rowOrder: { minWidth: '3em' },

  thumbnail: {
    width: '2.438em',
    height: '3.25em'
  },

  emailTable: {
    width: '10em'
  },

  alignItems: {
    alignItems: 'center'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  fullWidth: {
    width: '100%'
  },

  marginBottom: {
    marginBottom: '20px'
  }
});

export default styles;
