import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {
  textChangeHandler,
  addCategory,
  updateCategory,
  changeModal,
  selectOption,
  validateForm,
  addCategoryGroup,
  updateCategoryGroup,
  customCategoryType
} from '../../helpers/util';
import {
  MIN_WEIGHT,
  MIN_AGE,
  MAX_AGE,
  FORM,
  ID,
  UPDATE_CATEGORY,
  UPDATE_GROUP_CATEGORY,
  UPDATE_GROUP,
  ADD_NEW_GROUP_CATEGORY,
  ADD_CATEGORY
} from '../../helpers/constants';

import { AuthContext } from 'AuthContext';

import CategoryForm from '../../components/AddCategory/AddCategory';
import SideModal from '../../components/Snackbar/SideModal';

const defaultFormState = {
  group_category_name: '',
  group_category_type: '',
  group_category_age_from: '',
  group_category_age_to: '',
  group_category_gender: ''
};

class AddCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        ...(props?.state?.modalData ?? defaultFormState)
      },
      categoryTypes: props?.state?.categoryTypes ?? [],
      errors: {},
      showModal: false,
      success: false,
      isSending: false,
      isLoading: false
    };

    this.textChangeHandler = textChangeHandler.bind(this);
    this.addCategory = addCategory.bind(this);
    this.changeModal = changeModal.bind(this);
    this.updateCategory = updateCategory.bind(this);
    this.selectOption = selectOption.bind(this);
    this.validateForm = validateForm.bind(this);
    this.addCategoryGroup = addCategoryGroup.bind(this);
    this.updateCategoryGroup = updateCategoryGroup.bind(this);
    this.customCategoryType = customCategoryType.bind(this);

    this.role = localStorage.getItem('role');
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props;

    if (
      state?.selectedRow &&
      state?.selectedRow !== prevProps.state?.selectedRow
    ) {
      this.setState({
        form: state?.modalData
      });
    }
  }

  componentDidMount() {}

  onSelectCategoryType = (_, value) => {
    const { form, categoryTypes, errors } = this.state;
    const { state, t, isUpdateForm } = this.props;

    this.selectOption(
      _,
      value,
      FORM,
      'group_category_type',
      ID,
      errors,
      (type) => {
        const { updateNewFormNameOnCategorySelection } = this.props;

        let formErrors = { ...errors };
        const findCurrentType = categoryTypes?.find((it) => +it.id === +type);

        if (
          (state?.shouldShowForm === ADD_CATEGORY ||
            state?.shouldShowForm === UPDATE_CATEGORY ||
            state?.shouldShowForm === UPDATE_GROUP_CATEGORY) &&
          findCurrentType.weighted
        ) {
          form.category_weight_from < MIN_WEIGHT &&
            (formErrors.category_weight_from = t('minimumAllowableWeight'));
        } else {
          delete formErrors.category_weight_from;
        }

        delete formErrors.group_category_name;

        this.setState((prevState) => ({
          errors: formErrors,
          form: { ...prevState.form, weighted: findCurrentType.weighted }
        }));

        updateNewFormNameOnCategorySelection({
          modalData: {
            ...form,
            weighted: findCurrentType?.weighted,
            category_type_name: findCurrentType?.name
          }
        });
      },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      isUpdateForm
    );
  };

  saveCategory = (evt) => {
    const { form, errors } = this.state;
    const { state, t, triggerTableUpdate } = this.props;
    let object = { ...defaultFormState };
    let formErrors = { ...errors };

    if (
      (+form?.group_category_age_from >= MIN_AGE &&
        +form?.group_category_age_from <= MAX_AGE) ||
      (+form?.group_category_age_to >= MIN_AGE &&
        +form?.group_category_age_to <= MAX_AGE)
    ) {
      delete formErrors.group_category_age_to;
      delete formErrors.group_category_age_from;
      delete object.group_category_age_to;
      delete object.group_category_age_from;
    }

    if (state?.shouldShowForm === ADD_CATEGORY && form.weighted) {
      object = { ...object, category_weight_from: '' };
    } else {
      delete formErrors.category_weight_from;
      delete object.category_weight_from;
    }

    this.setState({ errors: formErrors }, () => {
      this.validateForm(evt, object, form, 'errors', () => {
        if (
          state?.shouldShowForm === UPDATE_CATEGORY ||
          state?.shouldShowForm === UPDATE_GROUP_CATEGORY ||
          state?.shouldShowForm === UPDATE_GROUP
        ) {
          state?.shouldShowForm === UPDATE_GROUP
            ? this.updateCategoryGroup(evt, form, triggerTableUpdate)
            : this.updateCategory(evt, form, triggerTableUpdate);
        } else {
          state?.shouldShowForm === ADD_NEW_GROUP_CATEGORY &&
          form.weighted &&
          (+form.group_category_type === 2 ? !form.isAbsoluteChecked : true)
            ? this.addCategoryGroup(evt, form, defaultFormState)
            : this.addCategory(evt, defaultFormState, state?.shouldShowForm);
        }
      });
    });
  };

  hideSnackbar = () => this.setState({ showModal: false });

  onSelectAbsoluteCategory = (evt, checked) => {
    const { form, errors } = this.state;
    const { updateNewFormNameOnCategorySelection } = this.props;
    let copyForm = { ...form };
    let copyErrors = { ...errors };

    copyForm = {
      ...copyForm,
      ...(checked ? { group_category_age_from: '18' } : {}),
      ...(checked ? { group_category_age_to: null } : {}),
      isAbsoluteChecked: checked
    };

    updateNewFormNameOnCategorySelection({
      modalData: { ...copyForm }
    });

    delete copyErrors.group_category_age_from;

    this.setState({ form: copyForm, errors: copyErrors });
  };

  render() {
    const {
      form,
      errors,
      showModal,
      success,
      categoryTypes,
      modalInfo,
      isLoading
    } = this.state;
    const { state, isUpdateForm } = this.props;
    const { viewportWidth } = this.context;

    const shouldDisableInputs =
      (!!+form?.is_group && state.shouldShowForm === ADD_CATEGORY) ||
      state.shouldShowForm === UPDATE_GROUP_CATEGORY;

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <CategoryForm
          windowWidth={viewportWidth}
          value={form}
          error={errors}
          formParamName={FORM}
          textChange={this.textChangeHandler}
          saveCategory={this.saveCategory}
          {...{ isUpdateForm }}
          onSelect={(evt, val, paramN, argN) =>
            this.selectOption(
              evt,
              val,
              FORM,
              paramN,
              argN,
              errors,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              isUpdateForm
            )
          }
          qdanList={state?.qdanList ?? []}
          {...{ categoryTypes }}
          selectCategoryType={this.onSelectCategoryType}
          onSelectAbsoluteCategory={(evt, checked) =>
            this.onSelectAbsoluteCategory(evt, checked)
          }
          shouldShowForm={state?.shouldShowForm}
          {...{ isLoading }}
          disabledFields={{
            group_category_age_from:
              shouldDisableInputs || form?.isCategoryAbsolute,
            group_category_age_to:
              shouldDisableInputs || form?.isCategoryAbsolute,
            gender: shouldDisableInputs || form?.isCategoryAbsolute,
            qdan: shouldDisableInputs,
            category_weight_from: form?.isCategoryAbsolute,
            type:
              !!+form?.is_group ||
              state?.shouldShowForm === ADD_CATEGORY ||
              state?.shouldShowForm === UPDATE_GROUP_CATEGORY ||
              form?.isCategoryAbsolute,
            category_name_plus: form?.isCategoryAbsolute,
            category_alt_name_plus: form?.isCategoryAbsolute
          }}
          shouldHideSaveFormBtn={() =>
            (state?.shouldShowForm === UPDATE_GROUP ||
              state?.shouldShowForm === UPDATE_CATEGORY) &&
            this.role === 'master' &&
            !+form?.is_owner
          }
        />
      </>
    );
  }
}
export default withTranslation()(AddCategory);
