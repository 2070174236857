import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { selectedValue } from '../../../helpers/selectors';
import { NAME, ID } from '../../../helpers/constants';

import Filter from '../../Filter/Filter';

const FilterBracketData = (props) => {
  const { categoryTypes, selectCategoryType, bracketForm } = props;

  const { t } = useTranslation();

  const filterData = [
    {
      options: categoryTypes,
      onChange: selectCategoryType,
      label: t('discipline'),
      item: NAME,
      value: selectedValue(categoryTypes, ID, +bracketForm?.category_type, true)
    }
  ];

  return (
    <>
      {filterData.map(
        ({ options, onChange, label, item, helperText, error, value }, index) =>
          item && (
            <Fragment key={index}>
              <Filter
                {...{ options }}
                {...{ onChange }}
                {...{ label }}
                {...{ item }}
                value={value || ''}
                variant="outlined"
                {...{ helperText }}
                {...{ error }}
              />
            </Fragment>
          )
      )}
    </>
  );
};

export default FilterBracketData;
